.reference-group{
	justify-content: center;
	@include bp(rev-xsmall){
		margin: 0 -20px 3rem;
	}
}

.reference-item{
	position: relative;
	margin: 1rem 0;
	&:last-child{
		border-right: 0;
	}
	@include bp(medium){
		border-right: 1px solid #eaedf0;
		&:nth-child(4n + 4){
			border-right: 0;
		}
	}
	@include bp(excl-medium){
		border-right: 1px solid #eaedf0;
		&:nth-child(3n + 3){
			border-right: 0;
		}
	}
	@include bp(excl-small){
		border-right: 1px solid #eaedf0;
		&:nth-child(3n + 3){
			border-right: 0;
		}
	}
	@include bp(rev-xsmall){
		border-right: 1px solid #eaedf0;
		&:nth-child(2n + 2){
			border-right: 0;
		}
		.zeta{
			@include font-size(14px);
		}
	}
}

.reference-content{
	max-width: 220px;
	margin: 0 auto;
	padding: 2rem 0.2rem;
}

.reference-image-block{
	height: 60px;
}