.contact-item{
	position: relative;
	display: inline-flex;
	height: 70px;
	align-items: center;
	vertical-align: middle;
	justify-content: center;
	margin: 1rem 0;
	@include bp(excl-large){
		margin: 0;
	}
	@include bp(large){
		height: 130px;
	}
	@include bp(rev-xsmall){
		margin: 0;
	}
	&:last-child{
		&:after{
			display: none;
		}
	}
	&:after{
		content: '';
		position: absolute;
		top: 50%;
		right: 0;
		display: block;
		width: 2px;
		height: 50px;
		transform: translateY(-50%);
		background-color: $color-white-light;
		@include bp(excl-large){
			top: auto;
			bottom: 0;
			left: 50%;
			width: 220px;
			height: 2px;
			transform: translate(-50%, 0);
		}
		@include bp(rev-xsmall){
			top: auto;
			bottom: 0;
			left: 50%;
			width: 220px;
			height: 2px;
			transform: translate(-50%, 0);
		}
		@include bp(large){
			height: 130px;
		}
	}
	&:hover{
		.contact-text{
			text-decoration: underline;
		}
	}
}

.contact-icon{
	display: inline-block;
	font-family: $font-primary;
	float: left;
	width: 40px;
    margin-right: 15px;
    position: relative;
    top: 0;
	@include bp(xlarge){
		width: 50px;
    	margin-right: 25px;
	}
	.icon-phone{
		@include font-size(26px);
		@include bp(xlarge){
			@include font-size(37px);
		}
	}
	svg{
		fill: $color-green-normal;
		@include font-size(28px);
		@include bp(rev-small){
			@include font-size(28px);
		}
		@include bp(xlarge){
			@include font-size(44px);
		}
	}
	@include bp(rev-xsmall){
		width: 35px;
	}
}

.contact-text{
	display: block;
	position: relative;
	top: 0;
	overflow: hidden;
	font-family: $font-primary;
	@include font-size(20px);
	color: $color-black-normal;
	font-weight: 400;
	line-height: 1.4;
	text-transform: none;
	@include bp(xlarge){
		@include font-size(25px);
	}
}