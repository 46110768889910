/* Typography */

.typography{
	p, 
    li, 
    td, 
    th{
        line-height: 1.5;
    }

    p,
    ul,ol,
    h1, h2, h3, h4, h5, h6,
    table,
    fieldset,
    blockquote,
    iframe{
        margin-bottom: 1rem;
    }

	h1{
		display: block;
		margin-top: 0;
		margin-bottom: 0;
		font-family: $font-primary;
		font-weight: 300;
		text-transform: uppercase;
		color: $color-black-normal;
		letter-spacing: 0.4px;

		@include font-size(32px);
		@include bp(small){
			@include font-size(37px);
		}
	}

	h2{
		display: block;
		margin-top: 3rem;
		margin-bottom: 2rem;
		font-family: $font-primary;
		font-weight: 700;
		color: $color-blue-normal;
		text-align: center;
		letter-spacing: 0.35px;
		text-transform: uppercase;

		@include font-size(30px);
		@include bp(small){
			margin-top: 4rem;
			margin-bottom: 4rem;
			@include font-size(29px);
		}
		&:after{
			content: '';
			position: relative;
			display: block;
			width: 48px;
			height: 10px;
			margin: 10px auto 0;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			background-image: url('../images/icons/vlnka.svg');
		}
	}

	h3{
		display: block;
		margin-top: 1.5rem;
		margin-bottom: 3rem;
		font-family: $font-primary;
		font-weight: 500;
		color: $color-black-normal;
		letter-spacing: 0.2px;

		@include font-size(22px);
		@include bp(small){
			@include font-size(26px);
		}
	}

	h4{
		display: block;
		margin-top: 3rem;
		margin-bottom: 2rem;
		font-family: $font-primary;
		color: $color-black-normal;
		font-weight: 500;
		line-height: 1.2;
		text-transform: uppercase;
		letter-spacing: 0.2px;

		@include font-size(16px);
		@include bp(small){
			@include font-size(18px);
		}
	}

	h5{
		display: block;
		margin-top: 1rem;
		margin-bottom: 1.5rem;
		font-family: $font-primary;
		font-weight: 500;
		color: $color-black-normal;
		letter-spacing: 0.1px;

		@include font-size(14px);
		@include bp(small){
			@include font-size(16px);
		}
	}

	h6{
		display: block;
		margin-top: 1rem;
		margin-bottom: 1.5rem;
		font-family: $font-primary;
		font-weight: 500;
		color: $color-black-normal;
		letter-spacing: 0.1px;

		@include font-size(14px);
		@include bp(small){
			@include font-size(16px);
		}
	}

	a{
		text-decoration: underline;
		color: $color-green-normal;
		&:hover{
			text-decoration: underline;
		}
	}


	p{
		display: block;
		margin-top: 0;
		margin-bottom: 2em;
		font-family: $font-primary;
		font-weight: 300;
		line-height: 1.8;
		color: $color-black-normal;

		@include font-size(15px);
	}

	strong{
		font-weight: 500;
		color: $color-green-normal;
	}
	
	img{
		display: block;
		height: auto;
		max-width: 100%;
		margin-bottom: 2rem;
		width: 100%;
	}

	ul,
	ol{
		margin-bottom: 2rem;
		padding-left: 0;
		list-style: none;
		li{
			position: relative;
			margin-bottom: 5px;
			padding-left: 25px;
			font-family: $font-primary;
			font-weight: 300;
			line-height: 1.8;
			color: $color-black-normal;

			@include font-size(15px);
		}
	}

	ul{
		li{
			padding-left: 30px;
			&:before{
				content: "";
				position: absolute;
				top: 7px;
				left: 3px;
				display: block;
				width: 15px;
				height: 12px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				background-image: url('../images/icons/odrazka.svg');
			}
		}
	}

	ol{
		list-style-position:inside;
		counter-reset: item;
		li{
			padding-left: 20px;
			&:before{
				position: absolute;
				top: 2px;
				left: 0;
				display: inline-block;
				line-height: 1.4;
				content: counter(item)'.';
				font-family: $font-primary;
				counter-increment: item;
				color: $color-green-normal;
				font-weight: 600;

				@include font-size(16px);
			}
		}
	}

	table{
		margin-bottom: 2rem;
	}

	figure{
		position: relative;
		margin: 0;
		margin-bottom: 2em;
		box-shadow: 0 4px 19px rgba(0, 0, 0, 0.07);
		border-radius: 5px;
		background-color: #ffffff;
		overflow: hidden;
		img{
			margin: 0;
		}
	}

	.js-gallery-item{
		text-decoration: none!important;
	}

	figcaption{
		font-family: $font-primary;
		color: #858585;
		padding: 1.3rem 1.5rem;
		@include font-size(12px);
		border-bottom: 3px solid $color-green-normal;
		font-weight: 500;
		line-height: 1.6;
		background-color: #ffffff;
		letter-spacing: 0.36px;
		strong{
			display: block;
			color: #383838;
			@include font-size(15px);
			letter-spacing: 0.4px;
			font-weight: 500;
		}
	}
}

.gallery-group{
	// figure{
	// 	&:hover{
	// 		figcaption{
	// 			opacity: 1;
	// 		}
	// 	}
	// }
	figcaption{
		position: absolute;
		bottom: 20px;
		left: 20px;
		right: 20px;
		z-index: 2;
		border-bottom: 0;
		border-bottom: 2px solid $color-green-normal;
		padding: 1rem 1.2rem;
		border-radius: 4px;
		@include bp(excl-large){
			padding: 0.4rem 0.6rem;
			left: 10px;
			right: 10px;
			bottom: 10px;
			@include font-size(12px);
		}
		// @include bp(small){
		// 	opacity: 0;
		// }
		@include bp(rev-xsmall){
			padding: 0.4rem 0.6rem;
			left: 10px;
			right: 10px;
			bottom: 10px;
			@include font-size(12px);
		}
	}
	img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.info{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	position: relative;
	padding: 2rem 1.5rem 2rem;
	border-radius: 5px;
	background-color: $color-gray-light;
	border-bottom: 2px solid $color-red-normal;
	margin-bottom: 2rem;
	@include bp(small){
		padding: 2rem 2rem 2rem;
	}
	.info-text{
		width: 15%;
		max-width: 15%;
		flex: 15%;
		display: inline-block;
		vertical-align: middle;
		color: $color-orange-normal;
		font-family: $font-primary;
		@include font-size(15px);
		font-weight: 500;
		text-transform: uppercase;
		letter-spacing: 0.15px;
		text-transform: uppercase;
		@include bp(rev-small){
			display: block;
			width: 100%;
			max-width: 100%;
			flex: 100%;
			margin-bottom: 15px;
		}
	}
	p{
		display: inline-block;
		vertical-align: middle;
		width: 85%;
		max-width: 85%;
		flex: 85%;
		margin-bottom: 0;
		line-height: 1.6;
		padding-left: 2rem;
		font-weight: 300;
		letter-spacing: 0.14px;
		color: $color-black-normal;
		@include bp(rev-small){
			width: 100%;
			max-width: 100%;
			flex: 100%;
			padding-left: 0;
		}
	}
}
