/* Footer */

.footer{
	z-index: 2;
	position: relative;
	overflow: hidden;
	background-color: $color-blue-dark;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	border-bottom: 2px solid $color-green-normal;
}

footer.footer-offset{
	.footer-top{
		@include bp(small){
			padding-top: 7rem;
		}
	}
}

.footer-item{
	margin-bottom: 1rem;
	@include bp(small){
		margin-bottom: 2rem;
	}
	@include bp(medium){
		margin-bottom: 3rem;
	}
	@include bp(rev-xssmall){
		width: 100%;
		// margin-bottom: 2rem;
	}
}

.footer-top{
	padding: 4.5rem 0.5rem 0;
	@include bp(rev-medium){
		padding: 3rem 0.5rem 0;
	}
}

.footer-bottom{
	padding: 1rem 0.5rem 1rem;
}

.footer-copy{
	color: #305795;
	font-family: $font-primary;
	@include font-size(12px);
	font-weight: 400;
	line-height: 1.4;
	@include bp(rev-small){
		text-align: center;
	}
	a{
		&:hover{
			color: #ffffff;
			text-decoration: underline;
		}
	}
}


.footer-link{
	color: white;
	&:hover{
		text-decoration: underline;
	}
}

.footer-logo{
	display: flex;
	align-items: center;
	// justify-content: center;
	position: relative;
	padding: 1rem 0;
	@include bp(rev-medium){
		justify-content: center;
	}
	img{
		@include bp(rev-xsmall){
			margin: 0 auto;
		}
	}
}

.footer-address-item{
	position: relative;
	display: inline-flex;
	align-items: center;
	vertical-align: middle;
	justify-content: center;
	&:last-child{
		&:after{
			display: none;
		}
	}
	&:after{
		content: '';
		position: absolute;
		top: 50%;
		right: 0;
		display: block;
		width: 2px;
		height: 50px;
		transform: translateY(-50%);
		background-color: #0f2d69;
	}
	@include bp(rev-medium){
		margin: 0;
	}
	@include bp(rev-small){
		display: flex;
		margin-bottom: 20px;
	}
	&:hover{
		.footer-address-text{
			text-decoration: underline;
		}
	}
}

.footer-address-icon{
	display: inline-block;
	font-family: $font-primary;
	float: left;
	width: 25px;
	margin-right: 25px;
	svg{
		fill: $color-green-normal;
		@include font-size(32px);
		@include bp(rev-small){
			@include font-size(28px);
		}
	}
	@include bp(rev-xsmall){
		width: 25px;
		// @include font-size(24px);
	}
}

.footer-address-text{
	display: block;
	height: 100%;
	position: relative;
	top: 3px;
	overflow: hidden;
	font-family: $font-primary;
	@include font-size(18px);
	color: #ffffff;
	font-weight: 300;
	line-height: 1.4;
	text-transform: none;
	@include bp(rev-xsmall){
		@include font-size(16px);
	}
	@include bp(excl-large){
		@include font-size(16px);
	}
	strong{
		display: block;
		margin-bottom: 1em;
		text-transform: uppercase;
	}
}

.footer-address-text-ext{
	line-height: 1.6;
}
