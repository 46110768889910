/* Color helpers */

.bg-black-normal {
    background-color: #000000;
}

.bg-gray-light{
    background-color: $color-gray-light;
}

.bg-green-normal{
    background-color: $color-green-normal;
}

.bg-blue-normal{
    background-color: $color-blue-normal;
}

.txt-white{
    color: #ffffff;
}

.txt-yellow{
    color: $color-blue-normal;
}

.txt-black{
    color: #000000;
}

.h-white{
	h1, .alfa,
	h2, .beta,
	h3, .gamma,
	h4, .delta,
	h5, .epsilon,
	h6, .zeta{
		color: #ffffff;
	}
}

.h-blue{
	h1, .alfa,
	h2, .beta,
	h3, .gamma,
	h4, .delta,
	h5, .epsilon,
	h6, .zeta{
		color: $color-blue-normal;
	}
}

.h-blue-light{
	h1, .alfa,
	h2, .beta,
	h3, .gamma,
	h4, .delta,
	h5, .epsilon,
	h6, .zeta{
		color: $color-blue-light;
	}
}

.h-green{
	h1, .alfa,
	h2, .beta,
	h3, .gamma,
	h4, .delta,
	h5, .epsilon,
	h6, .zeta{
		color: $color-green-normal;
	}
}

.h-black-normal{
	h1, .alfa,
	h2, .beta,
	h3, .gamma,
	h4, .delta,
	h5, .epsilon,
	h6, .zeta{
		color: $color-black-normal;
	}
}

.p-white{
	p{
		color: #ffffff;
	}
}

.p-blue-light{
	p{
		color: $color-blue-light;
	}
}

.ul-white{
	li{
		color: #ffffff!important;
		&:before{
			background-color: #ffffff!important;
		}
	}
}

.ol-white{
	li{
		color: #ffffff!important;
		&:before{
			color: #ffffff!important;
		}
	}
}
