/* Buttons helper */

.btn {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	margin: 0;
	padding: 1.4em 1.2rem;
	font-weight: 500;
	line-height: 1.2;
	cursor: pointer;
	text-decoration: none;
	text-transform: uppercase;
	color: #ffffff;
	border: 0;
	font-family: $font-primary;
	outline: none !important;
	background: $color-black-normal;
	text-align: center;
	letter-spacing: 3.48px;

	@include transition(background-color, 250ms, ease);
	@include border-radius(5px);
	@include font-size(12px);
	letter-spacing: 3.5px;
	-webkit-appearance: none;
	&,
	&:visited,
	&:active,
	&:focus {
		color: white;
	}

	&:hover {
		@include transition(background-color, 250ms, ease);
		@include bp(medium) {
			cursor: pointer;
			text-decoration: none;
			color: white;
		}
	}
}

.btn-responsive {
	width: 100%;
	padding-right: 0;
	padding-left: 0;
	text-align: center;
}


@include bp(rev-xsmall){
	.btn-xs-responsive {
		width: 100%;
		padding-right: 0;
		padding-left: 0;
		text-align: center;
	}
}


.btn-size-small {
	@include font-size(12px);
}

.btn-size-normal {
	@include font-size(14px);
}

.btn-size-big {
	@include font-size(18px);
}

.btn-size-large {
	@include font-size(20px);
}

.btn-small {
	letter-spacing: 1.34px;
	padding: 0.5em 0.5rem;
}

.btn-normal {
	padding: 1.5em 1.5rem;
}

.btn-big {
	padding: 2em 2rem;
}

.btn-min-width-small {
	min-width: 140px;
}

.btn-min-width-normal {
	min-width: 220px;
}

.btn-min-width-big {
	min-width: 250px;
}

.btn-max-width-big {
	max-width: 250px;
}

.btn-black-fill {
	color: #ffffff;
	border: 2px solid transparent;
	background-color: #000000;
	&,
	&:visited,
	&:active,
	&:focus {
		color: #ffffff;
	}
	&:hover {
		color: #000000;
		background-color: #ffffff;
		.btn-icon {
			color: #000000;
		}
	}
	.btn-icon {
		color: #ffffff;
	}
}

.btn-green-light-fill {
	color: #ffffff;
	border: 1px solid transparent;
	background-color: #4cc4a9;
	&,
	&:visited,
	&:active,
	&:focus {
		color: #ffffff;
	}
	&:hover {
		background-color: #ffffff;
		color: $color-green-normal;
	}
}

.btn-green-fill {
	color: #ffffff;
	border: 2px solid transparent;
	background-color: $color-green-normal;
	&,
	&:visited,
	&:active,
	&:focus {
		color: #ffffff;
	}
	&:hover {
		background-color: darken($color-green-normal, 10%);
		color: #ffffff;
	}
}


.btn-blue-light-outline {
	color: $color-blue-light;
	border: 2px solid $color-blue-light;
	background-color: transparent;
	&,
	&:visited,
	&:active,
	&:focus {
		color: $color-blue-light;
	}
	&:hover {
		color: $color-blue-light;
		border: 2px solid #ffffff;
		background-color: #ffffff;
	}
}

.btn-arrow-right{
	position: relative;
	padding-right: 40px;
	@include bp(medium){
		padding-right: 60px;
	}
	&:after{
		content: '';
		display: inline-block;
		position: absolute;
		top: 50%;
		right: 15px;
		transform: translateY(-50%);
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 4.5px 0 4.5px 5px;
		border-color: transparent transparent transparent $color-blue-normal;
		@include bp(medium){
			right: 25px;
		}
	}
}

.btn-arrow{
	position: relative;
	display: block;
	width: 7px;
	height: 14px;
	&:after{
		content: '';
		display: inline-block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 7px 0 7px 7px;
		border-color: transparent transparent transparent $color-green-normal;
	}
}

.btn-arrow-small{
	&:after{
		display: inline-block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 4.5px 0 4.5px 5px;
		border-color: transparent transparent transparent $color-green-normal;
	}
}

.btn-group{
	@include bp(xsmall){
		display: flex;
		align-items: center;
	}
	@include bp(rev-xsmall){
		.btn-item{
			margin-bottom: 1rem;
		}
	}
}