.opacity-0{
	opacity: 0;
}

.opacity-01{
	opacity: 0.1;
}

.opacity-02{
	opacity: 0.2;
}

.opacity-03{
	opacity: 0.3;
}

.opacity-04{
	opacity: 0.4;
}

.opacity-05{
	opacity: 0.5;
}

.opacity-06{
	opacity: 0.6;
}

.opacity-07{
	opacity: 0.7;
}

.opacity-08{
	opacity: 0.8;
}

.opacity-09{
	opacity: 0.9;
}

.opacity-1{
	opacity: 1;
}