.select2-container{
	z-index: 9;
	&.select2-container--open,
	.select2-container--focus{
		.select2-selection--single,
		.select2-selection--multiple{
			.select2-selection__arrow{
			 	&:after{
			 		@include transform(rotate(-180deg));
				}
			}
		}
	}
	.select2-search--inline .select2-search__field{
		margin-top: 0;
	}

	.select2-search--inline{
		display: block;
		width: 100%;
	}

	.select2-selection--single,
	.select2-selection--multiple{
		width: 100%;
		height: 70px;
		padding: 20px 0 1px 2rem;
		padding-bottom: 1px;
		font-family: $font-primary;
		font-weight: 600;
		line-height: 1.2;
		letter-spacing: 0px;
		color: $color-blue-normal;
        border: 0;
        outline: none!important;
		background-color: transparent;

		@include font-size(22px);
		@include border-radius(0);
		@include bp(small){
			@include font-size(25px);
		}
		@include bp(excl-large){
			@include font-size(22px);
			padding-left: 1rem;
		}

		.select2-selection__rendered{
			padding: 0;
			line-height: 50px;
       		color: $color-blue-normal;
		}
		.select2-selection__placeholder{
       		color: $color-blue-normal;
		}
		.select2-selection__arrow{
			position: absolute;
			top: -15px;
			right: 10px;
			width: 50px;
			height: 50px;
			line-height: 50px;
			text-align: center;
			&:after{
				content: ""!important;
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				margin-left: -14px;
				margin-top: -8px;
				width: 28px;
				height: 16px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				background-image: url('../images/icons/filter-arrow.svg');
			}
			b{
				display: none;
			}
		}
	}
}

.select2-container .select2-container--focus .select2-selection--single .select2-selection__arrow:after, 
.select2-container.select2-container--open .select2-selection--single .select2-selection__arrow:after{
	content: "\e91a";
}



.select2-dropdown{
	border: 0;
    // border: 1px solid #e6e6e6;
    // border-top: 0;
	border-radius: 0;
    background-color: white;
    box-shadow: 0 15px 20px rgba(0, 0, 0, 0.03);
	.select2-results__options--nested .select2-results__option{
		padding: 0 25px;
	}

	&.select2-dropdown--above{
    	box-shadow: 0 -15px 20px rgba(0, 0, 0, 0.03);
	}

	.select2-results__options{
		max-height: 220px!important;
		.select2-results__option[aria-selected=true]{
			color: #ffffff;
			background-color: $color-blue-normal;
		}
		.select2-results__option{
			padding: 1.2rem 2rem;
			font-family: $font-primary;
			font-weight: 600;
			letter-spacing: 0px;
			color: $color-blue-normal;

			@include font-size(18px);
			@include bp(excl-large){
				padding: 1.2rem 1rem;
			}
		}
		.select2-results__option--highlighted{
			color: $color-blue-normal;
			background-color: #f5f5f5;
		}
	}
}