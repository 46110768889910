/* Text helpers */

.txt-r {
	text-align: right;
}

.txt-c {
	text-align: center;
}

.txt-l {
	text-align: left;
}

.txt-upper {
	text-transform: uppercase;
}

.txt-lower {
	text-transform: lowercase;
}

.txt-italic{
	font-style: italic;
}

.txt-light{
	font-weight: 300;
}

.txt-regular{
	font-weight: 400;
}

.txt-bold{
	font-weight: 700;
}

.txt-line {
	text-decoration: line-through;
}

.txt-underline {
	text-decoration: underline;
}

.h-light{
	h1, .alfa,
	h2, .beta,
	h3, .gamma,
	h4, .delta,
	h5, .epsilon,
	h6, .zeta{
		font-weight: 300;
	}
}

.h-regular{
	h1, .alfa,
	h2, .beta,
	h3, .gamma,
	h4, .delta,
	h5, .epsilon,
	h6, .zeta{
		font-weight: 500;
	}
}

.h-semibold{
	h1, .alfa,
	h2, .beta,
	h3, .gamma,
	h4, .delta,
	h5, .epsilon,
	h6, .zeta{
		font-weight: 600;
	}
}

.h-bold{
	h1, .alfa,
	h2, .beta,
	h3, .gamma,
	h4, .delta,
	h5, .epsilon,
	h6, .zeta{
		font-weight: 700;
	}
}

.h-black{
	h1, .alfa,
	h2, .beta,
	h3, .gamma,
	h4, .delta,
	h5, .epsilon,
	h6, .zeta{
		font-weight: 900;
	}
}

.h-primary-semi{
	h1, .alfa,
	h2, .beta,
	h3, .gamma,
	h4, .delta,
	h5, .epsilon,
	h6, .zeta{
		font-family: $font-primary;
		font-weight: 600;
	}	
}

.h-upper{
	h1, .alfa,
	h2, .beta,
	h3, .gamma,
	h4, .delta,
	h5, .epsilon,
	h6, .zeta{
		text-transform: uppercase;
	}
}

.h-line-1-2{
	h1, .alfa,
	h2, .beta,
	h3, .gamma,
	h4, .delta,
	h5, .epsilon,
	h6, .zeta{
		line-height: 1.2;
	}
}

.h-line-1-4{
	h1, .alfa,
	h2, .beta,
	h3, .gamma,
	h4, .delta,
	h5, .epsilon,
	h6, .zeta{
		line-height: 1.4;
	}
}

.h-line-1-6{
	h1, .alfa,
	h2, .beta,
	h3, .gamma,
	h4, .delta,
	h5, .epsilon,
	h6, .zeta{
		line-height: 1.6;
	}
}

.h-line-1-8{
	h1, .alfa,
	h2, .beta,
	h3, .gamma,
	h4, .delta,
	h5, .epsilon,
	h6, .zeta{
		line-height: 1.8;
	}
}


@include bp(xsmall){
	.h-line-xs-1-2{
		h1, .alfa,
		h2, .beta,
		h3, .gamma,
		h4, .delta,
		h5, .epsilon,
		h6, .zeta{
			line-height: 1.2;
		}
	}
}

@include bp(small){
	.h-line-sm-1-2{
		h1, .alfa,
		h2, .beta,
		h3, .gamma,
		h4, .delta,
		h5, .epsilon,
		h6, .zeta{
			line-height: 1.2;
		}
	}
}

@include bp(xssmall) {
	.txt-xss-c {
		text-align: center;
	}
	.txt-xss-l {
		text-align: left;
	}
	.txt-xss-r {
		text-align: right;
	}
}

@include bp(xsmall) {
	.txt-xs-c {
		text-align: center;
	}
	.txt-xs-l {
		text-align: left;
	}
	.txt-xs-r {
		text-align: right;
	}
}

@include bp(small) {
	.txt-sm-l {
		text-align: left;
	}
	.txt-sm-r {
		text-align: right;
	}
	.txt-sm-c {
		text-align: center;
	}
}

@include bp(medium) {
	.txt-md-l {
		text-align: left;
	}
	.txt-md-r {
		text-align: right;
	}
	.txt-md-c {
		text-align: center;
	}
}

@include bp(large) {
	.txt-lg-r {
		text-align: right;
	}
	.txt-lg-l {
		text-align: left;
	}
	.txt-lg-c {
		text-align: center;
	}
}


// Headlines margin

.h-margin-0{
	.alfa, .beta, .gamma, .delta, .epsilon, .zeta,
	h1,h2,h3,h4,h5, h6{
		margin-bottom: 0;
	}
}

.h-margin-01{
	.alfa, .beta, .gamma, .delta, .epsilon, .zeta,
	h1,h2,h3,h4,h5, h6{
		margin-bottom: 0.1em;
	}
}

.h-margin-02{
	.alfa, .beta, .gamma, .delta, .epsilon, .zeta,
	h1,h2,h3,h4,h5, h6{
		margin-bottom: 0.2em;
	}
}

.h-margin-03{
	.alfa, .beta, .gamma, .delta, .epsilon, .zeta,
	h1,h2,h3,h4,h5, h6{
		margin-bottom: 0.3em;
	}
}

.h-margin-04{
	.alfa, .beta, .gamma, .delta, .epsilon, .zeta,
	h1,h2,h3,h4,h5, h6{
		margin-bottom: 0.4em;
	}
}

.h-margin-05{
	.alfa, .beta, .gamma, .delta, .epsilon, .zeta,
	h1,h2,h3,h4,h5, h6{
		margin-bottom: 0.5em;
	}
}

.h-margin-06{
	.alfa, .beta, .gamma, .delta, .epsilon, .zeta,
	h1,h2,h3,h4,h5, h6{
		margin-bottom: 0.6em;
	}
}

.h-margin-07{
	.alfa, .beta, .gamma, .delta, .epsilon, .zeta,
	h1,h2,h3,h4,h5, h6{
		margin-bottom: 0.7em;
	}
}

.h-margin-08{
	.alfa, .beta, .gamma, .delta, .epsilon, .zeta,
	h1,h2,h3,h4,h5, h6{
		margin-bottom: 0.8em;
	}
}

.h-margin-1{
	.alfa, .beta, .gamma, .delta, .epsilon, .zeta,
	h1,h2,h3,h4,h5, h6{
		margin-bottom: 1em;
	}
}

.h-margin-1-5{
	.alfa, .beta, .gamma, .delta, .epsilon, .zeta,
	h1,h2,h3,h4,h5, h6{
		margin-bottom: 1.5em;
	}
}

.h-margin-2{
	.alfa, .beta, .gamma, .delta, .epsilon, .zeta,
	h1,h2,h3,h4,h5, h6{
		margin-bottom: 2em;
	}
}

// Paragraphs margin
.p-margin-0{
	p{
		margin-bottom: 0;
	}
}

.p-margin-01{
	p{
		margin-bottom: 0.1rem;
	}
}

.p-margin-02{
	p{
		margin-bottom: 0.2rem;
	}
}

.p-margin-03{
	p{
		margin-bottom: 0.3rem;
	}
}

.p-margin-04{
	p{
		margin-bottom: 0.4rem;
	}
}

.p-margin-05{
	p{
		margin-bottom: 0.5rem;
	}
}

.p-margin-08{
	p{
		margin-bottom: 0.8rem;
	}
}

.p-margin-1{
	p{
		margin-bottom: 1rem;
	}
}

.p-margin-1-5{
	p{
		margin-bottom: 1.5rem;
	}
}

.p-margin-2{
	p{
		margin-bottom: 2rem;
	}
}