.offer-icon{
	display: flex;
	align-items: center;
	justify-content: center;
	height: 60px;
	margin-bottom: 1.2rem;
	svg{
		@include font-size(60px);
	}
}

.offer-icon-small{
	svg{
		@include font-size(60px);
	}
}

.offer-icon-big{
	svg{
		@include font-size(110px);
	}
}

.offer-group{
	justify-content: center;
	@include bp(medium){
		// margin-top: -80px;
	}
}

.offer-item{
	display: flex;
	align-items: center;
	margin-bottom: 1.2rem;
	@include bp(small){
		margin-bottom: 2rem;
	}
}

.offer-content{
	position: relative;
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	padding: 2rem 1rem;
	box-shadow: 0 4px 19px rgba(0, 0, 0, 0.07);
	border-radius: 4px;
	background-color: #ffffff;
	border-bottom: 2px solid #ffffff;
	// clip-path: polygon(100% 0, 100% 92%, 92% 100%, 0 100%, 0 0);
	transition: transform 250ms ease-out;
	height: 220px;
	@include bp(medium){
		padding: 1.5rem 1rem;
		height: 220px;
	}
	&:hover{
		border-bottom-color: $color-green-normal;
		transform: scale(1.1, 1.1);
	}
	.btn-arrow{
		position: absolute;
		bottom: 0.7rem;
		right: 0.7rem;
		@include bp(medium){
			bottom: 0.7rem;
			right: 0.7rem;
		}
	}
	.zeta{
		letter-spacing: 0.15px;
	}
	// &:before{
	// 	content: "";
	// 	position: absolute;
	// 	top: 50%;
	// 	left: 50%;
	// 	transform: translate(-50%, -50%);
	// 	display: block;
	// 	width: 320px;
	// 	height: 351px;
	// 	background-repeat: no-repeat;
	// 	background-position: center;
	// 	background-size: cover;
	// 	background-image: url('../images/square-cut.svg');
	// 	z-index: -1;
	// }
}