/* Image helpers */

.img {
    display: block;   
}

.img-full{
    width: 100%;
}

@include bp(rev-xsmall){
	.img-xs-full{
		width: 100%;
	}
}

.img-contain{
	height: 100%;
	object-fit: contain;
}

.img-responsive{
	display: block;
	max-width: 100%;
	height: auto;
}

.img-cover{
	height: 100%;
	object-fit: cover;
}

.img-hover{
	display: block;
	overflow: hidden;
	&:hover{
		> img{
			transform: scale(1.05, 1.05);
		}
	}
	img{
		transition: transform 400ms ease-out;
	}
}

.bg-image-hover{
	display: block;
	overflow: hidden;
	&:hover{
		> .bg-image{
			transform: scale(1.05, 1.05);
		}
	}
	.bg-image{
		transition: transform 400ms ease-out;
	}
}

.img-flex{
	display: flex;
	img{
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}

.img-flex-gallery{
	@include bp(xsmall){
		height: 380px;
	}
	@include bp(medium){
		height: 380px;
	}
	@include bp(rev-xsmall){
		height: 160px;
	}
	@include bp(excl-xxlarge){
		height: 380px;
	}
	@include bp(excl-xlarge){
		height: 380px;
	}
	@include bp(excl-large){
		height: 380px;
	}
}

.bg-image{
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.bg-image-offer{
	@include bp(rev-small){
		background-position: center right;
	}
}

.bg-overlay{
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(0,0,0, 0.5);
	display: block;
	width: 100%;
	height: 100%;
}

.bg-image-green-right{
	left: auto;
	right: 0;
	width: 50%;
	background-color: $color-green-normal;
	@include bp(rev-medium){
		width: 100vw;
		right: auto;
		left: calc(-50vw + 50%);
		z-index: -1;
	}
}

.bg-image-triangle-blue{
	left: auto;
	width: 311px;
	height: 341px;
	position: absolute;
	top: -60px;
	right: -80px;
	// opacity: 0.03;
}

.bg-image-triangle-blue-ext{
	width: 280px;
	height: 256px;
	position: absolute;
	top: auto;
	right: auto;
	left: -70px;
	bottom: 30px;
	// opacity: 0.03;
}

.bg-image-triangle-blue-ext-1{
	left: auto;
	top: 30%;
	transform: translateY(-50%);
	width: 280px;
	height: 256px;
	position: absolute;
	right: 0;
}

.bg-image-hero{
	z-index: 1;
}

.bg-image-hero-shape{
    top: 460px;
    z-index: 2;
    width: 100%;
    height: 766px;
    background-position: top;
    @include bp(rev-medium){
    	top: 400px;
    	height: 412px;
    }
    @media (min-width: 2000px){
    	top: 360px;
    	height: 1200px;
    }
}

.bg-image-pdf{
	background-position: center;
    @include bp(excl-medium){
		background-position: center bottom -30px;
    }
    @include bp(xsmall){
		background-position: bottom;
    }
}