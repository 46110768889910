/* Web & icon fonts */
/* @font-face {
	    font-family: $project-font-1;
	    src: url('../fonts/webfonts/fontname/fontname.eot');
	    src: url('../fonts/webfonts/fontname/fontname.eot?#iefix') format('embedded-opentype'), //         url('../fonts/webfonts/fontname/fontname.woff2') format('woff2'), //         url('../fonts/webfonts/fontname/fontname.woff') format('woff'), //         url('../fonts/webfonts/fontname/fontname.ttf') format('truetype');
	    font-weight: normal;
	    font-style: normal;
	    font-display: swap;
	

@font-face {
	    font-family: $project-font-2;
	    src: url('../fonts/webfonts/fontname/fontname-Bold.eot');
	    src: url('../fonts/webfonts/fontname/fontname-Bold.eot?#iefix') format('embedded-opentype'), //         url('../fonts/webfonts/fontname/fontname-Bold.woff2') format('woff2'), //         url('../fonts/webfonts/fontname/fontname-Bold.woff') format('woff'), //         url('../fonts/webfonts/fontname/fontname-Bold.ttf') format('truetype');
	    font-weight: normal;
	    font-style: normal;
	    font-display: swap;
	
}
*/
.ico-svg {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* .icon-close:before {
  content: "\e909";

} */
.icon-close {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  text-align: center;
  margin-left: -20px;
  margin-top: -20px;
}

.icon-close:before, .icon-close:after {
  position: absolute;
  display: block;
  width: 30px;
  height: 3px;
  content: '';
  background: #4f4f4f;
}

.icon-close:before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.icon-close:after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.body-test main,
.body-test header,
.body-test footer {
  opacity: 0.4 !important;
}

.body-test .img-test {
  display: block;
}

.img-test {
  display: none;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
}

/* Animate helpers */
@-webkit-keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading-spinner {
  display: inline-block;
  width: 60px;
  height: 60px;
  -webkit-animation-name: rotate-forever;
  animation-name: rotate-forever;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border: 5px solid #2244b3;
  border-right-color: transparent;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

/* Typography */
.typography p,
.typography li,
.typography td,
.typography th {
  line-height: 1.5;
}

.typography p,
.typography ul, .typography ol,
.typography h1, .typography h2, .typography h3, .typography h4, .typography h5, .typography h6,
.typography table,
.typography fieldset,
.typography blockquote,
.typography iframe {
  margin-bottom: 1rem;
}

.typography h1 {
  display: block;
  margin-top: 0;
  margin-bottom: 0;
  font-family: "univia-pro", Arial, sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  color: #4f4f4f;
  letter-spacing: 0.4px;
  font-size: 32px;
  font-size: 2rem;
}

@media (min-width: 771px) {
  .typography h1 {
    font-size: 37px;
    font-size: 2.3125rem;
  }
}

.typography h2 {
  display: block;
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-family: "univia-pro", Arial, sans-serif;
  font-weight: 700;
  color: #2244b3;
  text-align: center;
  letter-spacing: 0.35px;
  text-transform: uppercase;
  font-size: 30px;
  font-size: 1.875rem;
}

@media (min-width: 771px) {
  .typography h2 {
    margin-top: 4rem;
    margin-bottom: 4rem;
    font-size: 29px;
    font-size: 1.8125rem;
  }
}

.typography h2:after {
  content: '';
  position: relative;
  display: block;
  width: 48px;
  height: 10px;
  margin: 10px auto 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../images/icons/vlnka.svg");
}

.typography h3 {
  display: block;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  font-family: "univia-pro", Arial, sans-serif;
  font-weight: 500;
  color: #4f4f4f;
  letter-spacing: 0.2px;
  font-size: 22px;
  font-size: 1.375rem;
}

@media (min-width: 771px) {
  .typography h3 {
    font-size: 26px;
    font-size: 1.625rem;
  }
}

.typography h4 {
  display: block;
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-family: "univia-pro", Arial, sans-serif;
  color: #4f4f4f;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  font-size: 16px;
  font-size: 1rem;
}

@media (min-width: 771px) {
  .typography h4 {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.typography h5 {
  display: block;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-family: "univia-pro", Arial, sans-serif;
  font-weight: 500;
  color: #4f4f4f;
  letter-spacing: 0.1px;
  font-size: 14px;
  font-size: 0.875rem;
}

@media (min-width: 771px) {
  .typography h5 {
    font-size: 16px;
    font-size: 1rem;
  }
}

.typography h6 {
  display: block;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-family: "univia-pro", Arial, sans-serif;
  font-weight: 500;
  color: #4f4f4f;
  letter-spacing: 0.1px;
  font-size: 14px;
  font-size: 0.875rem;
}

@media (min-width: 771px) {
  .typography h6 {
    font-size: 16px;
    font-size: 1rem;
  }
}

.typography a {
  text-decoration: underline;
  color: #00ab84;
}

.typography a:hover {
  text-decoration: underline;
}

.typography p {
  display: block;
  margin-top: 0;
  margin-bottom: 2em;
  font-family: "univia-pro", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.8;
  color: #4f4f4f;
  font-size: 15px;
  font-size: 0.9375rem;
}

.typography strong {
  font-weight: 500;
  color: #00ab84;
}

.typography img {
  display: block;
  height: auto;
  max-width: 100%;
  margin-bottom: 2rem;
  width: 100%;
}

.typography ul,
.typography ol {
  margin-bottom: 2rem;
  padding-left: 0;
  list-style: none;
}

.typography ul li,
.typography ol li {
  position: relative;
  margin-bottom: 5px;
  padding-left: 25px;
  font-family: "univia-pro", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.8;
  color: #4f4f4f;
  font-size: 15px;
  font-size: 0.9375rem;
}

.typography ul li {
  padding-left: 30px;
}

.typography ul li:before {
  content: "";
  position: absolute;
  top: 7px;
  left: 3px;
  display: block;
  width: 15px;
  height: 12px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../images/icons/odrazka.svg");
}

.typography ol {
  list-style-position: inside;
  counter-reset: item;
}

.typography ol li {
  padding-left: 20px;
}

.typography ol li:before {
  position: absolute;
  top: 2px;
  left: 0;
  display: inline-block;
  line-height: 1.4;
  content: counter(item) ".";
  font-family: "univia-pro", Arial, sans-serif;
  counter-increment: item;
  color: #00ab84;
  font-weight: 600;
  font-size: 16px;
  font-size: 1rem;
}

.typography table {
  margin-bottom: 2rem;
}

.typography figure {
  position: relative;
  margin: 0;
  margin-bottom: 2em;
  box-shadow: 0 4px 19px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  background-color: #ffffff;
  overflow: hidden;
}

.typography figure img {
  margin: 0;
}

.typography .js-gallery-item {
  text-decoration: none !important;
}

.typography figcaption {
  font-family: "univia-pro", Arial, sans-serif;
  color: #858585;
  padding: 1.3rem 1.5rem;
  font-size: 12px;
  font-size: 0.75rem;
  border-bottom: 3px solid #00ab84;
  font-weight: 500;
  line-height: 1.6;
  background-color: #ffffff;
  letter-spacing: 0.36px;
}

.typography figcaption strong {
  display: block;
  color: #383838;
  font-size: 15px;
  font-size: 0.9375rem;
  letter-spacing: 0.4px;
  font-weight: 500;
}

.gallery-group figcaption {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  z-index: 2;
  border-bottom: 0;
  border-bottom: 2px solid #00ab84;
  padding: 1rem 1.2rem;
  border-radius: 4px;
}

@media (min-width: 993px) and (max-width: 1200px) {
  .gallery-group figcaption {
    padding: 0.4rem 0.6rem;
    left: 10px;
    right: 10px;
    bottom: 10px;
    font-size: 12px;
    font-size: 0.75rem;
  }
}

@media (max-width: 575px) {
  .gallery-group figcaption {
    padding: 0.4rem 0.6rem;
    left: 10px;
    right: 10px;
    bottom: 10px;
    font-size: 12px;
    font-size: 0.75rem;
  }
}

.gallery-group img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding: 2rem 1.5rem 2rem;
  border-radius: 5px;
  background-color: #f7f9ff;
  border-bottom: 2px solid #cd1434;
  margin-bottom: 2rem;
}

@media (min-width: 771px) {
  .info {
    padding: 2rem 2rem 2rem;
  }
}

.info .info-text {
  width: 15%;
  max-width: 15%;
  flex: 15%;
  display: inline-block;
  vertical-align: middle;
  color: #e03d20;
  font-family: "univia-pro", Arial, sans-serif;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.15px;
  text-transform: uppercase;
}

@media (max-width: 770px) {
  .info .info-text {
    display: block;
    width: 100%;
    max-width: 100%;
    flex: 100%;
    margin-bottom: 15px;
  }
}

.info p {
  display: inline-block;
  vertical-align: middle;
  width: 85%;
  max-width: 85%;
  flex: 85%;
  margin-bottom: 0;
  line-height: 1.6;
  padding-left: 2rem;
  font-weight: 300;
  letter-spacing: 0.14px;
  color: #4f4f4f;
}

@media (max-width: 770px) {
  .info p {
    width: 100%;
    max-width: 100%;
    flex: 100%;
    padding-left: 0;
  }
}

/* Form helpers */
.form-input, .form-textarea {
  width: 100%;
  height: 60px;
  padding: 0.2rem 0;
  font-family: "univia-pro", Arial, sans-serif;
  font-weight: 700;
  color: #ffffff;
  border: 0;
  border-bottom: 2px solid #ffffff;
  outline: none !important;
  background-color: transparent;
  font-size: 14px;
  font-size: 0.875rem;
  border-radius: 0;
  letter-spacing: 3.5px;
  -webkit-appearance: none;
}

.form-input-ext {
  border: 0;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
}

.form-textarea {
  height: 190px;
  padding: 1rem 1.5rem;
  line-height: 1.5rem;
  resize: none;
  border: 2px solid #ffffff;
  border-radius: 5px;
}

@media (min-width: 576px) {
  .form-textarea-limit {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    resize: none;
    min-height: 190px;
    max-height: 400px;
  }
}

@media (max-width: 575px) {
  .form-textarea-limit {
    height: 200px !important;
  }
}

.form-select {
  width: 100%;
  height: 60px;
  padding: 0.2rem 1.5rem;
  font-family: "univia-pro", Arial, sans-serif;
  font-weight: 700;
  line-height: 1.2;
  color: #4f4f4f;
  border: 2px solid #4f4f4f;
  outline: none;
  background-color: #ffffff;
  background-color: #ffffff;
  font-size: 16px;
  font-size: 1rem;
  -webkit-appearance: value;
  -moz-appearance: value;
  -ms-appearance: value;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

.form-icon {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 60px;
  height: 60px;
  font-size: 22px;
  font-size: 1.375rem;
  color: #4f4f4f;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.form-icon-block .form-input {
  padding-right: 60px;
}

.form-label {
  display: block;
  color: #ffffff;
  font-family: "univia-pro", Arial, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 3.5px;
}

.form-line-small {
  margin-bottom: 0.5rem;
}

.form-line-normal {
  margin-bottom: 2rem;
}

.form-line-big {
  margin-bottom: 2rem;
}

@media (min-width: 771px) {
  .form-line-big {
    margin-bottom: 4rem;
  }
}

.form-placeholder {
  position: relative;
  z-index: 1;
}

.form-placeholder.is-active .form-label {
  width: calc(100% - 22px);
  transform: translateX(0) translateY(1px);
  font-size: 12px;
  font-size: 0.75rem;
  padding: 10px 0 5px;
}

.form-placeholder.is-active .form-label-offset {
  padding-left: 25px;
}

.form-placeholder .form-input {
  padding: 30px 0 13px;
}

.form-placeholder .form-textarea {
  padding: 30px 25px 13px;
}

.form-placeholder .select2-container .select2-selection--single {
  padding: 7px 40px 0 25px;
}

.form-placeholder .form-label {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  margin: 0;
  cursor: text;
  transition: all .3s ease;
  transform: translateX(0) translateY(8px);
  pointer-events: none;
  padding: 15px 0 10px;
}

.form-placeholder .form-label-offset {
  padding-left: 25px;
}

.form-placeholder-textarea.is-active .form-label {
  transform: translateX(2px) translateY(2px);
}

.form-placeholder-textarea .form-label {
  transform: translateX(2px) translateY(8px);
  background-color: #00ab84;
}

.parsley-error.select2-container .select2-selection--single,
.parsley-error.select-classic .select2-container .select2-selection--multiple, .parsley-error.form-textarea, .parsley-error.form-input {
  border-color: #cd1434 !important;
}

.parsley-error + .select2-container .select2-selection--single,
.parsley-error + .select-classic .select2-container .select2-selection--multiple {
  border-color: #cd1434 !important;
}

.parsley-success.select2-container .select2-selection--single,
.parsley-success.select-classic .select2-container .select2-selection--multiple, .parsley-success.form-textarea, .parsley-success.form-input {
  border-color: #ffffff !important;
}

.parsley-success + .select2-container .select2-selection--single,
.parsley-success + .select-classic .select2-container .select2-selection--multiple {
  border-color: #ffffff !important;
}

.parsley-errors-list {
  color: #cd1434;
  text-align: right;
  font-size: 14px;
  font-size: 0.875rem;
}

.parsley-errors-list li {
  display: block;
  margin-top: 5px;
}

/* .checkbox.radiobox
	input#checkbox_payment_1.checkbox-hidden(type='radio', name="payment" required='')
	label.checkbox-label(for='checkbox_payment_1')
		span.checkbox-box
		span.checkbox-text Platba kartou */
/* Checkbox helpers */
.checkbox {
  margin-bottom: 2rem;
}

.checkbox-hidden {
  position: absolute;
  opacity: 0;
}

.checkbox-label {
  margin: 0;
  padding-left: 0;
  min-height: inherit;
  width: 100%;
  display: flex;
  align-items: center;
  vertical-align: middle;
  padding-top: 0;
  cursor: pointer;
  text-transform: none;
}

.checkbox-box {
  position: relative;
  width: 25px;
  height: 25px;
  border: 2px solid #4f4f4f;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  margin-left: 0;
}

.checkbox-text {
  display: block;
  position: relative;
  cursor: pointer;
  text-align: left;
  vertical-align: middle;
  color: #4f4f4f;
  font-size: 16px;
  font-size: 1rem;
  text-transform: uppercase;
  margin-left: 0;
}

.checkbox-hidden:checked + .checkbox-label .checkbox-box:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 7px;
  width: 7px;
  height: 13px;
  border: solid #4f4f4f;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
}

.radiobox {
  margin-bottom: 2rem;
}

.radiobox .checkbox-box {
  top: 0;
  width: 26px;
  height: 26px;
  border: 2px solid #4f4f4f;
  border-radius: 100%;
}

.radiobox .checkbox-hidden:checked + .checkbox-label .checkbox-box:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 100%;
  margin-top: -5px;
  margin-left: -5px;
  width: 10px;
  height: 10px;
  background-color: #4f4f4f;
}

/* Placeholder helpers */
input::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #817f7f;
}

input::-moz-input-placeholder,
select::-moz-input-placeholder,
textarea::-moz-input-placeholder {
  color: #817f7f;
}

input:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #817f7f;
}

.input-white input::-webkit-input-placeholder,
.input-white select::-webkit-input-placeholder,
.input-white textarea::-webkit-input-placeholder {
  color: white;
}

.input-white input::-moz-input-placeholder,
.input-white select::-moz-input-placeholder,
.input-white textarea::-moz-input-placeholder {
  color: white;
}

.input-white input:-ms-input-placeholder,
.input-white select:-ms-input-placeholder,
.input-white textarea:-ms-input-placeholder {
  color: white;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  color: transparent;
  opacity: 1;
}

input:focus::-moz-input-placeholder,
textarea:focus::-moz-input-placeholder {
  color: transparent;
  opacity: 1;
}

input:focus:-moz-input-placeholder,
textarea:focus:-moz-input-placeholder {
  color: transparent;
  opacity: 1;
}

/* Border helpers */
.border-0 {
  border: 0 !important;
}

.border-0-left {
  border-left: 0 !important;
}

.border-0-right {
  border-right: 0 !important;
}

.border-0-bottom {
  border-bottom: 0 !important;
}

.border-0-top {
  border-top: 0 !important;
}

.border-green-top {
  border-radius: 5px;
  border-top: 3px solid #00ab84;
}

/* Buttons helper */
.btn {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  margin: 0;
  padding: 1.4em 1.2rem;
  font-weight: 500;
  line-height: 1.2;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  color: #ffffff;
  border: 0;
  font-family: "univia-pro", Arial, sans-serif;
  outline: none !important;
  background: #4f4f4f;
  text-align: center;
  letter-spacing: 3.48px;
  transition: background-color 250ms ease;
  border-radius: 5px;
  font-size: 12px;
  font-size: 0.75rem;
  letter-spacing: 3.5px;
  -webkit-appearance: none;
}

.btn, .btn:visited, .btn:active, .btn:focus {
  color: white;
}

.btn:hover {
  transition: background-color 250ms ease;
}

@media (min-width: 992px) {
  .btn:hover {
    cursor: pointer;
    text-decoration: none;
    color: white;
  }
}

.btn-responsive {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
}

@media (max-width: 575px) {
  .btn-xs-responsive {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    text-align: center;
  }
}

.btn-size-small {
  font-size: 12px;
  font-size: 0.75rem;
}

.btn-size-normal {
  font-size: 14px;
  font-size: 0.875rem;
}

.btn-size-big {
  font-size: 18px;
  font-size: 1.125rem;
}

.btn-size-large {
  font-size: 20px;
  font-size: 1.25rem;
}

.btn-small {
  letter-spacing: 1.34px;
  padding: 0.5em 0.5rem;
}

.btn-normal {
  padding: 1.5em 1.5rem;
}

.btn-big {
  padding: 2em 2rem;
}

.btn-min-width-small {
  min-width: 140px;
}

.btn-min-width-normal {
  min-width: 220px;
}

.btn-min-width-big {
  min-width: 250px;
}

.btn-max-width-big {
  max-width: 250px;
}

.btn-black-fill {
  color: #ffffff;
  border: 2px solid transparent;
  background-color: #000000;
}

.btn-black-fill, .btn-black-fill:visited, .btn-black-fill:active, .btn-black-fill:focus {
  color: #ffffff;
}

.btn-black-fill:hover {
  color: #000000;
  background-color: #ffffff;
}

.btn-black-fill:hover .btn-icon {
  color: #000000;
}

.btn-black-fill .btn-icon {
  color: #ffffff;
}

.btn-green-light-fill {
  color: #ffffff;
  border: 1px solid transparent;
  background-color: #4cc4a9;
}

.btn-green-light-fill, .btn-green-light-fill:visited, .btn-green-light-fill:active, .btn-green-light-fill:focus {
  color: #ffffff;
}

.btn-green-light-fill:hover {
  background-color: #ffffff;
  color: #00ab84;
}

.btn-green-fill {
  color: #ffffff;
  border: 2px solid transparent;
  background-color: #00ab84;
}

.btn-green-fill, .btn-green-fill:visited, .btn-green-fill:active, .btn-green-fill:focus {
  color: #ffffff;
}

.btn-green-fill:hover {
  background-color: #00785d;
  color: #ffffff;
}

.btn-blue-light-outline {
  color: #77a4ee;
  border: 2px solid #77a4ee;
  background-color: transparent;
}

.btn-blue-light-outline, .btn-blue-light-outline:visited, .btn-blue-light-outline:active, .btn-blue-light-outline:focus {
  color: #77a4ee;
}

.btn-blue-light-outline:hover {
  color: #77a4ee;
  border: 2px solid #ffffff;
  background-color: #ffffff;
}

.btn-arrow-right {
  position: relative;
  padding-right: 40px;
}

@media (min-width: 992px) {
  .btn-arrow-right {
    padding-right: 60px;
  }
}

.btn-arrow-right:after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4.5px 0 4.5px 5px;
  border-color: transparent transparent transparent #2244b3;
}

@media (min-width: 992px) {
  .btn-arrow-right:after {
    right: 25px;
  }
}

.btn-arrow {
  position: relative;
  display: block;
  width: 7px;
  height: 14px;
}

.btn-arrow:after {
  content: '';
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 0 7px 7px;
  border-color: transparent transparent transparent #00ab84;
}

.btn-arrow-small:after {
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4.5px 0 4.5px 5px;
  border-color: transparent transparent transparent #00ab84;
}

@media (min-width: 576px) {
  .btn-group {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 575px) {
  .btn-group .btn-item {
    margin-bottom: 1rem;
  }
}

/* Color helpers */
.bg-black-normal {
  background-color: #000000;
}

.bg-gray-light {
  background-color: #f7f9ff;
}

.bg-green-normal {
  background-color: #00ab84;
}

.bg-blue-normal {
  background-color: #2244b3;
}

.txt-white {
  color: #ffffff;
}

.txt-yellow {
  color: #2244b3;
}

.txt-black {
  color: #000000;
}

.h-white h1, .h-white .alfa,
.h-white h2, .h-white .beta,
.h-white h3, .h-white .gamma,
.h-white h4, .h-white .delta,
.h-white h5, .h-white .epsilon,
.h-white h6, .h-white .zeta {
  color: #ffffff;
}

.h-blue h1, .h-blue .alfa,
.h-blue h2, .h-blue .beta,
.h-blue h3, .h-blue .gamma,
.h-blue h4, .h-blue .delta,
.h-blue h5, .h-blue .epsilon,
.h-blue h6, .h-blue .zeta {
  color: #2244b3;
}

.h-blue-light h1, .h-blue-light .alfa,
.h-blue-light h2, .h-blue-light .beta,
.h-blue-light h3, .h-blue-light .gamma,
.h-blue-light h4, .h-blue-light .delta,
.h-blue-light h5, .h-blue-light .epsilon,
.h-blue-light h6, .h-blue-light .zeta {
  color: #77a4ee;
}

.h-green h1, .h-green .alfa,
.h-green h2, .h-green .beta,
.h-green h3, .h-green .gamma,
.h-green h4, .h-green .delta,
.h-green h5, .h-green .epsilon,
.h-green h6, .h-green .zeta {
  color: #00ab84;
}

.h-black-normal h1, .h-black-normal .alfa,
.h-black-normal h2, .h-black-normal .beta,
.h-black-normal h3, .h-black-normal .gamma,
.h-black-normal h4, .h-black-normal .delta,
.h-black-normal h5, .h-black-normal .epsilon,
.h-black-normal h6, .h-black-normal .zeta {
  color: #4f4f4f;
}

.p-white p {
  color: #ffffff;
}

.p-blue-light p {
  color: #77a4ee;
}

.ul-white li {
  color: #ffffff !important;
}

.ul-white li:before {
  background-color: #ffffff !important;
}

.ol-white li {
  color: #ffffff !important;
}

.ol-white li:before {
  color: #ffffff !important;
}

/* Float helpers */
.float-0 {
  float: none;
}

.float-r {
  float: right;
}

.float-l {
  float: left;
}

@media (max-width: 1199px) {
  .float-lg-r {
    float: right;
  }
  .float-lg-l {
    float: left;
  }
}

@media (max-width: 991px) {
  .float-md-l {
    float: left;
  }
  .float-md-r {
    float: right;
  }
}

@media (max-width: 770px) {
  .float-sm-l {
    float: left;
  }
  .float-sm-r {
    float: right;
  }
}

@media (max-width: 575px) {
  .float-xs-l {
    float: left;
  }
  .float-xs-r {
    float: right;
  }
}

/* Gutter helpers */
.no-gutter {
  margin: 0;
}

.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.gutter-10.row {
  margin-right: -5px;
  margin-left: -5px;
}

.gutter-10 > [class*="col-"],
.gutter-10 > [class*=" col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.gutter-20.row {
  margin-right: -10px;
  margin-left: -10px;
}

.gutter-20 > [class*="col-"],
.gutter-20 > [class*=" col-"] {
  padding-right: 10px;
  padding-left: 10px;
}

.gutter-30.row {
  margin-right: -15px;
  margin-left: -15px;
}

.gutter-30 > [class*="col-"],
.gutter-30 > [class*=" col-"] {
  padding-right: 15px;
  padding-left: 15px;
}

.gutter-40.row {
  margin-right: -20px;
  margin-left: -20px;
}

.gutter-40 > [class*="col-"],
.gutter-40 > [class*=" col-"] {
  padding-right: 20px;
  padding-left: 20px;
}

.gutter-50.row {
  margin-right: -25px;
  margin-left: -25px;
}

.gutter-50 > [class*="col-"],
.gutter-50 > [class*=" col-"] {
  padding-right: 25px;
  padding-left: 25px;
}

.gutter-60.row {
  margin-right: -30px;
  margin-left: -30px;
}

.gutter-60 > [class*="col-"],
.gutter-60 > [class*=" col-"] {
  padding-right: 30px;
  padding-left: 30px;
}

@media (min-width: 576px) {
  .gutter-xs-10.row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .gutter-xs-10 > [class*="col-"],
  .gutter-xs-10 > [class*=" col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .gutter-xs-20.row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .gutter-xs-20 > [class*="col-"],
  .gutter-xs-20 > [class*=" col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
  .gutter-xs-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-xs-30 > [class*="col-"],
  .gutter-xs-30 > [class*=" col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-xs-40.row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .gutter-xs-40 > [class*="col-"],
  .gutter-xs-40 > [class*=" col-"] {
    padding-right: 20px;
    padding-left: 20px;
  }
  .gutter-xs-50.row {
    margin-right: -25px;
    margin-left: -25px;
  }
  .gutter-xs-50 > [class*="col-"],
  .gutter-xs-50 > [class*=" col-"] {
    padding-right: 25px;
    padding-left: 25px;
  }
  .gutter-xs-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-xs-60 > [class*="col-"],
  .gutter-xs-60 > [class*=" col-"] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-xs-100.row {
    margin-right: -50px;
    margin-left: -50px;
  }
  .gutter-xs-100 > [class*="col-"],
  .gutter-xs-100 > [class*=" col-"] {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 771px) {
  .gutter-sm-10.row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .gutter-sm-10 > [class*="col-"],
  .gutter-sm-10 > [class*=" col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .gutter-sm-20.row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .gutter-sm-20 > [class*="col-"],
  .gutter-sm-20 > [class*=" col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
  .gutter-sm-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-sm-30 > [class*="col-"],
  .gutter-sm-30 > [class*=" col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-sm-40.row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .gutter-sm-40 > [class*="col-"],
  .gutter-sm-40 > [class*=" col-"] {
    padding-right: 20px;
    padding-left: 20px;
  }
  .gutter-sm-50.row {
    margin-right: -25px;
    margin-left: -25px;
  }
  .gutter-sm-50 > [class*="col-"],
  .gutter-sm-50 > [class*=" col-"] {
    padding-right: 25px;
    padding-left: 25px;
  }
  .gutter-sm-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-sm-60 > [class*="col-"],
  .gutter-sm-60 > [class*=" col-"] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-sm-100.row {
    margin-right: -50px;
    margin-left: -50px;
  }
  .gutter-sm-100 > [class*="col-"],
  .gutter-sm-100 > [class*=" col-"] {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 992px) {
  .gutter-md-10.row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .gutter-md-10 > [class*="col-"],
  .gutter-md-10 > [class*=" col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .gutter-md-20.row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .gutter-md-20 > [class*="col-"],
  .gutter-md-20 > [class*=" col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
  .gutter-md-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-md-30 > [class*="col-"],
  .gutter-md-30 > [class*=" col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-md-40.row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .gutter-md-40 > [class*="col-"],
  .gutter-md-40 > [class*=" col-"] {
    padding-right: 20px;
    padding-left: 20px;
  }
  .gutter-md-50.row {
    margin-right: -25px;
    margin-left: -25px;
  }
  .gutter-md-50 > [class*="col-"],
  .gutter-md-50 > [class*=" col-"] {
    padding-right: 25px;
    padding-left: 25px;
  }
  .gutter-md-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-md-60 > [class*="col-"],
  .gutter-md-60 > [class*=" col-"] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-md-100.row {
    margin-right: -50px;
    margin-left: -50px;
  }
  .gutter-md-100 > [class*="col-"],
  .gutter-md-100 > [class*=" col-"] {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 1200px) {
  .gutter-lg-10.row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .gutter-lg-10 > [class*="col-"],
  .gutter-lg-10 > [class*=" col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .gutter-lg-20.row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .gutter-lg-20 > [class*="col-"],
  .gutter-lg-20 > [class*=" col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
  .gutter-lg-30.row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .gutter-lg-30 > [class*="col-"],
  .gutter-lg-30 > [class*=" col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .gutter-lg-40.row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .gutter-lg-40 > [class*="col-"],
  .gutter-lg-40 > [class*=" col-"] {
    padding-right: 20px;
    padding-left: 20px;
  }
  .gutter-lg-50.row {
    margin-right: -25px;
    margin-left: -25px;
  }
  .gutter-lg-50 > [class*="col-"],
  .gutter-lg-50 > [class*=" col-"] {
    padding-right: 25px;
    padding-left: 25px;
  }
  .gutter-lg-60.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .gutter-lg-60 > [class*="col-"],
  .gutter-lg-60 > [class*=" col-"] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .gutter-lg-100.row {
    margin-right: -50px;
    margin-left: -50px;
  }
  .gutter-lg-100 > [class*="col-"],
  .gutter-lg-100 > [class*=" col-"] {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 1900px) {
  .gutter-lg-200.row {
    margin-right: -100px;
    margin-left: -100px;
  }
  .gutter-lg-200 > [class*="col-"],
  .gutter-lg-200 > [class*=" col-"] {
    padding-right: 100px;
    padding-left: 100px;
  }
}

/* Hidden content */
.hidden-content {
  height: 0;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  -webkit-transition: opacity 250ms ease;
  -moz-transition: opacity 250ms ease;
  transition: opacity 250ms ease;
}

.hidden-content.is-active {
  height: auto;
  opacity: 1;
  overflow: visible;
  visibility: visible;
  -webkit-transition: opacity 250ms ease;
  -moz-transition: opacity 250ms ease;
  transition: opacity 250ms ease;
}

@media (min-width: 576px) {
  .hidden-xs-content {
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    -webkit-transition: opacity 250ms ease;
    -moz-transition: opacity 250ms ease;
    transition: opacity 250ms ease;
  }
  .hidden-xs-content.is-active {
    height: auto;
    opacity: 1;
    overflow: visible;
    visibility: visible;
    -webkit-transition: opacity 250ms ease;
    -moz-transition: opacity 250ms ease;
    transition: opacity 250ms ease;
  }
}

@media (min-width: 771px) {
  .hidden-sm-content {
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    -webkit-transition: opacity 250ms ease;
    -moz-transition: opacity 250ms ease;
    transition: opacity 250ms ease;
  }
  .hidden-sm-content.is-active {
    height: auto;
    opacity: 1;
    overflow: visible;
    visibility: visible;
    -webkit-transition: opacity 250ms ease;
    -moz-transition: opacity 250ms ease;
    transition: opacity 250ms ease;
  }
}

@media (min-width: 992px) {
  .hidden-md-content {
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    -webkit-transition: opacity 250ms ease;
    -moz-transition: opacity 250ms ease;
    transition: opacity 250ms ease;
  }
  .hidden-md-content.is-active {
    height: auto;
    opacity: 1;
    overflow: visible;
    visibility: visible;
    -webkit-transition: opacity 250ms ease;
    -moz-transition: opacity 250ms ease;
    transition: opacity 250ms ease;
  }
}

@media (min-width: 1200px) {
  .hidden-lg-content {
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    -webkit-transition: opacity 250ms ease;
    -moz-transition: opacity 250ms ease;
    transition: opacity 250ms ease;
  }
  .hidden-lg-content.is-active {
    height: auto;
    opacity: 1;
    overflow: visible;
    visibility: visible;
    -webkit-transition: opacity 250ms ease;
    -moz-transition: opacity 250ms ease;
    transition: opacity 250ms ease;
  }
}

/* Hidden class */
.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.overflow {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-visible {
  overflow: visible;
}

/* HR helper */
.hr-line {
  margin: 30px 0;
  height: 1px;
  background-color: #f7f9ff;
}

.hr-line-small {
  margin: 10px 0;
}

.hr-line-normal {
  margin: 20px 0;
}

.hr-line-big {
  margin: 20px 0;
}

.hr-line-width-small {
  width: 100px;
}

.hr-line-width-normal {
  width: 200px;
}

.hr-line-width-big {
  width: 300px;
}

.hr-line-center {
  margin: 0 auto;
}

.hr-line-black {
  background-color: #4f4f4f;
}

.hr-line-blue {
  background-color: #e8eeff;
}

/* Icon helpers */
.ico-size-small svg {
  font-size: 12px;
  font-size: 0.75rem;
}

.ico-size-normal svg {
  font-size: 12px;
  font-size: 0.75rem;
}

.icon-size-45 svg {
  font-size: 45px;
  font-size: 2.8125rem;
}

.icon-size-110 svg {
  font-size: 110px;
  font-size: 6.875rem;
}

/* Image helpers */
.img {
  display: block;
}

.img-full {
  width: 100%;
}

@media (max-width: 575px) {
  .img-xs-full {
    width: 100%;
  }
}

.img-contain {
  height: 100%;
  object-fit: contain;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.img-cover {
  height: 100%;
  object-fit: cover;
}

.img-hover {
  display: block;
  overflow: hidden;
}

.img-hover:hover > img {
  transform: scale(1.05, 1.05);
}

.img-hover img {
  transition: transform 400ms ease-out;
}

.bg-image-hover {
  display: block;
  overflow: hidden;
}

.bg-image-hover:hover > .bg-image {
  transform: scale(1.05, 1.05);
}

.bg-image-hover .bg-image {
  transition: transform 400ms ease-out;
}

.img-flex {
  display: flex;
}

.img-flex img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (min-width: 576px) {
  .img-flex-gallery {
    height: 380px;
  }
}

@media (min-width: 992px) {
  .img-flex-gallery {
    height: 380px;
  }
}

@media (max-width: 575px) {
  .img-flex-gallery {
    height: 160px;
  }
}

@media (min-width: 1441px) and (max-width: 1600px) {
  .img-flex-gallery {
    height: 380px;
  }
}

@media (min-width: 1201px) and (max-width: 1440px) {
  .img-flex-gallery {
    height: 380px;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .img-flex-gallery {
    height: 380px;
  }
}

.bg-image {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

@media (max-width: 770px) {
  .bg-image-offer {
    background-position: center right;
  }
}

.bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  width: 100%;
  height: 100%;
}

.bg-image-green-right {
  left: auto;
  right: 0;
  width: 50%;
  background-color: #00ab84;
}

@media (max-width: 991px) {
  .bg-image-green-right {
    width: 100vw;
    right: auto;
    left: calc(-50vw + 50%);
    z-index: -1;
  }
}

.bg-image-triangle-blue {
  left: auto;
  width: 311px;
  height: 341px;
  position: absolute;
  top: -60px;
  right: -80px;
}

.bg-image-triangle-blue-ext {
  width: 280px;
  height: 256px;
  position: absolute;
  top: auto;
  right: auto;
  left: -70px;
  bottom: 30px;
}

.bg-image-triangle-blue-ext-1 {
  left: auto;
  top: 30%;
  transform: translateY(-50%);
  width: 280px;
  height: 256px;
  position: absolute;
  right: 0;
}

.bg-image-hero {
  z-index: 1;
}

.bg-image-hero-shape {
  top: 460px;
  z-index: 2;
  width: 100%;
  height: 766px;
  background-position: top;
}

@media (max-width: 991px) {
  .bg-image-hero-shape {
    top: 400px;
    height: 412px;
  }
}

@media (min-width: 2000px) {
  .bg-image-hero-shape {
    top: 360px;
    height: 1200px;
  }
}

.bg-image-pdf {
  background-position: center;
}

@media (min-width: 772px) and (max-width: 992px) {
  .bg-image-pdf {
    background-position: center bottom -30px;
  }
}

@media (min-width: 576px) {
  .bg-image-pdf {
    background-position: bottom;
  }
}

[class*='aspect-ratio-'] {
  display: block;
  position: relative;
}

[class*='aspect-ratio-'] > img {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.aspect-ratio-wide {
  padding-top: 56.25%;
}

.aspect-ratio-square {
  padding-top: 100%;
}

/* Margin helpers */
.margin-0 {
  margin: 0 !important;
}

@media (min-width: 576px) {
  .margin-xs-0 {
    margin: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-sm-0 {
    margin: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-md-0 {
    margin: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-lg-0 {
    margin: 0 !important;
  }
}

@media (min-width: 1440px) {
  .margin-xl-0 {
    margin: 0 !important;
  }
}

.margin-bottom-01 {
  margin-bottom: 0.1rem;
}

.margin-bottom-02 {
  margin-bottom: 0.2rem;
}

.margin-bottom-03 {
  margin-bottom: 0.3rem;
}

.margin-bottom-04 {
  margin-bottom: 0.4rem;
}

.margin-bottom-05 {
  margin-bottom: 0.5rem;
}

.margin-bottom-08 {
  margin-bottom: 0.8rem;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}

.margin-bottom-1-5 {
  margin-bottom: 1.5rem;
}

.margin-bottom-2 {
  margin-bottom: 2rem;
}

.margin-bottom-3 {
  margin-bottom: 3rem;
}

.margin-bottom-4 {
  margin-bottom: 4rem;
}

.margin-bottom-5 {
  margin-bottom: 5rem;
}

.margin-bottom-6 {
  margin-bottom: 6rem;
}

.margin-bottom-7 {
  margin-bottom: 7rem;
}

.margin-bottom-8 {
  margin-bottom: 8rem;
}

.margin-bottom-9 {
  margin-bottom: 9rem;
}

.margin-bottom-10 {
  margin-bottom: 10rem;
}

@media (min-width: 576px) {
  .margin-bottom-xs-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-xs-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-xs-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-xs-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-xs-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-xs-6 {
    margin-bottom: 6rem;
  }
}

@media (min-width: 771px) {
  .margin-bottom-sm-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-sm-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-sm-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-sm-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-sm-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-sm-6 {
    margin-bottom: 6rem;
  }
}

@media (min-width: 992px) {
  .margin-bottom-md-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-md-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-md-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-md-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-md-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-md-6 {
    margin-bottom: 6rem;
  }
}

@media (min-width: 1200px) {
  .margin-bottom-lg-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-lg-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-lg-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-lg-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-lg-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-lg-6 {
    margin-bottom: 6rem;
  }
}

@media (min-width: 1440px) {
  .margin-bottom-xl-1 {
    margin-bottom: 1rem;
  }
  .margin-bottom-xl-2 {
    margin-bottom: 2rem;
  }
  .margin-bottom-xl-3 {
    margin-bottom: 3rem;
  }
  .margin-bottom-xl-4 {
    margin-bottom: 4rem;
  }
  .margin-bottom-xl-5 {
    margin-bottom: 5rem;
  }
  .margin-bottom-xl-6 {
    margin-bottom: 6rem;
  }
}

.margin-top-01 {
  margin-top: 0.1rem;
}

.margin-top-02 {
  margin-top: 0.2rem;
}

.margin-top-03 {
  margin-top: 0.3rem;
}

.margin-top-04 {
  margin-top: 0.4rem;
}

.margin-top-05 {
  margin-top: 0.5rem;
}

.margin-top-08 {
  margin-top: 0.8rem;
}

.margin-top-1 {
  margin-top: 1rem;
}

.margin-top-1-5 {
  margin-top: 1.5rem;
}

.margin-top-2 {
  margin-top: 2rem;
}

.margin-top-3 {
  margin-top: 3rem;
}

.margin-top-4 {
  margin-top: 4rem;
}

.margin-top-5 {
  margin-top: 5rem;
}

.margin-top-6 {
  margin-top: 6rem;
}

.margin-top-7 {
  margin-top: 7rem;
}

.margin-top-8 {
  margin-top: 8rem;
}

.margin-top-9 {
  margin-top: 9rem;
}

.margin-top-10 {
  margin-top: 10rem;
}

@media (min-width: 576px) {
  .margin-top-xs-1 {
    margin-top: 1rem;
  }
  .margin-top-xs-2 {
    margin-top: 2rem;
  }
  .margin-top-xs-3 {
    margin-top: 3rem;
  }
  .margin-top-xs-4 {
    margin-top: 4rem;
  }
  .margin-top-xs-5 {
    margin-top: 5rem;
  }
  .margin-top-xs-6 {
    margin-top: 6rem;
  }
}

@media (min-width: 771px) {
  .margin-top-sm-1 {
    margin-top: 1rem;
  }
  .margin-top-sm-2 {
    margin-top: 2rem;
  }
  .margin-top-sm-3 {
    margin-top: 3rem;
  }
  .margin-top-sm-4 {
    margin-top: 4rem;
  }
  .margin-top-sm-5 {
    margin-top: 5rem;
  }
  .margin-top-sm-6 {
    margin-top: 6rem;
  }
}

@media (min-width: 992px) {
  .margin-top-md-1 {
    margin-top: 1rem;
  }
  .margin-top-md-2 {
    margin-top: 2rem;
  }
  .margin-top-md-3 {
    margin-top: 3rem;
  }
  .margin-top-md-4 {
    margin-top: 4rem;
  }
  .margin-top-md-5 {
    margin-top: 5rem;
  }
  .margin-top-md-6 {
    margin-top: 6rem;
  }
}

@media (min-width: 1200px) {
  .margin-top-lg-1 {
    margin-top: 1rem;
  }
  .margin-top-lg-2 {
    margin-top: 2rem;
  }
  .margin-top-lg-3 {
    margin-top: 3rem;
  }
  .margin-top-lg-4 {
    margin-top: 4rem;
  }
  .margin-top-lg-5 {
    margin-top: 5rem;
  }
  .margin-top-lg-6 {
    margin-top: 6rem;
  }
}

@media (min-width: 1440px) {
  .margin-top-xl-1 {
    margin-top: 1rem;
  }
  .margin-top-xl-2 {
    margin-top: 2rem;
  }
  .margin-top-xl-3 {
    margin-top: 3rem;
  }
  .margin-top-xl-4 {
    margin-top: 4rem;
  }
  .margin-top-xl-5 {
    margin-top: 5rem;
  }
  .margin-top-xl-6 {
    margin-top: 6rem;
  }
}

.margin-left-01 {
  margin-left: 0.1rem;
}

.margin-left-02 {
  margin-left: 0.2rem;
}

.margin-left-03 {
  margin-left: 0.3rem;
}

.margin-left-04 {
  margin-left: 0.4rem;
}

.margin-left-05 {
  margin-left: 0.5rem;
}

.margin-left-08 {
  margin-left: 0.8rem;
}

.margin-left-1 {
  margin-left: 1rem;
}

.margin-left-1-5 {
  margin-left: 1.5rem;
}

.margin-left-2 {
  margin-left: 2rem;
}

.margin-left-3 {
  margin-left: 3rem;
}

.margin-left-4 {
  margin-left: 4rem;
}

.margin-left-5 {
  margin-left: 5rem;
}

.margin-left-6 {
  margin-left: 6rem;
}

.margin-left-7 {
  margin-left: 7rem;
}

.margin-left-8 {
  margin-left: 8rem;
}

.margin-left-9 {
  margin-left: 9rem;
}

.margin-left-10 {
  margin-left: 10rem;
}

@media (min-width: 576px) {
  .margin-left-xs-1 {
    margin-left: 1rem;
  }
  .margin-left-xs-2 {
    margin-left: 2rem;
  }
  .margin-left-xs-3 {
    margin-left: 3rem;
  }
  .margin-left-xs-4 {
    margin-left: 4rem;
  }
  .margin-left-xs-5 {
    margin-left: 5rem;
  }
  .margin-left-xs-6 {
    margin-left: 6rem;
  }
}

@media (min-width: 771px) {
  .margin-left-sm-1 {
    margin-left: 1rem;
  }
  .margin-left-sm-2 {
    margin-left: 2rem;
  }
  .margin-left-sm-3 {
    margin-left: 3rem;
  }
  .margin-left-sm-4 {
    margin-left: 4rem;
  }
  .margin-left-sm-5 {
    margin-left: 5rem;
  }
  .margin-left-sm-6 {
    margin-left: 6rem;
  }
}

@media (min-width: 992px) {
  .margin-left-md-1 {
    margin-left: 1rem;
  }
  .margin-left-md-2 {
    margin-left: 2rem;
  }
  .margin-left-md-3 {
    margin-left: 3rem;
  }
  .margin-left-md-4 {
    margin-left: 4rem;
  }
  .margin-left-md-5 {
    margin-left: 5rem;
  }
  .margin-left-md-6 {
    margin-left: 6rem;
  }
}

@media (min-width: 1200px) {
  .margin-left-lg-1 {
    margin-left: 1rem;
  }
  .margin-left-lg-2 {
    margin-left: 2rem;
  }
  .margin-left-lg-3 {
    margin-left: 3rem;
  }
  .margin-left-lg-4 {
    margin-left: 4rem;
  }
  .margin-left-lg-5 {
    margin-left: 5rem;
  }
  .margin-left-lg-6 {
    margin-left: 6rem;
  }
}

@media (min-width: 1440px) {
  .margin-left-xl-1 {
    margin-left: 1rem;
  }
  .margin-left-xl-2 {
    margin-left: 2rem;
  }
  .margin-left-xl-3 {
    margin-left: 3rem;
  }
  .margin-left-xl-4 {
    margin-left: 4rem;
  }
  .margin-left-xl-5 {
    margin-left: 5rem;
  }
  .margin-left-xl-6 {
    margin-left: 6rem;
  }
}

.margin-right-01 {
  margin-right: 0.1rem;
}

.margin-right-02 {
  margin-right: 0.2rem;
}

.margin-right-03 {
  margin-right: 0.3rem;
}

.margin-right-04 {
  margin-right: 0.4rem;
}

.margin-right-05 {
  margin-right: 0.5rem;
}

.margin-right-08 {
  margin-right: 0.8rem;
}

.margin-right-1 {
  margin-right: 1rem;
}

.margin-right-1-5 {
  margin-right: 1.5rem;
}

.margin-right-2 {
  margin-right: 2rem;
}

.margin-right-3 {
  margin-right: 3rem;
}

.margin-right-4 {
  margin-right: 4rem;
}

.margin-right-5 {
  margin-right: 5rem;
}

.margin-right-6 {
  margin-right: 6rem;
}

.margin-right-7 {
  margin-right: 7rem;
}

.margin-right-8 {
  margin-right: 8rem;
}

.margin-right-9 {
  margin-right: 9rem;
}

.margin-right-10 {
  margin-right: 10rem;
}

@media (min-width: 576px) {
  .margin-right-xs-1 {
    margin-right: 1rem;
  }
  .margin-right-xs-1-5 {
    margin-right: 1.5rem;
  }
  .margin-right-xs-2 {
    margin-right: 2rem;
  }
  .margin-right-xs-3 {
    margin-right: 3rem;
  }
  .margin-right-xs-4 {
    margin-right: 4rem;
  }
  .margin-right-xs-5 {
    margin-right: 5rem;
  }
  .margin-right-xs-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 771px) {
  .margin-right-sm-1 {
    margin-right: 1rem;
  }
  .margin-right-sm-2 {
    margin-right: 2rem;
  }
  .margin-right-sm-3 {
    margin-right: 3rem;
  }
  .margin-right-sm-4 {
    margin-right: 4rem;
  }
  .margin-right-sm-5 {
    margin-right: 5rem;
  }
  .margin-right-sm-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 992px) {
  .margin-right-md-1 {
    margin-right: 1rem;
  }
  .margin-right-md-2 {
    margin-right: 2rem;
  }
  .margin-right-md-3 {
    margin-right: 3rem;
  }
  .margin-right-md-4 {
    margin-right: 4rem;
  }
  .margin-right-md-5 {
    margin-right: 5rem;
  }
  .margin-right-md-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 1200px) {
  .margin-right-lg-1 {
    margin-right: 1rem;
  }
  .margin-right-lg-2 {
    margin-right: 2rem;
  }
  .margin-right-lg-3 {
    margin-right: 3rem;
  }
  .margin-right-lg-4 {
    margin-right: 4rem;
  }
  .margin-right-lg-5 {
    margin-right: 5rem;
  }
  .margin-right-lg-6 {
    margin-right: 6rem;
  }
}

@media (min-width: 1440px) {
  .margin-right-xl-1 {
    margin-right: 1rem;
  }
  .margin-right-xl-2 {
    margin-right: 2rem;
  }
  .margin-right-xl-3 {
    margin-right: 3rem;
  }
  .margin-right-xl-4 {
    margin-right: 4rem;
  }
  .margin-right-xl-5 {
    margin-right: 5rem;
  }
  .margin-right-xl-6 {
    margin-right: 6rem;
  }
}

.margin-center {
  margin: 0 auto;
}

@media (min-width: 576px) {
  .margin-xs-center {
    margin: 0 auto;
  }
}

@media (min-width: 771px) {
  .margin-sm-center {
    margin: 0 auto;
  }
}

@media (min-width: 992px) {
  .margin-md-center {
    margin: 0 auto;
  }
}

@media (min-width: 1200px) {
  .margin-lg-center {
    margin: 0 auto;
  }
}

.margin-offset-bottom-20 {
  margin-bottom: -20px;
}

@media (min-width: 771px) {
  .margin-sm-offset-bottom-50 {
    margin-bottom: -50px;
  }
}

.margin-top-0 {
  margin-top: 0 !important;
}

@media (min-width: 576px) {
  .margin-top-xs-0 {
    margin-top: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-top-sm-0 {
    margin-top: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-top-md-0 {
    margin-top: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-top-lg-0 {
    margin-top: 0 !important;
  }
}

@media (max-width: 1199px) {
  .margin-bp-top-lg-0 {
    margin-top: 0 !important;
  }
}

@media (max-width: 991px) {
  .margin-bp-top-md-0 {
    margin-top: 0 !important;
  }
}

@media (max-width: 770px) {
  .margin-bp-top-sm-0 {
    margin-top: 0 !important;
  }
}

@media (max-width: 575px) {
  .margin-bp-top-xs-0 {
    margin-top: 0 !important;
  }
}

.margin-right-0 {
  margin-right: 0 !important;
}

@media (min-width: 576px) {
  .margin-right-xs-0 {
    margin-right: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-right-sm-0 {
    margin-right: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-right-md-0 {
    margin-right: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-right-lg-0 {
    margin-right: 0 !important;
  }
}

@media (max-width: 1199px) {
  .margin-bp-right-lg-0 {
    margin-right: 0 !important;
  }
}

@media (max-width: 991px) {
  .margin-bp-right-md-0 {
    margin-right: 0 !important;
  }
}

@media (max-width: 770px) {
  .margin-bp-right-sm-0 {
    margin-right: 0 !important;
  }
}

@media (max-width: 575px) {
  .margin-bp-right-xs-0 {
    margin-right: 0 !important;
  }
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

@media (min-width: 576px) {
  .margin-bottom-xs-0 {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-bottom-sm-0 {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-bottom-md-0 {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-bottom-lg-0 {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 1199px) {
  .margin-bp-bottom-lg-0 {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 991px) {
  .margin-bp-bottom-md-0 {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 770px) {
  .margin-bp-bottom-sm-0 {
    margin-bottom: 0 !important;
  }
}

@media (max-width: 575px) {
  .margin-bp-bottom-xs-0 {
    margin-bottom: 0 !important;
  }
}

.margin-left-0 {
  margin-left: 0 !important;
}

@media (min-width: 576px) {
  .margin-left-xs-0 {
    margin-left: 0 !important;
  }
}

@media (min-width: 771px) {
  .margin-left-sm-0 {
    margin-left: 0 !important;
  }
}

@media (min-width: 992px) {
  .margin-left-md-0 {
    margin-left: 0 !important;
  }
}

@media (min-width: 1200px) {
  .margin-left-lg-0 {
    margin-left: 0 !important;
  }
}

@media (max-width: 1199px) {
  .margin-bp-left-lg-0 {
    margin-left: 0 !important;
  }
}

@media (max-width: 991px) {
  .margin-bp-left-md-0 {
    margin-left: 0 !important;
  }
}

@media (max-width: 770px) {
  .margin-bp-left-sm-0 {
    margin-left: 0 !important;
  }
}

@media (max-width: 575px) {
  .margin-bp-left-xs-0 {
    margin-left: 0 !important;
  }
}

/* Padding block */
.padding-0 {
  padding: 0 !important;
}

@media (min-width: 576px) {
  .padding-xs-0 {
    padding: 0 !important;
  }
}

@media (min-width: 771px) {
  .padding-sm-0 {
    padding: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-md-0 {
    padding: 0 !important;
  }
}

@media (min-width: 1440px) {
  .padding-xl-0 {
    padding: 0 !important;
  }
}

.padding-top-0 {
  padding-top: 0 !important;
}

.padding-top-xs-0 {
  padding-top: 0 !important;
}

@media (min-width: 771px) {
  .padding-top-sm-0 {
    padding-top: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-top-md-0 {
    padding-top: 0 !important;
  }
}

@media (min-width: 1200px) {
  .padding-top-lg-0 {
    padding-top: 0 !important;
  }
}

@media (max-width: 1199px) {
  .padding-bp-top-lg-0 {
    padding-top: 0 !important;
  }
}

@media (max-width: 991px) {
  .padding-bp-top-md-0 {
    padding-top: 0 !important;
  }
}

@media (max-width: 770px) {
  .padding-bp-top-sm-0 {
    padding-top: 0 !important;
  }
}

@media (max-width: 575px) {
  .padding-bp-top-xs-0 {
    padding-top: 0 !important;
  }
}

.padding-right-0 {
  padding-right: 0 !important;
}

.padding-right-xs-0 {
  padding-right: 0 !important;
}

@media (min-width: 771px) {
  .padding-right-sm-0 {
    padding-right: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-right-md-0 {
    padding-right: 0 !important;
  }
}

@media (min-width: 1200px) {
  .padding-right-lg-0 {
    padding-right: 0 !important;
  }
}

@media (max-width: 1199px) {
  .padding-bp-right-lg-0 {
    padding-right: 0 !important;
  }
}

@media (max-width: 991px) {
  .padding-bp-right-md-0 {
    padding-right: 0 !important;
  }
}

@media (max-width: 770px) {
  .padding-bp-right-sm-0 {
    padding-right: 0 !important;
  }
}

@media (max-width: 575px) {
  .padding-bp-right-xs-0 {
    padding-right: 0 !important;
  }
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.padding-bottom-xs-0 {
  padding-bottom: 0 !important;
}

@media (min-width: 771px) {
  .padding-bottom-sm-0 {
    padding-bottom: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-bottom-md-0 {
    padding-bottom: 0 !important;
  }
}

@media (min-width: 1200px) {
  .padding-bottom-lg-0 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 1199px) {
  .padding-bp-bottom-lg-0 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 991px) {
  .padding-bp-bottom-md-0 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 770px) {
  .padding-bp-bottom-sm-0 {
    padding-bottom: 0 !important;
  }
}

@media (max-width: 575px) {
  .padding-bp-bottom-xs-0 {
    padding-bottom: 0 !important;
  }
}

.padding-left-0 {
  padding-left: 0 !important;
}

.padding-left-xs-0 {
  padding-left: 0 !important;
}

@media (min-width: 771px) {
  .padding-left-sm-0 {
    padding-left: 0 !important;
  }
}

@media (min-width: 992px) {
  .padding-left-md-0 {
    padding-left: 0 !important;
  }
}

@media (min-width: 1200px) {
  .padding-left-lg-0 {
    padding-left: 0 !important;
  }
}

@media (max-width: 1199px) {
  .padding-bp-left-lg-0 {
    padding-left: 0 !important;
  }
}

@media (max-width: 991px) {
  .padding-bp-left-md-0 {
    padding-left: 0 !important;
  }
}

@media (max-width: 770px) {
  .padding-bp-left-sm-0 {
    padding-left: 0 !important;
  }
}

@media (max-width: 575px) {
  .padding-bp-left-xs-0 {
    padding-left: 0 !important;
  }
}

/* Position helpers */
.pos-r {
  position: relative;
}

.pos-f {
  position: fixed;
}

.pos-s {
  position: static;
}

.pos-sticky {
  position: sticky;
}

.pos-a {
  position: absolute;
}

.pos-auto {
  position: inherit;
}

/* Row helpers */
.row-40 {
  margin-right: -40px;
  margin-left: -40px;
}

.row-40 .row-item {
  padding-left: 40px;
  padding-right: 40px;
}

.row-30 {
  margin-right: -30px;
  margin-left: -30px;
}

.row-30 .row-item {
  padding-left: 30px;
  padding-right: 30px;
}

.row-20 {
  margin-right: -20px;
  margin-left: -20px;
}

.row-20 .row-item {
  padding-left: 20px;
  padding-right: 20px;
}

.row-10 {
  margin-right: -10px;
  margin-left: -10px;
}

.row-10 .row-item {
  padding-left: 10px;
  padding-right: 10px;
}

/* Size helpers */
.w-full {
  width: 100%;
}

.w-half {
  width: 50%;
}

.w-quarter {
  width: 25%;
}

.h-full-vh {
  height: 100vh;
}

.h-full {
  height: 100%;
}

.h-half {
  height: 50%;
}

.h-quarter {
  height: 25%;
}

/* Text helpers */
.txt-r {
  text-align: right;
}

.txt-c {
  text-align: center;
}

.txt-l {
  text-align: left;
}

.txt-upper {
  text-transform: uppercase;
}

.txt-lower {
  text-transform: lowercase;
}

.txt-italic {
  font-style: italic;
}

.txt-light {
  font-weight: 300;
}

.txt-regular {
  font-weight: 400;
}

.txt-bold {
  font-weight: 700;
}

.txt-line {
  text-decoration: line-through;
}

.txt-underline {
  text-decoration: underline;
}

.h-light h1, .h-light .alfa,
.h-light h2, .h-light .beta,
.h-light h3, .h-light .gamma,
.h-light h4, .h-light .delta,
.h-light h5, .h-light .epsilon,
.h-light h6, .h-light .zeta {
  font-weight: 300;
}

.h-regular h1, .h-regular .alfa,
.h-regular h2, .h-regular .beta,
.h-regular h3, .h-regular .gamma,
.h-regular h4, .h-regular .delta,
.h-regular h5, .h-regular .epsilon,
.h-regular h6, .h-regular .zeta {
  font-weight: 500;
}

.h-semibold h1, .h-semibold .alfa,
.h-semibold h2, .h-semibold .beta,
.h-semibold h3, .h-semibold .gamma,
.h-semibold h4, .h-semibold .delta,
.h-semibold h5, .h-semibold .epsilon,
.h-semibold h6, .h-semibold .zeta {
  font-weight: 600;
}

.h-bold h1, .h-bold .alfa,
.h-bold h2, .h-bold .beta,
.h-bold h3, .h-bold .gamma,
.h-bold h4, .h-bold .delta,
.h-bold h5, .h-bold .epsilon,
.h-bold h6, .h-bold .zeta {
  font-weight: 700;
}

.h-black h1, .h-black .alfa,
.h-black h2, .h-black .beta,
.h-black h3, .h-black .gamma,
.h-black h4, .h-black .delta,
.h-black h5, .h-black .epsilon,
.h-black h6, .h-black .zeta {
  font-weight: 900;
}

.h-primary-semi h1, .h-primary-semi .alfa,
.h-primary-semi h2, .h-primary-semi .beta,
.h-primary-semi h3, .h-primary-semi .gamma,
.h-primary-semi h4, .h-primary-semi .delta,
.h-primary-semi h5, .h-primary-semi .epsilon,
.h-primary-semi h6, .h-primary-semi .zeta {
  font-family: "univia-pro", Arial, sans-serif;
  font-weight: 600;
}

.h-upper h1, .h-upper .alfa,
.h-upper h2, .h-upper .beta,
.h-upper h3, .h-upper .gamma,
.h-upper h4, .h-upper .delta,
.h-upper h5, .h-upper .epsilon,
.h-upper h6, .h-upper .zeta {
  text-transform: uppercase;
}

.h-line-1-2 h1, .h-line-1-2 .alfa,
.h-line-1-2 h2, .h-line-1-2 .beta,
.h-line-1-2 h3, .h-line-1-2 .gamma,
.h-line-1-2 h4, .h-line-1-2 .delta,
.h-line-1-2 h5, .h-line-1-2 .epsilon,
.h-line-1-2 h6, .h-line-1-2 .zeta {
  line-height: 1.2;
}

.h-line-1-4 h1, .h-line-1-4 .alfa,
.h-line-1-4 h2, .h-line-1-4 .beta,
.h-line-1-4 h3, .h-line-1-4 .gamma,
.h-line-1-4 h4, .h-line-1-4 .delta,
.h-line-1-4 h5, .h-line-1-4 .epsilon,
.h-line-1-4 h6, .h-line-1-4 .zeta {
  line-height: 1.4;
}

.h-line-1-6 h1, .h-line-1-6 .alfa,
.h-line-1-6 h2, .h-line-1-6 .beta,
.h-line-1-6 h3, .h-line-1-6 .gamma,
.h-line-1-6 h4, .h-line-1-6 .delta,
.h-line-1-6 h5, .h-line-1-6 .epsilon,
.h-line-1-6 h6, .h-line-1-6 .zeta {
  line-height: 1.6;
}

.h-line-1-8 h1, .h-line-1-8 .alfa,
.h-line-1-8 h2, .h-line-1-8 .beta,
.h-line-1-8 h3, .h-line-1-8 .gamma,
.h-line-1-8 h4, .h-line-1-8 .delta,
.h-line-1-8 h5, .h-line-1-8 .epsilon,
.h-line-1-8 h6, .h-line-1-8 .zeta {
  line-height: 1.8;
}

@media (min-width: 576px) {
  .h-line-xs-1-2 h1, .h-line-xs-1-2 .alfa,
  .h-line-xs-1-2 h2, .h-line-xs-1-2 .beta,
  .h-line-xs-1-2 h3, .h-line-xs-1-2 .gamma,
  .h-line-xs-1-2 h4, .h-line-xs-1-2 .delta,
  .h-line-xs-1-2 h5, .h-line-xs-1-2 .epsilon,
  .h-line-xs-1-2 h6, .h-line-xs-1-2 .zeta {
    line-height: 1.2;
  }
}

@media (min-width: 771px) {
  .h-line-sm-1-2 h1, .h-line-sm-1-2 .alfa,
  .h-line-sm-1-2 h2, .h-line-sm-1-2 .beta,
  .h-line-sm-1-2 h3, .h-line-sm-1-2 .gamma,
  .h-line-sm-1-2 h4, .h-line-sm-1-2 .delta,
  .h-line-sm-1-2 h5, .h-line-sm-1-2 .epsilon,
  .h-line-sm-1-2 h6, .h-line-sm-1-2 .zeta {
    line-height: 1.2;
  }
}

@media (min-width: 370px) {
  .txt-xss-c {
    text-align: center;
  }
  .txt-xss-l {
    text-align: left;
  }
  .txt-xss-r {
    text-align: right;
  }
}

@media (min-width: 576px) {
  .txt-xs-c {
    text-align: center;
  }
  .txt-xs-l {
    text-align: left;
  }
  .txt-xs-r {
    text-align: right;
  }
}

@media (min-width: 771px) {
  .txt-sm-l {
    text-align: left;
  }
  .txt-sm-r {
    text-align: right;
  }
  .txt-sm-c {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .txt-md-l {
    text-align: left;
  }
  .txt-md-r {
    text-align: right;
  }
  .txt-md-c {
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .txt-lg-r {
    text-align: right;
  }
  .txt-lg-l {
    text-align: left;
  }
  .txt-lg-c {
    text-align: center;
  }
}

.h-margin-0 .alfa, .h-margin-0 .beta, .h-margin-0 .gamma, .h-margin-0 .delta, .h-margin-0 .epsilon, .h-margin-0 .zeta,
.h-margin-0 h1, .h-margin-0 h2, .h-margin-0 h3, .h-margin-0 h4, .h-margin-0 h5, .h-margin-0 h6 {
  margin-bottom: 0;
}

.h-margin-01 .alfa, .h-margin-01 .beta, .h-margin-01 .gamma, .h-margin-01 .delta, .h-margin-01 .epsilon, .h-margin-01 .zeta,
.h-margin-01 h1, .h-margin-01 h2, .h-margin-01 h3, .h-margin-01 h4, .h-margin-01 h5, .h-margin-01 h6 {
  margin-bottom: 0.1em;
}

.h-margin-02 .alfa, .h-margin-02 .beta, .h-margin-02 .gamma, .h-margin-02 .delta, .h-margin-02 .epsilon, .h-margin-02 .zeta,
.h-margin-02 h1, .h-margin-02 h2, .h-margin-02 h3, .h-margin-02 h4, .h-margin-02 h5, .h-margin-02 h6 {
  margin-bottom: 0.2em;
}

.h-margin-03 .alfa, .h-margin-03 .beta, .h-margin-03 .gamma, .h-margin-03 .delta, .h-margin-03 .epsilon, .h-margin-03 .zeta,
.h-margin-03 h1, .h-margin-03 h2, .h-margin-03 h3, .h-margin-03 h4, .h-margin-03 h5, .h-margin-03 h6 {
  margin-bottom: 0.3em;
}

.h-margin-04 .alfa, .h-margin-04 .beta, .h-margin-04 .gamma, .h-margin-04 .delta, .h-margin-04 .epsilon, .h-margin-04 .zeta,
.h-margin-04 h1, .h-margin-04 h2, .h-margin-04 h3, .h-margin-04 h4, .h-margin-04 h5, .h-margin-04 h6 {
  margin-bottom: 0.4em;
}

.h-margin-05 .alfa, .h-margin-05 .beta, .h-margin-05 .gamma, .h-margin-05 .delta, .h-margin-05 .epsilon, .h-margin-05 .zeta,
.h-margin-05 h1, .h-margin-05 h2, .h-margin-05 h3, .h-margin-05 h4, .h-margin-05 h5, .h-margin-05 h6 {
  margin-bottom: 0.5em;
}

.h-margin-06 .alfa, .h-margin-06 .beta, .h-margin-06 .gamma, .h-margin-06 .delta, .h-margin-06 .epsilon, .h-margin-06 .zeta,
.h-margin-06 h1, .h-margin-06 h2, .h-margin-06 h3, .h-margin-06 h4, .h-margin-06 h5, .h-margin-06 h6 {
  margin-bottom: 0.6em;
}

.h-margin-07 .alfa, .h-margin-07 .beta, .h-margin-07 .gamma, .h-margin-07 .delta, .h-margin-07 .epsilon, .h-margin-07 .zeta,
.h-margin-07 h1, .h-margin-07 h2, .h-margin-07 h3, .h-margin-07 h4, .h-margin-07 h5, .h-margin-07 h6 {
  margin-bottom: 0.7em;
}

.h-margin-08 .alfa, .h-margin-08 .beta, .h-margin-08 .gamma, .h-margin-08 .delta, .h-margin-08 .epsilon, .h-margin-08 .zeta,
.h-margin-08 h1, .h-margin-08 h2, .h-margin-08 h3, .h-margin-08 h4, .h-margin-08 h5, .h-margin-08 h6 {
  margin-bottom: 0.8em;
}

.h-margin-1 .alfa, .h-margin-1 .beta, .h-margin-1 .gamma, .h-margin-1 .delta, .h-margin-1 .epsilon, .h-margin-1 .zeta,
.h-margin-1 h1, .h-margin-1 h2, .h-margin-1 h3, .h-margin-1 h4, .h-margin-1 h5, .h-margin-1 h6 {
  margin-bottom: 1em;
}

.h-margin-1-5 .alfa, .h-margin-1-5 .beta, .h-margin-1-5 .gamma, .h-margin-1-5 .delta, .h-margin-1-5 .epsilon, .h-margin-1-5 .zeta,
.h-margin-1-5 h1, .h-margin-1-5 h2, .h-margin-1-5 h3, .h-margin-1-5 h4, .h-margin-1-5 h5, .h-margin-1-5 h6 {
  margin-bottom: 1.5em;
}

.h-margin-2 .alfa, .h-margin-2 .beta, .h-margin-2 .gamma, .h-margin-2 .delta, .h-margin-2 .epsilon, .h-margin-2 .zeta,
.h-margin-2 h1, .h-margin-2 h2, .h-margin-2 h3, .h-margin-2 h4, .h-margin-2 h5, .h-margin-2 h6 {
  margin-bottom: 2em;
}

.p-margin-0 p {
  margin-bottom: 0;
}

.p-margin-01 p {
  margin-bottom: 0.1rem;
}

.p-margin-02 p {
  margin-bottom: 0.2rem;
}

.p-margin-03 p {
  margin-bottom: 0.3rem;
}

.p-margin-04 p {
  margin-bottom: 0.4rem;
}

.p-margin-05 p {
  margin-bottom: 0.5rem;
}

.p-margin-08 p {
  margin-bottom: 0.8rem;
}

.p-margin-1 p {
  margin-bottom: 1rem;
}

.p-margin-1-5 p {
  margin-bottom: 1.5rem;
}

.p-margin-2 p {
  margin-bottom: 2rem;
}

/* Z-index helpers */
.z-index--1 {
  z-index: -1;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.z-index-6 {
  z-index: 6;
}

.z-index-7 {
  z-index: 7;
}

.z-index-8 {
  z-index: 8;
}

.z-index-9 {
  z-index: 9;
}

.z-index-10 {
  z-index: 10;
}

.z-index-100 {
  z-index: 100;
}

.z-index-1000 {
  z-index: 1000;
}

.z-index-10000 {
  z-index: 10000;
}

.opacity-0 {
  opacity: 0;
}

.opacity-01 {
  opacity: 0.1;
}

.opacity-02 {
  opacity: 0.2;
}

.opacity-03 {
  opacity: 0.3;
}

.opacity-04 {
  opacity: 0.4;
}

.opacity-05 {
  opacity: 0.5;
}

.opacity-06 {
  opacity: 0.6;
}

.opacity-07 {
  opacity: 0.7;
}

.opacity-08 {
  opacity: 0.8;
}

.opacity-09 {
  opacity: 0.9;
}

.opacity-1 {
  opacity: 1;
}

.dots {
  overflow: hidden;
}

.dots-10 {
  height: 10px;
}

.dots-20 {
  height: 20px;
}

.dots-30 {
  height: 30px;
}

.dots-40 {
  height: 40px;
}

.dots-50 {
  height: 50px;
}

.dots-60 {
  height: 60px;
}

.dots-70 {
  height: 70px;
}

.dots-80 {
  height: 80px;
}

.dots-90 {
  height: 90px;
}

.dots-100 {
  height: 100px;
}

.dots-110 {
  height: 160px;
}

.dots-120 {
  height: 120px;
}

.dots-130 {
  height: 130px;
}

.dots-140 {
  height: 140px;
}

.dots-150 {
  height: 150px;
}

@media (max-width: 991px) {
  .dots-md-10 {
    height: 10px;
  }
  .dots-md-20 {
    height: 20px;
  }
  .dots-md-30 {
    height: 30px;
  }
  .dots-md-40 {
    height: 40px;
  }
  .dots-md-50 {
    height: 50px;
  }
  .dots-md-60 {
    height: 60px;
  }
  .dots-md-70 {
    height: 70px;
  }
  .dots-md-80 {
    height: 80px;
  }
  .dots-md-90 {
    height: 90px;
  }
  .dots-md-100 {
    height: 100px;
  }
  .dots-md-110 {
    height: 160px;
  }
  .dots-md-120 {
    height: 120px;
  }
  .dots-md-130 {
    height: 130px;
  }
  .dots-md-140 {
    height: 140px;
  }
  .dots-md-150 {
    height: 150px;
  }
}

@media (max-width: 770px) {
  .dots-sm-10 {
    height: 10px;
  }
  .dots-sm-20 {
    height: 20px;
  }
  .dots-sm-30 {
    height: 30px;
  }
  .dots-sm-40 {
    height: 40px;
  }
  .dots-sm-50 {
    height: 50px;
  }
  .dots-sm-60 {
    height: 60px;
  }
  .dots-sm-70 {
    height: 70px;
  }
  .dots-sm-80 {
    height: 80px;
  }
  .dots-sm-90 {
    height: 90px;
  }
  .dots-sm-100 {
    height: 100px;
  }
  .dots-sm-110 {
    height: 160px;
  }
  .dots-sm-120 {
    height: 120px;
  }
  .dots-sm-130 {
    height: 130px;
  }
  .dots-sm-140 {
    height: 140px;
  }
  .dots-sm-150 {
    height: 150px;
  }
}

@media (max-width: 575px) {
  .dots-xs-10 {
    height: 10px;
  }
  .dots-xs-20 {
    height: 20px;
  }
  .dots-xs-30 {
    height: 30px;
  }
  .dots-xs-40 {
    height: 40px;
  }
  .dots-xs-50 {
    height: 50px;
  }
  .dots-xs-60 {
    height: 60px;
  }
  .dots-xs-70 {
    height: 70px;
  }
  .dots-xs-80 {
    height: 80px;
  }
  .dots-xs-90 {
    height: 90px;
  }
  .dots-xs-100 {
    height: 100px;
  }
  .dots-xs-110 {
    height: 160px;
  }
  .dots-xs-120 {
    height: 120px;
  }
  .dots-xs-130 {
    height: 130px;
  }
  .dots-xs-140 {
    height: 140px;
  }
  .dots-xs-150 {
    height: 150px;
  }
}

.dots-max-10 {
  max-height: 10px;
}

.dots-max-20 {
  max-height: 20px;
}

.dots-max-30 {
  max-height: 30px;
}

.dots-max-40 {
  max-height: 40px;
}

.dots-max-50 {
  max-height: 50px;
}

.dots-max-60 {
  max-height: 60px;
}

.dots-max-70 {
  max-height: 70px;
}

.dots-max-80 {
  max-height: 80px;
}

.dots-max-90 {
  max-height: 90px;
}

.dots-max-100 {
  max-height: 100px;
}

.dots-max-110 {
  max-height: 160px;
}

.dots-max-120 {
  max-height: 120px;
}

.dots-max-130 {
  max-height: 130px;
}

.dots-max-140 {
  max-height: 140px;
}

.dots-max-150 {
  max-height: 150px;
}

@media (max-width: 991px) {
  .dots-md-max-10 {
    max-height: 10px;
  }
  .dots-md-max-20 {
    max-height: 20px;
  }
  .dots-md-max-30 {
    max-height: 30px;
  }
  .dots-md-max-40 {
    max-height: 40px;
  }
  .dots-md-max-50 {
    max-height: 50px;
  }
  .dots-md-max-60 {
    max-height: 60px;
  }
  .dots-md-max-70 {
    max-height: 70px;
  }
  .dots-md-max-80 {
    max-height: 80px;
  }
  .dots-md-max-90 {
    max-height: 90px;
  }
  .dots-md-max-100 {
    max-height: 100px;
  }
  .dots-md-max-110 {
    max-height: 160px;
  }
  .dots-md-max-120 {
    max-height: 120px;
  }
  .dots-md-max-130 {
    max-height: 130px;
  }
  .dots-md-max-140 {
    max-height: 140px;
  }
  .dots-md-max-150 {
    max-height: 150px;
  }
}

@media (max-width: 770px) {
  .dots-sm-max-10 {
    max-height: 10px;
  }
  .dots-sm-max-20 {
    max-height: 20px;
  }
  .dots-sm-max-30 {
    max-height: 30px;
  }
  .dots-sm-max-40 {
    max-height: 40px;
  }
  .dots-sm-max-50 {
    max-height: 50px;
  }
  .dots-sm-max-60 {
    max-height: 60px;
  }
  .dots-sm-max-70 {
    max-height: 70px;
  }
  .dots-sm-max-80 {
    max-height: 80px;
  }
  .dots-sm-max-90 {
    max-height: 90px;
  }
  .dots-sm-max-100 {
    max-height: 100px;
  }
  .dots-sm-max-110 {
    max-height: 160px;
  }
  .dots-sm-max-120 {
    max-height: 120px;
  }
  .dots-sm-max-130 {
    max-height: 130px;
  }
  .dots-sm-max-140 {
    max-height: 140px;
  }
  .dots-sm-max-150 {
    max-height: 150px;
  }
}

@media (max-width: 575px) {
  .dots-xs-max-10 {
    max-height: 10px;
  }
  .dots-xs-max-20 {
    max-height: 20px;
  }
  .dots-xs-max-30 {
    max-height: 30px;
  }
  .dots-xs-max-40 {
    max-height: 40px;
  }
  .dots-xs-max-50 {
    max-height: 50px;
  }
  .dots-xs-max-60 {
    max-height: 60px;
  }
  .dots-xs-max-70 {
    max-height: 70px;
  }
  .dots-xs-max-80 {
    max-height: 80px;
  }
  .dots-xs-max-90 {
    max-height: 90px;
  }
  .dots-xs-max-100 {
    max-height: 100px;
  }
  .dots-xs-max-110 {
    max-height: 160px;
  }
  .dots-xs-max-120 {
    max-height: 120px;
  }
  .dots-xs-max-130 {
    max-height: 130px;
  }
  .dots-xs-max-140 {
    max-height: 140px;
  }
  .dots-xs-max-150 {
    max-height: 150px;
  }
}

.no-object-fit .img-flex img {
  height: auto;
  width: auto;
  vertical-align: middle;
  margin: 0 auto;
}

.no-object-fit .img-contain {
  height: auto;
  width: auto;
  vertical-align: middle;
  margin: 0 auto;
}

/* Base settings */
*, *::before, *::after {
  box-sizing: border-box;
}

* {
  transition: color .15s, background .15s, border .15s, opacity .15s;
}

*:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

::-moz-selection {
  color: #fff;
  background-color: #00ab84;
}

::selection {
  color: #fff;
  background-color: #00ab84;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background: #f7f9ff;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #0b204f;
}

::-webkit-scrollbar-thumb:hover {
  background: #081739;
}

@-ms-viewport {
  width: device-width;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

html {
  position: relative;
  box-sizing: border-box;
  height: 100%;
  font-size: 100%;
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 310px;
  height: 100%;
  margin: 0;
  color: #000;
  background-color: #ffffff;
  font-family: "univia-pro", Arial, sans-serif;
  font-weight: 500;
  line-height: 1;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  outline: none;
  font-family: "univia-pro", Arial, sans-serif;
}

a:focus, a:active, a:visited {
  outline: none;
}

.a-hover:hover, .a-hover:focus {
  text-decoration: underline;
  color: white;
}

.a-hover-link {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.a-hover-link-text {
  text-transform: uppercase;
  color: #00ab84;
  font-family: "univia-pro", Arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  font-size: 1rem;
}

.a-hover-link-text-green {
  color: #00ab84;
}

.a-hover-link-icon {
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;
  color: #00ab84;
  font-size: 24px;
  font-size: 1.5rem;
}

p {
  display: block;
  margin-top: 0;
  margin-bottom: 2em;
  color: #4f4f4f;
  font-family: "univia-pro", Arial, sans-serif;
  font-weight: 300;
  line-height: 1.8;
  font-size: 15px;
  font-size: 0.9375rem;
}

.p-large p {
  font-size: 16px;
  font-size: 1rem;
}

@media (min-width: 576px) {
  .p-large p {
    font-size: 16px;
    font-size: 1rem;
  }
}

@media (min-width: 771px) {
  .p-large p {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

@media (min-width: 992px) {
  .p-large p {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

.p-big p {
  font-size: 16px;
  font-size: 1rem;
}

@media (min-width: 771px) {
  .p-big p {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.p-normal p {
  line-height: 1.6;
  font-size: 14px;
  font-size: 0.875rem;
}

.p-small p {
  letter-spacing: 0.65px;
  line-height: 1.5;
  font-size: 13px;
  font-size: 0.8125rem;
}

.p-secondary p {
  font-family: "univia-pro", Arial, sans-serif;
  font-weight: 700;
}

.p-line-1-4 p {
  line-height: 1.4;
}

.p-line-1-6 p {
  line-height: 1.6;
}

.p-line-1-8 p {
  line-height: 1.8;
}

.p-line-2 p {
  line-height: 2;
}

.p-line-2-2 p {
  line-height: 2.2;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.list-style, ol.list-style {
  margin-bottom: 2em;
}

ul.list-style li, ol.list-style li {
  position: relative;
  display: block;
  margin-bottom: 8px;
  padding-left: 25px;
  color: #4f4f4f;
  font-family: "univia-pro", Arial, sans-serif;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.23px;
  font-size: 15px;
  font-size: 0.9375rem;
}

ul.list-style a, ol.list-style a {
  color: #00ab84;
}

ul.list-style a:hover, ol.list-style a:hover {
  text-decoration: underline;
}

ul.list-style li {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
  border-bottom: 1px solid #eff2f4;
}

@media (min-width: 576px) {
  ul.list-style li {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (min-width: 1200px) {
  ul.list-style li {
    padding-left: 80px;
  }
}

ul.list-style li:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  display: block;
  width: 19px;
  height: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../images/icons/check.svg");
}

@media (min-width: 1200px) {
  ul.list-style li:before {
    left: 50px;
  }
}

ol.list-style {
  list-style-position: inside;
  counter-reset: item;
}

ol.list-style li:before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  content: counter(item) ".";
  counter-increment: item;
  color: #00ab84;
  line-height: 1.4;
  font-size: 16px;
  font-size: 1rem;
}

em, i, .italic {
  font-style: italic;
}

strong, .strong {
  font-weight: 700;
}

small, .small {
  font-size: 12px;
  font-size: 0.75rem;
}

h1, .alfa, h2, .beta, h3, .gamma, h4, .delta, h5, .epsilon, h6, .zeta {
  margin: 0;
  margin-bottom: 1.5em;
  color: #2244b3;
  font-family: "univia-pro", Arial, sans-serif;
  font-weight: 700;
  line-height: 1.4;
}

h1 a, .alfa a, h2 a, .beta a, h3 a, .gamma a, h4 a, .delta a, h5 a, .epsilon a, h6 a, .zeta a {
  color: inherit;
}

h1, .alfa {
  font-size: 36px;
  font-size: 2.25rem;
}

@media (min-width: 576px) {
  h1, .alfa {
    font-size: 40px;
    font-size: 2.5rem;
  }
}

@media (min-width: 771px) {
  h1, .alfa {
    font-size: 50px;
    font-size: 3.125rem;
  }
}

@media (min-width: 992px) {
  h1, .alfa {
    font-size: 60px;
    font-size: 3.75rem;
  }
}

h2, .beta {
  font-size: 36px;
  font-size: 2.25rem;
}

@media (min-width: 576px) {
  h2, .beta {
    font-size: 38px;
    font-size: 2.375rem;
  }
}

@media (min-width: 771px) {
  h2, .beta {
    font-size: 40px;
    font-size: 2.5rem;
  }
}

@media (min-width: 771px) {
  h2, .beta {
    font-size: 44px;
    font-size: 2.75rem;
  }
}

h3, .gamma {
  letter-spacing: 1.99px;
  font-size: 32px;
  font-size: 2rem;
}

@media (min-width: 576px) {
  h3, .gamma {
    font-size: 36px;
    font-size: 2.25rem;
  }
}

@media (min-width: 771px) {
  h3, .gamma {
    font-size: 37px;
    font-size: 2.3125rem;
  }
}

h4, .delta {
  letter-spacing: 1.99px;
  font-size: 28px;
  font-size: 1.75rem;
}

@media (min-width: 576px) {
  h4, .delta {
    font-size: 32px;
    font-size: 2rem;
  }
}

@media (min-width: 771px) {
  h4, .delta {
    font-size: 34px;
    font-size: 2.125rem;
  }
}

h5, .epsilon {
  font-size: 24px;
  font-size: 1.5rem;
}

@media (min-width: 576px) {
  h5, .epsilon {
    font-size: 26px;
    font-size: 1.625rem;
  }
}

@media (min-width: 771px) {
  h5, .epsilon {
    font-size: 29px;
    font-size: 1.8125rem;
  }
}

@media (min-width: 771px) {
  .epsilon-ext {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

h6, .zeta {
  font-size: 15px;
  font-size: 0.9375rem;
  letter-spacing: 0.3px;
}

@media (min-width: 576px) {
  h6, .zeta {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}

@media (min-width: 771px) {
  h6, .zeta {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}

.headline-divider {
  margin-top: -10px;
}

.headline-divider svg {
  font-size: 50px;
  font-size: 3.125rem;
  fill: #00ab84;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.js-slider {
  display: none;
}

.js-slider.slick-initialized {
  display: block;
}

.js-slider .slick-slide {
  outline: none;
}

.js-slider .js-slide-item {
  outline: none;
}

.slick-margin-negative-10 {
  margin: 0 -10px;
}

.slick-margin-negative-10 .js-slide-item {
  padding-right: 10px;
  padding-left: 10px;
}

.slick-margin-negative-20 {
  margin: 0 -20px;
}

.slick-margin-negative-20 .js-slide-item {
  padding-right: 20px;
  padding-left: 20px;
}

.slick-margin-negative-30 {
  margin: 0 -30px;
}

.slick-margin-negative-30 .js-slide-item {
  padding-right: 30px;
  padding-left: 30px;
}

.slick-margin-negative-40 {
  margin: 0 -40px;
}

.slick-margin-negative-40 .js-slide-item {
  padding-right: 40px;
  padding-left: 40px;
}

.slick-margin-negative-50 {
  margin: 0 -50px;
}

.slick-margin-negative-50 .js-slide-item {
  padding-right: 50px;
  padding-left: 50px;
}

@media (min-width: 576px) {
  .slick-margin-negative-xs-5 {
    margin: 0 -5px;
  }
  .slick-margin-negative-xs-5 .js-slide-item {
    padding-right: 5px;
    padding-left: 5px;
  }
  .slick-margin-negative-xs-10 {
    margin: 0 -10px;
  }
  .slick-margin-negative-xs-10 .js-slide-item {
    padding-right: 10px;
    padding-left: 10px;
  }
  .slick-margin-negative-xs-20 {
    margin: 0 -20px;
  }
  .slick-margin-negative-xs-20 .js-slide-item {
    padding-right: 20px;
    padding-left: 20px;
  }
  .slick-margin-negative-xs-30 {
    margin: 0 -30px;
  }
  .slick-margin-negative-xs-30 .js-slide-item {
    padding-right: 30px;
    padding-left: 30px;
  }
  .slick-margin-negative-xs-40 {
    margin: 0 -40px;
  }
  .slick-margin-negative-xs-40 .js-slide-item {
    padding-right: 40px;
    padding-left: 40px;
  }
  .slick-margin-negative-xs-50 {
    margin: 0 -50px;
  }
  .slick-margin-negative-xs-50 .js-slide-item {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 771px) {
  .slick-margin-negative-sm-5 {
    margin: 0 -5px;
  }
  .slick-margin-negative-sm-5 .js-slide-item {
    padding-right: 5px;
    padding-left: 5px;
  }
  .slick-margin-negative-sm-10 {
    margin: 0 -10px;
  }
  .slick-margin-negative-sm-10 .js-slide-item {
    padding-right: 10px;
    padding-left: 10px;
  }
  .slick-margin-negative-sm-20 {
    margin: 0 -20px;
  }
  .slick-margin-negative-sm-20 .js-slide-item {
    padding-right: 20px;
    padding-left: 20px;
  }
  .slick-margin-negative-sm-30 {
    margin: 0 -30px;
  }
  .slick-margin-negative-sm-30 .js-slide-item {
    padding-right: 30px;
    padding-left: 30px;
  }
  .slick-margin-negative-sm-40 {
    margin: 0 -40px;
  }
  .slick-margin-negative-sm-40 .js-slide-item {
    padding-right: 40px;
    padding-left: 40px;
  }
  .slick-margin-negative-sm-50 {
    margin: 0 -50px;
  }
  .slick-margin-negative-sm-50 .js-slide-item {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media (min-width: 992px) {
  .slick-margin-negative-md-5 {
    margin: 0 -5px;
  }
  .slick-margin-negative-md-5 .js-slide-item {
    padding-right: 5px;
    padding-left: 5px;
  }
  .slick-margin-negative-md-10 {
    margin: 0 -10px;
  }
  .slick-margin-negative-md-10 .js-slide-item {
    padding-right: 10px;
    padding-left: 10px;
  }
  .slick-margin-negative-md-20 {
    margin: 0 -20px;
  }
  .slick-margin-negative-md-20 .js-slide-item {
    padding-right: 20px;
    padding-left: 20px;
  }
  .slick-margin-negative-md-30 {
    margin: 0 -30px;
  }
  .slick-margin-negative-md-30 .js-slide-item {
    padding-right: 30px;
    padding-left: 30px;
  }
  .slick-margin-negative-md-40 {
    margin: 0 -40px;
  }
  .slick-margin-negative-md-40 .js-slide-item {
    padding-right: 40px;
    padding-left: 40px;
  }
  .slick-margin-negative-md-50 {
    margin: 0 -50px;
  }
  .slick-margin-negative-md-50 .js-slide-item {
    padding-right: 50px;
    padding-left: 50px;
  }
}

.slick-margin-10 {
  margin: 0 10px;
}

.slick-margin-20 {
  margin: 0 20px;
}

.slick-margin-30 {
  margin: 0 30px;
}

.slick-margin-40 {
  margin: 0 40px;
}

.slick-margin-50 {
  margin: 0 50px;
}

@media (min-width: 771px) {
  .slick-margin-sm-50 {
    margin: 0 50px;
  }
}

@media (min-width: 992px) {
  .slick-dots-hero .slick-dots {
    text-align: left;
    left: calc((100% - 905px) / 2);
  }
}

@media (min-width: 1200px) {
  .slick-dots-hero .slick-dots {
    left: calc((100% - 1125px) / 2);
  }
}

@media (min-width: 1440px) {
  .slick-dots-hero .slick-dots {
    left: calc((100% - 1185px) / 2);
  }
}

@media (max-width: 991px) {
  .slick-dots-hero .slick-dots {
    position: absolute;
    bottom: 10px;
  }
}

.slick-dots-hero .slick-dots li {
  width: 30px;
  height: 40px;
}

.slick-dots-hero .slick-dots li:after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 9px;
  height: 9px;
  border: 2px solid #ffffff;
  content: '';
}

.slick-dots-hero .slick-dots li.slick-active:after, .slick-dots-hero .slick-dots li:hover:after {
  border-color: #ffffff;
  background-color: #ffffff;
}

.slick-dots {
  position: absolute;
  bottom: -30px;
  left: 0;
  width: 100%;
  margin: 0 !important;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 40px;
  padding: 0 !important;
  cursor: pointer;
  vertical-align: middle;
  transition: all 250ms ease;
}

.slick-dots li:after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 10px;
  height: 10px;
  content: '';
  transform: translate(-50%, -50%);
  border: 2px solid #2244b3;
}

.slick-dots li.slick-active:after, .slick-dots li:hover:after {
  border-color: #2244b3;
  background-color: #2244b3;
}

.slick-dots li button:before {
  content: none;
}

.slick-dots li button {
  display: none;
}

.slick-arrow-hero .slick-arrows {
  top: auto;
  margin-top: 0;
  bottom: -60px;
}

@media (min-width: 576px) {
  .slick-arrow-hero .slick-arrows {
    bottom: -120px;
  }
}

.slick-arrow-hero .slick-arrows.slick-prev {
  left: 50%;
}

@media (min-width: 576px) {
  .slick-arrow-hero .slick-arrows.slick-prev {
    left: -25px;
  }
}

@media (max-width: 575px) {
  .slick-arrow-hero .slick-arrows.slick-prev {
    margin-left: -60px;
  }
}

.slick-arrow-hero .slick-arrows.slick-next {
  right: auto;
  left: 50%;
}

@media (min-width: 576px) {
  .slick-arrow-hero .slick-arrows.slick-next {
    left: 35px;
  }
}

@media (max-width: 575px) {
  .slick-arrow-hero .slick-arrows.slick-next {
    margin-right: -60px;
  }
}

.slick-arrows {
  position: absolute;
  z-index: 4;
  top: 50%;
  display: block;
  margin-top: -20px;
  cursor: pointer;
}

.slick-arrows .slick-icon-prev,
.slick-arrows .slick-icon-next {
  display: flex;
  width: 60px;
  height: 50px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.slick-arrows svg {
  fill: #00ab84;
  font-size: 26px;
  font-size: 1.625rem;
}

.slick-arrows svg:hover {
  fill: #009270;
}

.slick-arrows.slick-disabled svg {
  fill: rgba(119, 164, 238, 0.6);
}

@media (min-width: 992px) {
  .slick-arrows.slick-disabled svg {
    fill: rgba(119, 164, 238, 0.18);
  }
}

.slick-arrows.slick-prev {
  left: 0;
}

.slick-arrows.slick-next {
  right: 0;
}

.select2-container {
  z-index: 9;
}

.select2-container.select2-container--open .select2-selection--single .select2-selection__arrow:after,
.select2-container.select2-container--open .select2-selection--multiple .select2-selection__arrow:after,
.select2-container .select2-container--focus .select2-selection--single .select2-selection__arrow:after,
.select2-container .select2-container--focus .select2-selection--multiple .select2-selection__arrow:after {
  transform: rotate(-180deg);
}

.select2-container .select2-search--inline .select2-search__field {
  margin-top: 0;
}

.select2-container .select2-search--inline {
  display: block;
  width: 100%;
}

.select2-container .select2-selection--single,
.select2-container .select2-selection--multiple {
  width: 100%;
  height: 70px;
  padding: 20px 0 1px 2rem;
  padding-bottom: 1px;
  font-family: "univia-pro", Arial, sans-serif;
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: 0px;
  color: #2244b3;
  border: 0;
  outline: none !important;
  background-color: transparent;
  font-size: 22px;
  font-size: 1.375rem;
  border-radius: 0;
}

@media (min-width: 771px) {
  .select2-container .select2-selection--single,
  .select2-container .select2-selection--multiple {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .select2-container .select2-selection--single,
  .select2-container .select2-selection--multiple {
    font-size: 22px;
    font-size: 1.375rem;
    padding-left: 1rem;
  }
}

.select2-container .select2-selection--single .select2-selection__rendered,
.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 0;
  line-height: 50px;
  color: #2244b3;
}

.select2-container .select2-selection--single .select2-selection__placeholder,
.select2-container .select2-selection--multiple .select2-selection__placeholder {
  color: #2244b3;
}

.select2-container .select2-selection--single .select2-selection__arrow,
.select2-container .select2-selection--multiple .select2-selection__arrow {
  position: absolute;
  top: -15px;
  right: 10px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.select2-container .select2-selection--single .select2-selection__arrow:after,
.select2-container .select2-selection--multiple .select2-selection__arrow:after {
  content: "" !important;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -14px;
  margin-top: -8px;
  width: 28px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../images/icons/filter-arrow.svg");
}

.select2-container .select2-selection--single .select2-selection__arrow b,
.select2-container .select2-selection--multiple .select2-selection__arrow b {
  display: none;
}

.select2-container .select2-container--focus .select2-selection--single .select2-selection__arrow:after,
.select2-container.select2-container--open .select2-selection--single .select2-selection__arrow:after {
  content: "\e91a";
}

.select2-dropdown {
  border: 0;
  border-radius: 0;
  background-color: white;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.03);
}

.select2-dropdown .select2-results__options--nested .select2-results__option {
  padding: 0 25px;
}

.select2-dropdown.select2-dropdown--above {
  box-shadow: 0 -15px 20px rgba(0, 0, 0, 0.03);
}

.select2-dropdown .select2-results__options {
  max-height: 220px !important;
}

.select2-dropdown .select2-results__options .select2-results__option[aria-selected=true] {
  color: #ffffff;
  background-color: #2244b3;
}

.select2-dropdown .select2-results__options .select2-results__option {
  padding: 1.2rem 2rem;
  font-family: "univia-pro", Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  color: #2244b3;
  font-size: 18px;
  font-size: 1.125rem;
}

@media (min-width: 993px) and (max-width: 1200px) {
  .select2-dropdown .select2-results__options .select2-results__option {
    padding: 1.2rem 1rem;
  }
}

.select2-dropdown .select2-results__options .select2-results__option--highlighted {
  color: #2244b3;
  background-color: #f5f5f5;
}

.lg-sub-html {
  line-height: 1.6;
}

.lg-sub-html strong {
  display: block;
}

/* Content */
section {
  position: relative;
  padding: 3rem 0 3rem;
  z-index: 3;
}

@media (min-width: 771px) {
  section {
    padding: 3rem 0 3rem;
  }
}

.no-padding {
  padding: 0;
}

@media (min-width: 576px) {
  .no-xs-padding {
    padding: 0;
  }
}

@media (min-width: 771px) {
  .no-sm-padding {
    padding: 0;
  }
}

@media (min-width: 992px) {
  .no-md-padding {
    padding: 0;
  }
}

@media (min-width: 1200px) {
  .no-lg-padding {
    padding: 0;
  }
}

.padding-style-1 {
  padding: 2rem 0.5rem 1rem;
}

@media (min-width: 771px) {
  .padding-style-1 {
    padding: 2rem 0 0;
  }
}

.padding-style-1-ext {
  padding: 0 0 1rem;
}

@media (min-width: 771px) {
  .padding-style-1-ext {
    padding: 1rem 0 1rem;
  }
}

.padding-style-2 {
  padding: 2rem 0.5rem 2rem;
}

@media (min-width: 771px) {
  .padding-style-2 {
    padding: 2rem 0 2rem;
  }
}

.padding-style-3 {
  padding: 2rem 1rem 2rem;
}

@media (min-width: 771px) {
  .padding-style-3 {
    padding: 2rem 0 3rem;
  }
}

.padding-style-4 {
  padding: 3rem 0.5rem 3rem;
}

@media (min-width: 992px) {
  .padding-style-4 {
    padding: 4rem 0 4rem;
  }
}

.padding-style-4-ext {
  padding: 3rem 0.5rem 3rem;
}

@media (min-width: 992px) {
  .padding-style-4-ext {
    padding: 6rem 0 4rem;
  }
}

.padding-style-5 {
  padding: 3rem 0.5rem 3rem;
}

@media (min-width: 771px) {
  .padding-style-5 {
    padding: 4.5rem 0 5rem;
  }
}

.padding-style-5-ext {
  padding: 3rem 0.5rem 0;
}

@media (min-width: 771px) {
  .padding-style-5-ext {
    padding: 5rem 0 2.5rem;
  }
}

.padding-style-6 {
  padding: 3rem 0.5rem 3rem;
}

@media (min-width: 992px) {
  .padding-style-6 {
    padding: 0 0 5rem;
  }
}

.padding-style-6-ext {
  padding: 3rem 0.5rem 2rem;
}

@media (min-width: 771px) {
  .padding-style-6-ext {
    padding: 6rem 0 4rem;
  }
}

.padding-style-6-ext-1 {
  padding: 3rem 0.5rem 2rem;
}

@media (min-width: 771px) {
  .padding-style-6-ext-1 {
    padding: 6rem 0 6rem;
  }
}

.padding-style-7 {
  padding: 3rem 0.5rem 3rem;
}

@media (min-width: 771px) {
  .padding-style-7 {
    padding: 6rem 0 8rem;
  }
}

.padding-style-7-ext {
  padding: 3rem 0.5rem 2rem;
}

@media (min-width: 771px) {
  .padding-style-7-ext {
    padding: 4rem 0 4rem;
  }
}

.padding-content-1 {
  padding: 1rem 1rem;
}

.padding-content-2 {
  padding: 2rem 2rem;
}

.padding-content-3 {
  padding: 3rem 3rem;
}

.padding-content-4 {
  padding: 4rem 4rem;
}

.padding-content-5 {
  padding: 5rem 5rem;
}

.content-width-200 {
  max-width: 200px;
}

.content-width-300 {
  max-width: 300px;
}

.content-width-600 {
  max-width: 600px;
}

.content-width-670 {
  max-width: 670px;
}

.content-width-750 {
  max-width: 750px;
}

@media (max-width: 991px) {
  .content-width-md-300 {
    max-width: 300px;
  }
}

.page-index main {
  padding-top: 60px;
}

@media (min-width: 992px) {
  .page-index main {
    padding-top: 0;
  }
}

main {
  position: relative;
  display: block;
  padding-top: 60px;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

@media (min-width: 992px) {
  main {
    padding-top: 120px;
  }
}

.container-full {
  padding: 0 15px;
}

.container-small,
.container-normal,
.container-large {
  margin: 0 auto;
  padding: 0 15px;
}

@media (min-width: 771px) {
  .container-small {
    width: 730px;
  }
}

@media (min-width: 992px) {
  .container-small {
    width: 800px;
  }
}

@media (min-width: 1200px) {
  .container-small {
    width: 800px;
  }
}

@media (min-width: 1440px) {
  .container-small {
    width: 800px;
  }
}

@media (max-width: 770px) {
  .container-small {
    width: 100%;
  }
}

@media (min-width: 771px) {
  .container-normal {
    width: 730px;
  }
}

@media (min-width: 992px) {
  .container-normal {
    width: 920px;
  }
}

@media (min-width: 1200px) {
  .container-normal {
    width: 1140px;
  }
}

@media (min-width: 1440px) {
  .container-normal {
    width: 1200px;
  }
}

@media (max-width: 770px) {
  .container-normal {
    width: 100%;
  }
}

@media (min-width: 771px) {
  .container-large {
    width: 730px;
  }
}

@media (min-width: 992px) {
  .container-large {
    width: 950px;
  }
}

@media (min-width: 1200px) {
  .container-large {
    width: 1140px;
  }
}

@media (min-width: 1440px) {
  .container-large {
    width: 1360px;
  }
}

@media (min-width: 1600px) {
  .container-large {
    width: 1480px;
  }
}

@media (max-width: 770px) {
  .container-large {
    width: 100%;
  }
}

@media (min-width: 1900px) {
  .container-large {
    width: 1720px;
  }
}

@media (min-width: 2000px) {
  .container-large {
    width: 1800px;
  }
}

/* Footer */
.footer {
  z-index: 2;
  position: relative;
  overflow: hidden;
  background-color: #0b204f;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-bottom: 2px solid #00ab84;
}

@media (min-width: 771px) {
  footer.footer-offset .footer-top {
    padding-top: 7rem;
  }
}

.footer-item {
  margin-bottom: 1rem;
}

@media (min-width: 771px) {
  .footer-item {
    margin-bottom: 2rem;
  }
}

@media (min-width: 992px) {
  .footer-item {
    margin-bottom: 3rem;
  }
}

@media (max-width: 369px) {
  .footer-item {
    width: 100%;
  }
}

.footer-top {
  padding: 4.5rem 0.5rem 0;
}

@media (max-width: 991px) {
  .footer-top {
    padding: 3rem 0.5rem 0;
  }
}

.footer-bottom {
  padding: 1rem 0.5rem 1rem;
}

.footer-copy {
  color: #305795;
  font-family: "univia-pro", Arial, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.4;
}

@media (max-width: 770px) {
  .footer-copy {
    text-align: center;
  }
}

.footer-copy a:hover {
  color: #ffffff;
  text-decoration: underline;
}

.footer-link {
  color: white;
}

.footer-link:hover {
  text-decoration: underline;
}

.footer-logo {
  display: flex;
  align-items: center;
  position: relative;
  padding: 1rem 0;
}

@media (max-width: 991px) {
  .footer-logo {
    justify-content: center;
  }
}

@media (max-width: 575px) {
  .footer-logo img {
    margin: 0 auto;
  }
}

.footer-address-item {
  position: relative;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
}

.footer-address-item:last-child:after {
  display: none;
}

.footer-address-item:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: 2px;
  height: 50px;
  transform: translateY(-50%);
  background-color: #0f2d69;
}

@media (max-width: 991px) {
  .footer-address-item {
    margin: 0;
  }
}

@media (max-width: 770px) {
  .footer-address-item {
    display: flex;
    margin-bottom: 20px;
  }
}

.footer-address-item:hover .footer-address-text {
  text-decoration: underline;
}

.footer-address-icon {
  display: inline-block;
  font-family: "univia-pro", Arial, sans-serif;
  float: left;
  width: 25px;
  margin-right: 25px;
}

.footer-address-icon svg {
  fill: #00ab84;
  font-size: 32px;
  font-size: 2rem;
}

@media (max-width: 770px) {
  .footer-address-icon svg {
    font-size: 28px;
    font-size: 1.75rem;
  }
}

@media (max-width: 575px) {
  .footer-address-icon {
    width: 25px;
  }
}

.footer-address-text {
  display: block;
  height: 100%;
  position: relative;
  top: 3px;
  overflow: hidden;
  font-family: "univia-pro", Arial, sans-serif;
  font-size: 18px;
  font-size: 1.125rem;
  color: #ffffff;
  font-weight: 300;
  line-height: 1.4;
  text-transform: none;
}

@media (max-width: 575px) {
  .footer-address-text {
    font-size: 16px;
    font-size: 1rem;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .footer-address-text {
    font-size: 16px;
    font-size: 1rem;
  }
}

.footer-address-text strong {
  display: block;
  margin-bottom: 1em;
  text-transform: uppercase;
}

.footer-address-text-ext {
  line-height: 1.6;
}

/* Header */
.navigation .navigation-trigger {
  display: none;
}

@media (max-width: 991px) {
  .navigation .container-normal, .navigation .container-large {
    width: 100%;
  }
  .navigation .nav-collapse {
    position: fixed;
    z-index: 98;
    top: 59px;
    left: 0;
    overflow: auto;
    width: 100%;
    height: 100%;
    padding: 25px 0 70px;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    background-color: #12163e;
    transition: none 0.4s ease-out;
  }
  .navigation .navigation-trigger {
    position: fixed;
    z-index: 200;
    top: 3px;
    right: 0;
    display: block;
    width: 70px;
    height: 57px;
  }
  .navigation .navigation-trigger-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    width: 30px;
    height: 3px;
    -webkit-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-transition-property: background-color, transform;
    transition-property: background-color, transform;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    font-weight: 300;
  }
  .navigation .navigation-trigger-icon:before, .navigation .navigation-trigger-icon:after {
    position: absolute;
    display: block;
    width: 30px;
    height: 3px;
    content: '';
    -webkit-transition-duration: 250ms;
    transition-duration: 250ms;
    -webkit-transition-property: margin, transform;
    transition-property: margin, transform;
    background: #ffffff;
  }
  .navigation .navigation-trigger-icon:before {
    margin-top: -10px;
  }
  .navigation .navigation-trigger-icon:after {
    margin-top: 10px;
  }
  .navigation.is-collapse .nav-collapse {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    transition: transform 250ms ease;
  }
  .navigation.is-collapse .navigation-trigger-icon {
    transition: none;
    background: rgba(0, 0, 0, 0);
  }
  .navigation.is-collapse .navigation-trigger-icon:before {
    margin-top: 0;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .navigation.is-collapse .navigation-trigger-icon:after {
    margin-top: 0;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

@media (min-width: 992px) {
  .page-index .header {
    position: relative;
  }
  .page-index .header:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 400px;
    background: -moz-linear-gradient(top, rgba(27, 31, 70, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(27, 31, 70, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(27, 31, 70, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc1b1f46', endColorstr='#00ffffff',GradientType=0 );
    /* IE6-9 */
    z-index: 2;
    pointer-events: none;
  }
  .page-index .navigation:not(.navigation-scroll) {
    background-color: transparent;
  }
  .page-index .navigation:not(.navigation-scroll) .header-logo {
    height: 140px;
  }
  .page-index .navigation:not(.navigation-scroll) .footer-address-icon svg {
    fill: #00ab84;
  }
  .page-index .navigation:not(.navigation-scroll) .footer-address-text {
    color: #ffffff;
  }
  .page-index .navigation-scroll .menu-list-link {
    padding: 0.4rem 0.7rem;
  }
}

.navigation {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #001d5d;
  padding: 0 20px;
  border-top: 2px solid #2244b3;
  transition: box-shadow 350ms ease-out, background-color 350ms ease-out;
}

@media (min-width: 992px) {
  .navigation {
    padding: 0 15px;
  }
}

@media (min-width: 1440px) {
  .navigation {
    padding: 0 30px;
  }
}

.navigation-scroll {
  background-color: #001d5d;
}

.navigation-scroll .header-logo {
  height: 80px;
}

.navigation-scroll .header-logo-link {
  height: 80px;
}

@media (min-width: 992px) {
  .navigation-scroll .header-logo-link {
    padding-bottom: 0;
  }
}

@media (min-width: 992px) {
  .header-flex {
    display: flex;
    justify-content: space-between;
  }
}

.header-logo {
  display: flex;
  align-items: center;
  float: left;
  text-align: center;
  height: 58px;
  transition: height 400ms ease-out;
}

@media (min-width: 992px) {
  .header-logo {
    height: 120px;
    width: 180px;
    max-width: 180px;
    flex: 0 0 180px;
    padding-right: 25px;
  }
}

@media (min-width: 1440px) {
  .header-logo {
    padding-right: 0;
    width: 220px;
    max-width: 220px;
    flex: 0 0 220px;
  }
}

@media (max-width: 991px) {
  .header-logo {
    position: relative;
    z-index: 99;
    padding: 5px 0 5px;
  }
}

.header-logo-link {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  transition: height 400ms ease-out;
}

.header-image {
  transition: width 400ms ease-out;
}

@media (max-width: 991px) {
  .header-image {
    width: 150px;
  }
}

@media (min-width: 992px) {
  .nav-collapse {
    display: flex;
    justify-content: flex-end;
    width: calc(100% - 180px);
    max-width: calc(100% - 180px);
    flex: 0 0 calc(100% - 180px);
    align-items: center;
  }
}

@media (min-width: 1440px) {
  .nav-collapse {
    width: calc(100% - 220px);
    max-width: calc(100% - 220px);
    flex: 0 0 calc(100% - 220px);
  }
}

@media (min-width: 1600px) {
  .nav-collapse {
    padding-left: 3rem;
  }
}

.menu-list {
  text-align: center;
}

@media (min-width: 992px) {
  .menu-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .menu-list {
    width: 100%;
  }
}

.menu-list-item {
  position: relative;
}

@media (min-width: 992px) {
  .menu-list-item {
    display: flex;
  }
}

@media (max-width: 991px) {
  .menu-list-item {
    display: block;
    text-align: center;
  }
}

.menu-list-item:hover .menu-list-link, .menu-list-item.is-active .menu-list-link {
  color: #ffffff;
}

@media (min-width: 992px) {
  .menu-list-item.is-active:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    width: 25px;
    height: 2px;
    background-color: #00ab84;
  }
}

.menu-list-link {
  position: relative;
  display: inline-block;
  padding: 0.9rem 1rem;
  text-decoration: none;
  color: #6979dc;
  font-family: "univia-pro", Arial, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 3.5px;
  line-height: 1.4;
  font-size: 14px;
  font-size: 0.875rem;
}

@media (min-width: 992px) {
  .menu-list-link {
    display: flex;
    align-items: center;
    padding: 0.8rem 0.7rem;
    font-size: 12px;
    font-size: 0.75rem;
  }
}

@media (min-width: 1440px) {
  .menu-list-link {
    padding: 0.8rem 1rem;
  }
}

@media (min-width: 1600px) {
  .menu-list-link {
    padding: 0.8rem 1.4rem;
    letter-spacing: 3.5px;
  }
}

@media (max-width: 991px) {
  .header-contact {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    width: 280px;
    margin: 1rem auto 0;
  }
}

@media (min-width: 992px) {
  .header-contact {
    justify-content: flex-end;
    display: flex;
    flex-wrap: wrap;
    width: 240px;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .header-contact {
    flex-wrap: wrap;
  }
}

.header-contact .footer-address-item {
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 0;
}

.header-contact .footer-address-item:after {
  display: none;
}

@media (min-width: 992px) {
  .header-contact .footer-address-item {
    padding: 6px 10px;
    padding-right: 0;
    justify-content: flex-end;
  }
}

@media (min-width: 1440px) {
  .header-contact .footer-address-item {
    padding: 6px 20px;
    padding-right: 0;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .header-contact .footer-address-item {
    padding: 5px 20px;
    padding-right: 0;
  }
}

.header-contact .footer-address-text {
  letter-spacing: 3.5px;
  font-size: 12px;
  font-size: 0.75rem;
}

.header-contact .footer-address-icon {
  position: relative;
  top: 1px;
  margin-right: 5px;
}

.header-contact .footer-address-icon svg {
  color: #00ab84;
  font-size: 18px;
  font-size: 1.125rem;
}

/* Social block */
.social-list-header {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.social-list-item {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 10px;
  vertical-align: middle;
}

@media (min-width: 992px) {
  .social-list-item {
    margin-right: 4px;
  }
}

@media (min-width: 1200px) {
  .social-list-item {
    margin-right: 20px;
  }
}

.social-list-item:last-child {
  margin-right: 0;
}

.social-list-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  color: #2244b3;
  border-radius: 100%;
}

.social-list-link svg {
  fill: #2244b3;
  font-size: 40px;
  font-size: 2.5rem;
}

.social-list-link:hover svg {
  fill: #1a3488;
}

/* article block */
.article-item {
  margin-bottom: 2rem;
}

.article-item:last-child {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .article-item-ext .article-image-block {
    order: 1;
  }
  .article-item-ext .article-text-block {
    order: 0;
  }
}

.article-content {
  display: block;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 4px 19px rgba(0, 0, 0, 0.07);
}

@media (min-width: 576px) {
  .article-content {
    display: flex;
  }
}

@media (min-width: 992px) {
  .article-content {
    display: flex;
    height: 200px;
  }
}

.article-content .zeta {
  letter-spacing: 0.3px;
}

.article-content:hover {
  box-shadow: 0 4px 19px rgba(0, 0, 0, 0.07);
}

.article-content:hover img {
  transform: scale(1.05, 1.05);
}

.article-content:hover .article-title {
  color: #2244b3;
}

@media (min-width: 992px) {
  .article-width {
    max-width: 220px;
    margin: 0 auto;
  }
}

.article-image-block {
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (max-width: 991px) {
  .article-image-block {
    height: 220px;
  }
}

@media (max-width: 575px) {
  .article-image-block {
    height: 180px;
  }
}

@media (min-width: 576px) {
  .article-image-block {
    width: 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.article-image-block img {
  transition: transform 400ms ease-out;
}

.article-text-block {
  display: flex;
  align-items: center;
  padding: 1.5rem 1.5rem;
}

@media (min-width: 576px) {
  .article-text-block {
    width: 50%;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0.3rem 1rem;
  }
}

/* Map block */
.map-block {
  width: 100%;
  height: 100%;
  background-color: #f7f9ff;
  position: relative;
}

.map {
  width: 100%;
  height: 400px;
}

@media (min-width: 771px) {
  .map {
    height: 600px;
  }
}

.map-block .window-block {
  position: relative;
  pointer-events: none;
}

.map-block .gm-style-iw-t:after {
  display: none;
}

.map-block .gm-style-iw {
  width: 180px !important;
  top: 0 !important;
  left: 0 !important;
  margin: 0 auto;
  overflow: visible;
  z-index: 9;
  background-color: #0a2659;
  pointer-events: all;
  border-radius: 5px;
  padding: 0;
}

.map-block .gm-style-iw:after {
  content: '';
  position: absolute;
  left: -10px;
  top: 50%;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 10px 8px 0;
  border-color: transparent #0a2659 transparent transparent;
  transform: translateY(-50%);
}

.map-block .gm-style-iw .gm-ui-hover-effect {
  width: 30px !important;
  height: 30px !important;
  right: 10px !important;
  top: -15px !important;
  border-radius: 100%;
  background-color: #2244b3 !important;
  opacity: 1;
  outline: none;
  display: none !important;
}

.map-block .gm-style-iw .gm-ui-hover-effect img {
  display: none !important;
}

.map-block .gm-style-iw .gm-ui-hover-effect:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  margin-left: -7px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../images/icons/close-purple.svg");
}

.map-block .gm-style-iw > div {
  overflow: auto !important;
  max-width: 100% !important;
}

.map-block .gm-style-iw + div {
  pointer-events: all;
  background: #2244b3;
  cursor: pointer;
  z-index: 9999999 !important;
}

.map-block .gm-style-iw + div:after {
  content: "";
  font-family: "project-name";
  background-color: red;
  width: 10px;
  height: 10px;
  display: block;
}

.map-block .gm-style-iw + div img {
  display: none !important;
}

.map-content {
  padding: 0.8rem 1.2rem;
}

.map-content p {
  display: block;
  margin-bottom: 0;
  color: #77a4ee;
  font-family: "univia-pro", Arial, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: 0.24px;
}

.map-title {
  display: block;
  margin-bottom: 0.2rem;
  color: #2bb22b;
  font-family: "univia-pro", Arial, sans-serif;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.45px;
}

/* Breadcrumbs block */
.breadcrumb-list-item {
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 770px) {
  .breadcrumb-list-item:last-child .breadcrumb-list-link {
    margin-right: 0;
  }
}

.breadcrumb-list-item:last-child .breadcrumb-list-link:after {
  content: none;
}

.breadcrumb-list-link {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.2;
  color: #a1a1a1;
  font-size: 12px;
  font-size: 0.75rem;
  margin: 0.5rem 1.5rem 0.5rem 0;
  position: relative;
}

@media (min-width: 771px) {
  .breadcrumb-list-link {
    margin: 0.8rem 0 0.8rem 1.5rem;
  }
}

.breadcrumb-list-link:hover {
  color: #2244b3;
}

.breadcrumb-list-link:after {
  content: '';
  position: absolute;
  right: -20px;
  top: 2px;
  display: inline-block;
  width: 12px;
  height: 12px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  background-size: cover;
  background-image: url(../images/icons/chevron-right.svg);
}

/* Table block */
@media (max-width: 770px) {
  .table-responsives {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    min-height: .01%;
    border: 0 !important;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    background: -webkit-gradient(linear, left top, right top, color-stop(30%, white), to(rgba(255, 255, 255, 0))), -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), color-stop(70%, white)) 0 100%, radial-gradient(farthest-side at 0% 50%, #f8faff, rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 100% 50%, #f8faff, rgba(0, 0, 0, 0)) 0 100%;
    background: -webkit-linear-gradient(left, white 30%, rgba(255, 255, 255, 0)), -webkit-linear-gradient(left, rgba(255, 255, 255, 0), white 70%) 0 100%, -webkit-radial-gradient(0% 50%, farthest-side, #f8faff, rgba(0, 0, 0, 0)), -webkit-radial-gradient(100% 50%, farthest-side, #f8faff, rgba(0, 0, 0, 0)) 0 100%;
    background: -o-linear-gradient(left, white 30%, rgba(255, 255, 255, 0)), -o-linear-gradient(left, rgba(255, 255, 255, 0), white 70%) 0 100%, -o-radial-gradient(0% 50%, farthest-side, #f8faff, rgba(0, 0, 0, 0)), -o-radial-gradient(100% 50%, farthest-side, #f8faff, rgba(0, 0, 0, 0)) 0 100%;
    background: linear-gradient(to right, white 30%, rgba(255, 255, 255, 0)), linear-gradient(to right, rgba(255, 255, 255, 0), white 70%) 0 100%, radial-gradient(farthest-side at 0% 50%, #f8faff, rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 100% 50%, #f8faff, rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    -webkit-background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
    background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
    background-position: 0 0, 100%, 0 0, 100%;
    background-attachment: local, local, scroll, scroll;
  }
}

@media (max-width: 991px) {
  .table-md-responsives {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    min-height: .01%;
    border: 0 !important;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    background: linear-gradient(to right, white 30%, rgba(255, 255, 255, 0)), linear-gradient(to right, rgba(255, 255, 255, 0), white 70%) 0 100%, radial-gradient(farthest-side at 0% 50%, #faf7f3, rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 100% 50%, #faf7f3, rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
    background-position: 0 0, 100%, 0 0, 100%;
    background-attachment: local, local, scroll, scroll;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
}

table thead tr {
  border-bottom: 2px solid #00ab84;
}

table th {
  padding: 1rem 1rem 1rem 1rem;
  font-family: "univia-pro", Arial, sans-serif;
  font-weight: 500;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  color: #4f4f4f;
  letter-spacing: 0.15px;
  font-size: 14px;
  font-size: 0.875rem;
}

@media (min-width: 771px) {
  table th {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}

table tbody tr:nth-child(even) {
  background-color: #f8faff;
}

table td {
  padding: 1rem 1rem 1rem 1rem;
  font-family: "univia-pro", Arial, sans-serif;
  line-height: 1.4;
  text-align: center;
  font-weight: 300;
  color: #4f4f4f;
  letter-spacing: 1.27px;
  font-size: 15px;
  font-size: 0.9375rem;
}

.pagination-list {
  width: 100%;
  position: relative;
}

@media (max-width: 575px) {
  .pagination-list {
    text-align: center;
  }
}

.pagination-list-item {
  display: inline-block;
  vertical-align: middle;
  margin-left: -4px;
  margin-right: 10px;
  padding-left: 0;
  margin-bottom: 0;
}

@media (max-width: 575px) {
  .pagination-list-item {
    margin-right: 8px;
  }
}

.pagination-list-item:hover .pagination-list-link {
  border-color: #2244b3;
  background-color: #2244b3;
  color: #ffffff;
}

.pagination-list-item.more .pagination-list-link:hover {
  border-color: #e8e8e8;
  background-color: #f7f9ff;
  color: #8a8a8a;
}

.pagination-list-item:before {
  content: none;
}

.pagination-list-item:first-child .pagination-list-link {
  width: auto;
  min-width: 120px;
  padding: 0 25px;
  padding-top: 2px;
}

@media (max-width: 770px) {
  .pagination-list-item:first-child .pagination-list-link {
    padding: 0 15px;
  }
}

@media (max-width: 575px) {
  .pagination-list-item:first-child {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.pagination-list-item:last-child {
  margin-right: 0;
}

.pagination-list-item:last-child .pagination-list-link {
  width: auto;
  min-width: 120px;
  padding: 0 25px;
  padding-top: 2px;
}

@media (max-width: 770px) {
  .pagination-list-item:last-child .pagination-list-link {
    padding: 0 15px;
  }
}

@media (max-width: 575px) {
  .pagination-list-item:last-child {
    width: 100%;
    margin-right: 0;
    margin-top: 10px;
  }
}

.pagination-list-item.is-active .pagination-list-link {
  background-color: #2244b3;
  color: #ffffff;
  border-color: #2244b3;
}

.pagination-list-link {
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f7f9ff;
  border: 1px solid #e8e8e8;
  text-decoration: none !important;
  color: #212232;
  padding-top: 2px;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  text-transform: uppercase;
}

/* Tabs block */
.tabs-list {
  position: relative;
  display: flex;
  text-align: center;
  border: 0;
  align-items: center;
  justify-content: center;
}

.tabs-list:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #eaedf0;
  z-index: -1;
}

.tab-list-item {
  position: relative;
  display: flex;
  height: 60px;
  cursor: pointer;
  text-align: center;
  border: 0;
  background-color: #ffffff;
  box-shadow: 0 4px 19px rgba(0, 0, 0, 0.07);
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 2rem;
}

.tab-list-item:first-child {
  border-radius: 4px 0 0 4px;
}

.tab-list-item:last-child {
  border-radius: 0 4px 4px 0;
}

@media (max-width: 575px) {
  .tab-list-item {
    padding: 0.5rem 1rem;
  }
}

.tab-list-item.mixitup-control-active, .tab-list-item.is-active {
  background-color: #00ab84;
}

.tab-list-item.mixitup-control-active .tab-list-text, .tab-list-item.is-active .tab-list-text {
  color: #ffffff;
}

.tab-list-item:hover {
  background-color: #00ab84;
}

.tab-list-item:hover .tab-list-text {
  color: #ffffff;
}

.tab-list-text {
  font-family: "univia-pro", Arial, sans-serif;
  font-weight: 500;
  line-height: 1.4;
  color: #00ab84;
  font-size: 15px;
  font-size: 0.9375rem;
}

.tabs-content {
  clear: both;
}

.tab-content-item {
  visibility: hidden;
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: opacity 400ms ease-out;
}

.tab-content-item.is-active {
  visibility: visible;
  overflow: visible;
  height: auto;
  opacity: 1;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eaedf0;
}

@media (min-width: 771px) {
  .tab-content-item.is-active {
    padding-top: 2rem;
    padding-bottom: 4rem;
  }
}

@media (min-width: 771px) {
  .tab-sm-content-item {
    visibility: hidden;
    overflow: hidden;
    height: 0;
    opacity: 0;
    transition: opacity 400ms ease-out;
  }
  .tab-sm-content-item.is-active {
    visibility: visible;
    overflow: visible;
    height: auto;
    opacity: 1;
  }
}

@media (min-width: 992px) {
  .tab-md-content-item {
    visibility: hidden;
    overflow: hidden;
    height: 0;
    opacity: 0;
    transition: opacity 400ms ease-out;
  }
  .tab-md-content-item.is-active {
    visibility: visible;
    overflow: visible;
    height: auto;
    opacity: 1;
  }
}

@media (min-width: 1200px) {
  .tab-lg-content-item {
    visibility: hidden;
    overflow: hidden;
    height: 0;
    opacity: 0;
    transition: opacity 400ms ease-out;
  }
  .tab-lg-content-item.is-active {
    visibility: visible;
    overflow: visible;
    height: auto;
    opacity: 1;
  }
}

.section-hero {
  padding: 3rem 0.5rem 5rem;
}

@media (min-width: 992px) {
  .section-hero {
    padding-top: 220px;
    padding-bottom: 160px;
  }
}

@media (min-width: 1440px) {
  .section-hero {
    padding-left: 160px;
  }
}

@media (min-width: 992px) and (max-height: 880px) {
  .section-hero {
    padding-top: 150px;
    padding-bottom: 140px;
  }
  .section-hero .margin-bottom-3 {
    margin-bottom: 1.5rem;
  }
  .section-hero .margin-bottom-5 {
    margin-bottom: 3rem;
  }
  .section-hero .slick-arrow-hero .slick-arrows {
    bottom: -100px;
  }
}

.js-slider-hero-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 1199px;
  width: 100%;
}

@media (max-width: 991px) {
  .js-slider-hero-image {
    height: 779px;
  }
}

@media (min-width: 2000px) {
  .js-slider-hero-image {
    height: 1409px;
  }
}

.js-slider-hero-image div:not(.bg-image-hero) {
  height: 100%;
}

.file-item {
  margin-bottom: 1rem;
  align-content: center;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  background-color: #ffffff;
  border-bottom: 2px solid #00ab84;
  padding: 1.5rem 1.5rem;
}

@media (min-width: 576px) {
  .file-item {
    padding: 1.5rem 2rem;
  }
}

.file-content {
  align-items: center;
  justify-content: space-between;
}

.file-name {
  display: block;
  margin-bottom: 0.3rem;
  color: #4f4f4f;
  font-family: "univia-pro", Arial, sans-serif;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 0.15px;
  font-size: 15px;
  font-size: 0.9375rem;
}

.file-description {
  color: #4f4f4f;
  font-family: "univia-pro", Arial, sans-serif;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: 0.15px;
}

.file-info {
  color: #383838;
  font-family: "univia-pro", Arial, sans-serif;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.84px;
}

@media (min-width: 771px) {
  .file-info {
    min-width: 130px;
    font-size: 14px;
    font-size: 0.875rem;
  }
}

@media (max-width: 770px) {
  .file-info {
    margin-top: 10px;
  }
}

.file-btn {
  color: #ffffff !important;
  text-decoration: none !important;
}

@media (min-width: 772px) and (max-width: 992px) {
  .file-btn {
    min-width: auto;
  }
}

@media (min-width: 771px) {
  .file-btn {
    margin-left: 1rem;
  }
}

@media (max-width: 770px) {
  .file-btn {
    width: 100%;
  }
}

.pdf-item {
  margin-bottom: 2rem;
}

@media (min-width: 771px) {
  .pdf-item {
    height: calc( (360px / 270) * 335);
  }
}

@media (min-width: 992px) {
  .pdf-item {
    height: calc( (360px / 270) * 255);
  }
}

@media (min-width: 1440px) {
  .pdf-item {
    height: calc( (360px / 270) * 270);
  }
}

.pdf-content {
  display: block;
  position: relative;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  border-bottom: 2px solid #00ab84;
  background-color: #ffffff;
  text-decoration: none !important;
}

@media (min-width: 771px) {
  .pdf-content {
    height: 100%;
  }
}

@media (max-width: 770px) {
  .pdf-content {
    height: calc( (347px / 260) * 260);
    width: 260px;
    margin: 0 auto;
  }
}

@media (max-width: 575px) {
  .pdf-content {
    height: calc( (387px / 290) * 290);
    width: 290px;
    margin: 0 auto;
  }
}

.pdf-content:hover .pdf-name {
  color: #00ab84;
}

.pdf-content .btn-arrow {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.pdf-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
}

.pdf-size-block {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 1rem 0.5rem 1rem;
  color: #ffffff;
  font-family: "univia-pro", Arial, sans-serif;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.4;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 2;
  position: relative;
  height: calc(100% - 90px);
}

@media (min-width: 771px) {
  .pdf-size-block {
    height: calc(100% - 90px);
  }
}

.pdf-size-block strong {
  display: block;
  color: #ffffff;
  font-weight: 500;
}

.pdf-size-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  height: 80%;
}

.pdf-text-block {
  position: relative;
  padding: 1.5rem 1rem;
  border-top: 1px solid #eaedf0;
  text-align: center;
  background-color: #ffffff;
  z-index: 3;
  height: 90px;
}

@media (min-width: 771px) {
  .pdf-text-block {
    height: 90px;
  }
}

.pdf-name {
  display: block;
  margin-bottom: 0.5rem;
  color: #383838;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.45px;
}

.pdf-info {
  display: block;
  color: #858585;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.36px;
}

.offer-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  margin-bottom: 1.2rem;
}

.offer-icon svg {
  font-size: 60px;
  font-size: 3.75rem;
}

.offer-icon-small svg {
  font-size: 60px;
  font-size: 3.75rem;
}

.offer-icon-big svg {
  font-size: 110px;
  font-size: 6.875rem;
}

.offer-group {
  justify-content: center;
}

.offer-item {
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
}

@media (min-width: 771px) {
  .offer-item {
    margin-bottom: 2rem;
  }
}

.offer-content {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  box-shadow: 0 4px 19px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  background-color: #ffffff;
  border-bottom: 2px solid #ffffff;
  transition: transform 250ms ease-out;
  height: 220px;
}

@media (min-width: 992px) {
  .offer-content {
    padding: 1.5rem 1rem;
    height: 220px;
  }
}

.offer-content:hover {
  border-bottom-color: #00ab84;
  transform: scale(1.1, 1.1);
}

.offer-content .btn-arrow {
  position: absolute;
  bottom: 0.7rem;
  right: 0.7rem;
}

@media (min-width: 992px) {
  .offer-content .btn-arrow {
    bottom: 0.7rem;
    right: 0.7rem;
  }
}

.offer-content .zeta {
  letter-spacing: 0.15px;
}

.contact-item {
  position: relative;
  display: inline-flex;
  height: 70px;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  margin: 1rem 0;
}

@media (min-width: 993px) and (max-width: 1200px) {
  .contact-item {
    margin: 0;
  }
}

@media (min-width: 1200px) {
  .contact-item {
    height: 130px;
  }
}

@media (max-width: 575px) {
  .contact-item {
    margin: 0;
  }
}

.contact-item:last-child:after {
  display: none;
}

.contact-item:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: 2px;
  height: 50px;
  transform: translateY(-50%);
  background-color: #e8eeff;
}

@media (min-width: 993px) and (max-width: 1200px) {
  .contact-item:after {
    top: auto;
    bottom: 0;
    left: 50%;
    width: 220px;
    height: 2px;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 575px) {
  .contact-item:after {
    top: auto;
    bottom: 0;
    left: 50%;
    width: 220px;
    height: 2px;
    transform: translate(-50%, 0);
  }
}

@media (min-width: 1200px) {
  .contact-item:after {
    height: 130px;
  }
}

.contact-item:hover .contact-text {
  text-decoration: underline;
}

.contact-icon {
  display: inline-block;
  font-family: "univia-pro", Arial, sans-serif;
  float: left;
  width: 40px;
  margin-right: 15px;
  position: relative;
  top: 0;
}

@media (min-width: 1440px) {
  .contact-icon {
    width: 50px;
    margin-right: 25px;
  }
}

.contact-icon .icon-phone {
  font-size: 26px;
  font-size: 1.625rem;
}

@media (min-width: 1440px) {
  .contact-icon .icon-phone {
    font-size: 37px;
    font-size: 2.3125rem;
  }
}

.contact-icon svg {
  fill: #00ab84;
  font-size: 28px;
  font-size: 1.75rem;
}

@media (max-width: 770px) {
  .contact-icon svg {
    font-size: 28px;
    font-size: 1.75rem;
  }
}

@media (min-width: 1440px) {
  .contact-icon svg {
    font-size: 44px;
    font-size: 2.75rem;
  }
}

@media (max-width: 575px) {
  .contact-icon {
    width: 35px;
  }
}

.contact-text {
  display: block;
  position: relative;
  top: 0;
  overflow: hidden;
  font-family: "univia-pro", Arial, sans-serif;
  font-size: 20px;
  font-size: 1.25rem;
  color: #4f4f4f;
  font-weight: 400;
  line-height: 1.4;
  text-transform: none;
}

@media (min-width: 1440px) {
  .contact-text {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

.reference-group {
  justify-content: center;
}

@media (max-width: 575px) {
  .reference-group {
    margin: 0 -20px 3rem;
  }
}

.reference-item {
  position: relative;
  margin: 1rem 0;
}

.reference-item:last-child {
  border-right: 0;
}

@media (min-width: 992px) {
  .reference-item {
    border-right: 1px solid #eaedf0;
  }
  .reference-item:nth-child(4n + 4) {
    border-right: 0;
  }
}

@media (min-width: 772px) and (max-width: 992px) {
  .reference-item {
    border-right: 1px solid #eaedf0;
  }
  .reference-item:nth-child(3n + 3) {
    border-right: 0;
  }
}

@media (min-width: 577px) and (max-width: 771px) {
  .reference-item {
    border-right: 1px solid #eaedf0;
  }
  .reference-item:nth-child(3n + 3) {
    border-right: 0;
  }
}

@media (max-width: 575px) {
  .reference-item {
    border-right: 1px solid #eaedf0;
  }
  .reference-item:nth-child(2n + 2) {
    border-right: 0;
  }
  .reference-item .zeta {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

.reference-content {
  max-width: 220px;
  margin: 0 auto;
  padding: 2rem 0.2rem;
}

.reference-image-block {
  height: 60px;
}
