/* Form helpers */

%input{
	width: 100%;
	height: 60px;
	padding: 0.2rem 0;
	font-family: $font-primary;
	font-weight: 700;
	color: #ffffff;
	border: 0;
	border-bottom: 2px solid #ffffff;
	outline: none!important;
	background-color: transparent;

	@include font-size(14px);
	@include border-radius(0);
	letter-spacing: 3.5px;
	-webkit-appearance: none;
	// &:placeholder-shown{
	// 	border-color: $color-black-normal;
	// }
	// &:focus{
	// 	border-color: $color-black-normal;
	// }
}

.form-input{
	@extend %input;
}

.form-input-ext{
	border: 0;
	box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
}

.form-textarea{
	height: 190px;
	padding: 1rem 1.5rem;
	line-height: 1.5rem;
	resize: none;
	border: 2px solid #ffffff;

	@extend %input;
	@include border-radius(5px);
}

.form-textarea-limit{
	@include bp(xsmall){
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	    resize: none;
	    min-height: 190px;
	    max-height: 400px;
	}
	@include bp(rev-xsmall){
		height: 200px!important;
	}
}

.form-select{
	width: 100%;
	height: 60px;
	padding: 0.2rem 1.5rem;
	font-family: $font-primary;
	font-weight: 700;
	line-height: 1.2;
	color: $color-black-normal;
	border: 2px solid $color-black-normal;
	outline: none;
	background-color: #ffffff;
	background-color: #ffffff;

	@include font-size(16px);
	-webkit-appearance: value;
	-moz-appearance: value;
	-ms-appearance: value;
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	appearance: none;
}

.form-icon{
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	width: 60px;
	height: 60px;
	@include font-size(22px);
	color: $color-black-normal;
	align-items: center;
	justify-content: center;
	pointer-events: none;
}

.form-icon-block{
	.form-input{
		padding-right: 60px;
	}
}

.form-label{
	display: block;
	color: #ffffff;
	font-family: $font-primary;
	@include font-size(12px);
	font-weight: 500;
	line-height: 1.2;
	text-transform: uppercase;
	letter-spacing: 3.5px;
}


.form-line-small{
	margin-bottom: 0.5rem;
}

.form-line-normal{
	margin-bottom: 2rem;
}

.form-line-big{
	margin-bottom: 2rem;
	@include bp(small){
		margin-bottom: 4rem;
	}
}

.form-placeholder{
    position: relative;
    z-index: 1;
    &.is-active{
        .form-label{
        	// z-index: 9;
            width: calc(100% - 22px);
            transform: translateX(0) translateY(1px);
            @include font-size(12px);
            padding: 10px 0 5px;
        }
    	.form-label-offset{
    		padding-left: 25px;
        }
    }
    .form-input{
        padding: 30px 0 13px;
    }
    .form-textarea{
        padding: 30px 25px 13px;
    }
    .select2-container .select2-selection--single{
    	padding: 7px 40px 0 25px;
    }
    .form-label{
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        margin: 0;
        // padding: 8px 0 2px;
        cursor: text;
        transition: all .3s ease;
        transform: translateX(0) translateY(8px);
        pointer-events: none;
        padding: 15px 0 10px;
    }
    .form-label-offset{
    	padding-left: 25px;
    }
}

.form-placeholder-textarea{
	&.is-active{
        .form-label{
            transform: translateX(2px) translateY(2px);
        }
    }
	.form-label{
        transform: translateX(2px) translateY(8px);
        background-color: $color-green-normal;
    }
}


.parsley-error{
	&.select2-container .select2-selection--single,
	&.select-classic .select2-container .select2-selection--multiple,
	&.form-textarea,
	&.form-input{
		border-color: $color-red-normal!important;
	}
	& + .select2-container .select2-selection--single,
	& + .select-classic .select2-container .select2-selection--multiple{
		border-color: $color-red-normal!important;
	}
}

.parsley-success{
	&.select2-container .select2-selection--single,
	&.select-classic .select2-container .select2-selection--multiple,
	&.form-textarea,
	&.form-input{
		border-color: #ffffff!important;
	}
	& + .select2-container .select2-selection--single,
	& + .select-classic .select2-container .select2-selection--multiple{
		border-color: #ffffff!important;
	}
}

.parsley-errors-list{
	color: $color-red-normal;
	text-align: right;
	@include font-size(14px);
	li{
		display: block;
		margin-top: 5px;
	}
}