/* Header */

$main-bg: #1BBC9B;
$nav-color: #ffffff;
$nav-bg-active: #12163e;

.navigation{
	.navigation-trigger{
		display: none;
	}
	@include bp(rev-medium){
		.container-normal, .container-large{
			width: 100%;
		}

		.nav-collapse{
			position: fixed;
			z-index: 98;
			top: 59px;
			left: 0;
			overflow: auto;
			width: 100%;
			height: 100%;
			padding: 25px 0 70px;
			-webkit-transform: translateX(-100%);
			-moz-transform: translateX(-100%);
			-o-transform: translateX(-100%);
			transform: translateX(-100%);
			background-color: $nav-bg-active;

			@include transition(none);
		}

		.navigation-trigger{
			position: fixed;
			z-index: 200;
			top: 3px;
			right: 0;
			display: block;
			width: 70px;
			height: 57px;
		}

		.navigation-trigger-icon{
			position: absolute;
			top: 50%;
			left: 50%;
			display: inline-block;
			width: 30px;
			height: 3px;
			-webkit-transition-duration: 250ms;
			transition-duration: 250ms;
			-webkit-transition-property: background-color, transform;
			transition-property: background-color, transform;
			-webkit-transform: translate(-50%, -50%);
			-moz-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			background-color: $nav-color;
			font-weight: 300;

			&:before, &:after{
				position: absolute;
				display: block;
				width: 30px;
				height: 3px;
				content: '';
				-webkit-transition-duration: 250ms;
				transition-duration: 250ms;
				-webkit-transition-property: margin, transform;
				transition-property: margin, transform;
				background: $nav-color;
			}

			&:before{
				margin-top: -10px;
			}

			&:after{
				margin-top: 10px;
			}

		}

		&.is-collapse .nav-collapse{
			-webkit-transform: translateX(0);
			-moz-transform: translateX(0);
			-o-transform: translateX(0);
			transform: translateX(0);

			@include transition(transform, 250ms, ease);
		}

		&.is-collapse .navigation-trigger-icon{
			transition: none;
			background: rgba(0, 0, 0, 0.0);

			&:before{
				margin-top: 0;
				-webkit-transform: rotate(45deg);
				-moz-transform: rotate(45deg);
				-o-transform: rotate(45deg);
				transform: rotate(45deg);
			}

			&:after{
				margin-top: 0;
				-webkit-transform: rotate(-45deg);
				-moz-transform: rotate(-45deg);
				-o-transform: rotate(-45deg);
				transform: rotate(-45deg);
			}

		}

	}

}

.page-index{
	@include bp(medium){
		.header{
			position: relative;
			&:after{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				width: 100%;
				height: 400px;
				background: -moz-linear-gradient(top,  rgba(27,31,70,0.8) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top,  rgba(27,31,70,0.8) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom,  rgba(27,31,70,0.8) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc1b1f46', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
				z-index: 2;
				pointer-events: none;
			}
		}
		.navigation:not(.navigation-scroll){
			background-color: transparent;
			// background-color: rgba(18, 22, 63, 0.91);
			// &:af;
			// border-top: 0;
			// .header-logo-link{
			// 	background-color: transparent;
			// }
			.header-logo{
				height: 140px;
			}
			.footer-address-icon{
				svg{
					fill: $color-green-normal;
				}
			}
			.footer-address-text{
				color: #ffffff;
			}
		}
		.navigation-scroll{
			.menu-list-link{
				padding: 0.4rem 0.7rem;
			}
		}
	}
}

.navigation{
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	width: 100%;
	background-color: #001d5d;
	// background-color: #12163e;
	padding: 0 20px;
	border-top: 2px solid $color-blue-normal;
	transition: box-shadow 350ms ease-out, background-color 350ms ease-out;
	@include bp(medium){
		padding: 0 15px;
	}
	@include bp(xlarge){
		padding: 0 30px;
	}

}

.navigation-scroll{
	background-color: #001d5d;
	.header-logo{
		height: 80px;
	}
	.header-logo-link{
		height: 80px;
		@include bp(medium){
			padding-bottom: 0;
		}
	}
	.header-image{
		@include bp(medium){
			// width: 110px;
		}
	}
	// .menu-list-item.is-active:before{
	// 	top: -1px;
	// }
}

.header-flex{
	@include bp(medium){
		display: flex;
		// align-items: center;
		justify-content: space-between;
	}

}

.header-logo{
	display: flex;
	align-items: center;
	float: left;
	text-align: center;
	height: 58px;
	transition: height 400ms ease-out;
	@include bp(medium){
		height: 120px;
		width: 180px;
		max-width: 180px;
		flex: 0 0 180px;
		padding-right: 25px;
	}

	@include bp(xlarge){
		padding-right: 0;
		width: 220px;
		max-width: 220px;
		flex: 0 0 220px;
	}

	@include bp(rev-medium){
		position: relative;
		z-index: 99;
		padding: 5px 0 5px;
	}

}

.header-logo-link{
	display: flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	transition: height 400ms ease-out;
}

.header-image{
	// margin: 0 auto;
	transition: width 400ms ease-out;
	@include bp(rev-medium){
		width: 150px;
	}
	// @include bp(excl-large){
	// 	width: 70px;
	// }

}

.nav-collapse{
	@include bp(medium){
		display: flex;
		justify-content: flex-end;
		width: calc(100% - 180px);
		max-width: calc(100% - 180px);
		flex: 0 0 calc(100% - 180px);
		align-items: center;
	}
	@include bp(xlarge){
		width: calc(100% - 220px);
		max-width: calc(100% - 220px);
		flex: 0 0 calc(100% - 220px);
	}
	@include bp(xxlarge){
		padding-left: 3rem;
	}

}

.menu-list{
	text-align: center;

	@include bp(medium){
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		// align-items: center;
	}
	@include bp(excl-large){
		width: 100%;
	}
}

.menu-list-item{
	position: relative;
	@include bp(medium){
		display: flex;
	}
	@include bp(rev-medium){
		display: block;
		text-align: center;
	}

	&:hover, &.is-active{
		.menu-list-link{
			color: #ffffff;
		}
	}
	&.is-active{
		@include bp(medium){
			&:before{
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				display: inline-block;
				width: 25px;
				height: 2px;
				background-color: $color-green-normal;
			}
		}
	}
}

.menu-list-link{
	position: relative;
	display: inline-block;
	padding: 0.9rem 1rem;
	text-decoration: none;
	color: #6979dc;
	font-family: $font-primary;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 3.5px;
	line-height: 1.4;
	@include font-size(14px);
	@include bp(medium){
		display: flex;
		align-items: center;
		padding: 0.8rem 0.7rem;
		@include font-size(12px);
	}
	@include bp(xlarge){
		padding: 0.8rem 1rem;
	}
	@include bp(xxlarge){
		padding: 0.8rem 1.4rem;
		letter-spacing: 3.5px;
	}
}

.header-contact{
	@include bp(rev-medium){
		justify-content: center;
		display: flex;
		flex-wrap: wrap;
		width: 280px;
		margin: 1rem auto 0;
	}
	@include bp(medium){
		justify-content: flex-end;
		display: flex;
		flex-wrap: wrap;
		width: 240px;
	}
	@include bp(excl-large){
		flex-wrap: wrap;
	}
	.footer-address-item{
		width: 100%;
		padding: 12px 20px;
		margin-bottom: 0;
		&:after{
			display: none;
		}
		@include bp(medium){
			padding: 6px 10px;
			padding-right: 0;
			justify-content: flex-end;
		}
		@include bp(xlarge){
			padding: 6px 20px;
			padding-right: 0;
		}
		@include bp(excl-large){
			padding: 5px 20px;
			padding-right: 0;
		}
	}
	.footer-address-text{
		letter-spacing: 3.5px;
		@include font-size(12px);
	}
	.footer-address-icon{
		position: relative;
		top: 1px;
		margin-right: 5px;
		svg{
			color: $color-green-normal;
			@include font-size(18px);
		}
	}
}
