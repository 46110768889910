/* Tabs block */

.tabs-list{
	position: relative;
	display: flex;
	text-align: center;
	border: 0;

	align-items: center;
	justify-content: center;
	&:before{
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: #eaedf0;
		z-index: -1;
	}
}

.tab-list-item{
	position: relative;
	display: flex;
	height: 60px;
	cursor: pointer;
	text-align: center;
	border: 0;
	background-color: #ffffff;
	box-shadow: 0 4px 19px rgba(0, 0, 0, 0.07);

	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	padding: 0 2rem;
	&:first-child{
		border-radius: 4px 0 0 4px;
	}
	&:last-child{
		border-radius: 0 4px 4px 0;
	}
	@include bp(rev-xsmall){
		padding: 0.5rem 1rem;
	}
	&.mixitup-control-active,
	&.is-active{
		background-color: $color-green-normal;
		.tab-list-text{
			color: #ffffff;
		}
	}
	&:hover{
		background-color: $color-green-normal;
		.tab-list-text{
			color: #ffffff;
		}
	}
}

.tab-list-text{
	font-family: $font-primary;
	font-weight: 500;
	line-height: 1.4;
	color: $color-green-normal;

	@include font-size(15px);
}


.tabs-content{
	clear: both;
}

.tab-content-item{
	visibility: hidden;
	overflow: hidden;
	height: 0;
	opacity: 0;
	@include transition(opacity, 400ms, ease-out);
	&.is-active{
		visibility: visible;
		overflow: visible;
		height: auto;
		opacity: 1;
		padding-bottom: 1rem;
		border-bottom: 1px solid #eaedf0;
		@include bp(small){
			padding-top: 2rem;
			padding-bottom: 4rem;
		}
	}
}

@include bp(small){
	.tab-sm-content-item{
		visibility: hidden;
		overflow: hidden;
		height: 0;
		opacity: 0;
		@include transition(opacity, 400ms, ease-out);
		&.is-active{
			visibility: visible;
			overflow: visible;
			height: auto;
			opacity: 1;
		}
	}
}

@include bp(medium){
	.tab-md-content-item{
		visibility: hidden;
		overflow: hidden;
		height: 0;
		opacity: 0;
		@include transition(opacity, 400ms, ease-out);
		&.is-active{
			visibility: visible;
			overflow: visible;
			height: auto;
			opacity: 1;
		}
	}
}

@include bp(large){
	.tab-lg-content-item{
		visibility: hidden;
		overflow: hidden;
		height: 0;
		opacity: 0;
		@include transition(opacity, 400ms, ease-out);
		&.is-active{
			visibility: visible;
			overflow: visible;
			height: auto;
			opacity: 1;
		}
	}
}