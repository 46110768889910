/* Border helpers */

.border-0 {
    border: 0!important;
}

.border-0-left {
    border-left: 0!important;
}

.border-0-right {
border-right: 0!important;
}

.border-0-bottom {
    border-bottom: 0!important;
}

.border-0-top {
    border-top: 0!important;
}

.border-green-top{
	border-radius: 5px;
	border-top: 3px solid $color-green-normal;
}