/* article block */
.article-item{
	margin-bottom: 2rem;
	&:last-child{
		margin-bottom: 0;
	}
}

.article-item-ext{
	@include bp(medium){
		.article-image-block{
			order: 1;
		}
		.article-text-block{
			order: 0;
		}
	}
}

.article-content{
	display: block;
	// align-items: center;
	overflow: hidden;
	background-color: #ffffff;
	box-shadow: 0 4px 19px rgba(0, 0, 0, 0.07);
	@include bp(xsmall){
		display: flex;
	}
	@include bp(medium){
		display: flex;
		height: 200px;
	}
	.zeta{
		letter-spacing: 0.3px;
	}
	&:hover{
		box-shadow: 0 4px 19px rgba(0, 0, 0, 0.07);
		img{
			transform: scale(1.05, 1.05);
		}
		.article-title{
			color: $color-blue-normal;
		}
	}
}

.article-width{
	@include bp(medium){
		max-width: 220px;
		margin: 0 auto;
	}
}

.article-image-block{
    display: flex;
    // height: 100%;
	position: relative;
	overflow: hidden;
	@include bp(rev-medium){
		height: 220px;
	}
	@include bp(rev-xsmall){
		height: 180px;
	}
	@include bp(xsmall){
		width: 50%;
		flex: 0 0 50%;
		max-width: 50%;
	}

	img{
		transition: transform 400ms ease-out;
	}

}

.article-text-block{
	display: flex;
	align-items: center;
	padding: 1.5rem 1.5rem;
	@include bp(xsmall){
		width: 50%;
		flex: 0 0 50%;
		max-width: 50%;
		padding: 0.3rem 1rem;
	}
}
