.file-item{
	margin-bottom: 1rem;
	align-content: center;
	box-shadow: 0 4px 24px rgba(0, 0, 0, 0.07);
	border-radius: 4px;
	background-color: #ffffff;
	border-bottom: 2px solid $color-green-normal;
	padding: 1.5rem 1.5rem;
	@include bp(xsmall){
		padding: 1.5rem 2rem;
	}
}

.file-content{
	align-items: center;
	justify-content: space-between;
}

.file-name{
	display: block;
	margin-bottom: 0.3rem;
	color: $color-black-normal;
	font-family: $font-primary;
	font-weight: 500;
	line-height: 1.2;
	text-transform: uppercase;
	letter-spacing: 0.15px;
	@include font-size(15px);
}

.file-description{
	color: $color-black-normal;
	font-family: $font-primary;
	@include font-size(15px);
	font-weight: 300;
	line-height: 1.4;
	letter-spacing: 0.15px;
}

.file-info{
	color: #383838;
	font-family: $font-primary;
	@include font-size(12px);
	font-weight: 300;
	text-transform: uppercase;
	letter-spacing: 0.84px;
	@include bp(small){
		min-width: 130px;
		@include font-size(14px);
	}
	@include bp(rev-small){
		margin-top: 10px;
	}
}

.file-btn{
	color: #ffffff!important;
	text-decoration: none!important;
	@include bp(excl-medium){
		min-width: auto;
	}
	@include bp(small){
		margin-left: 1rem;
	}
	@include bp(rev-small){
		width: 100%;
	}
}
