/* Icon helpers */

.ico-size-small{
	svg{
		@include font-size(12px);
	}
}

.ico-size-normal{
	svg{
		@include font-size(12px);
	}
}

.icon-size-45{
	svg{
		@include font-size(45px);
	}
}

.icon-size-110{
	svg{
		@include font-size(110px);
	}
}
