.pagination-list{
	width: 100%;
	position: relative;
	@include bp(rev-xsmall){
		text-align: center;
	}
}

.pagination-list-item{
	display: inline-block;
	vertical-align: middle;
	margin-left: -4px;
	margin-right: 10px;
	padding-left: 0;
	margin-bottom: 0;
	@include bp(rev-xsmall) {
		margin-right: 8px;
	}
	&:hover{
		.pagination-list-link{
			border-color: $color-blue-normal;
			background-color: $color-blue-normal;
			color: #ffffff;
		}
	}
	&.more{
		.pagination-list-link{
			&:hover{
				border-color: #e8e8e8;
				background-color: $color-gray-light;
				color: #8a8a8a;
			}
		}
	}

	&:before{
		content: none;
	}

	&:first-child{
		.pagination-list-link{
			width: auto;
			min-width: 120px;
			padding: 0 25px;
			padding-top: 2px;
			@include bp(rev-small){
				padding: 0 15px;
			}
		}
		@include bp(rev-xsmall){
			width: 100%;
			margin-right: 0;
			margin-bottom: 10px;
		}
	}

	&:last-child{
		margin-right: 0;
		.pagination-list-link{
			width: auto;
			min-width: 120px;
			padding: 0 25px;
			padding-top: 2px;
			@include bp(rev-small){
				padding: 0 15px;
			}
		}
		@include bp(rev-xsmall){
			width: 100%;
			margin-right: 0;
			margin-top: 10px;
		}
	}

	&.is-active{
		.pagination-list-link{
			background-color: $color-blue-normal;
			color: #ffffff;
			border-color: $color-blue-normal;
			&:hover{
			}
		}
	}
}

.pagination-list-link{
	display: block;
	width: 40px;
	height: 40px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-color: $color-gray-light;
	border: 1px solid #e8e8e8;
	text-decoration: none!important;
	color: #212232;
	padding-top: 2px;
	@include font-size(12px);
	font-weight: 400;
	text-transform: uppercase;
}

