/* .checkbox.radiobox
	input#checkbox_payment_1.checkbox-hidden(type='radio', name="payment" required='')
	label.checkbox-label(for='checkbox_payment_1')
		span.checkbox-box
		span.checkbox-text Platba kartou */
	   

/* Checkbox helpers */

.checkbox{
	margin-bottom: 2rem;
}

.checkbox-hidden {
	position: absolute;
	opacity: 0;
	// display: none;
	// top: -9999px;
	// left: -9999px;
	// appearance: none;
	// -webkit-appearance: none;
	// -moz-appearance: none;
}

.checkbox-label {
	margin: 0;
	padding-left: 0;
	min-height: inherit;
	width: 100%;
	display: flex;
	align-items: center;
	vertical-align: middle;
	padding-top: 0;
	cursor: pointer;
	text-transform: none;
	// justify-content: center;
}

.checkbox-box {
	position: relative;
	width: 25px;
	height: 25px;
	border: 2px solid $color-black-normal;
	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
	margin-left: 0;
}

.checkbox-text {
	display: block;
	position: relative;
	cursor: pointer;
	text-align: left;
	vertical-align: middle;
	color: $color-black-normal;
	@include font-size(16px);
	text-transform: uppercase;
	margin-left: 0;
}


.checkbox-hidden:checked + .checkbox-label {
	.checkbox-box {
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 2px;
			left: 7px;
			width: 7px;
			height: 13px;
			border: solid $color-black-normal;
			border-width: 0 2px 2px 0;
			-webkit-transform: rotate(40deg);
			transform: rotate(40deg);
		}
	}
}

.radiobox{
	margin-bottom: 2rem;
	.checkbox-box {
		top: 0;
		width: 26px;
		height: 26px;
		border: 2px solid $color-black-normal;
		// background-color: rgba(0,0,0,0.03);
		@include border-radius(100%);
	}
	.checkbox-hidden:checked + .checkbox-label {
		.checkbox-box {
			// border: 2px solid $color-black-normal;
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				@include border-radius(100%);
				margin-top: -5px;
				margin-left: -5px;
				width: 10px;
				height: 10px;
				background-color: $color-black-normal;
			}
		}
	}
}
