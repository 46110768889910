.section-hero{
	// min-height: 400px;
	// height: 860px;
	padding: 3rem 0.5rem 5rem;
	@include bp(medium){
		padding-top: 220px;
		padding-bottom: 160px;
		// height: calc(100vh - 80px);
		// max-height: 750px;
	}
	@include bp(xlarge){
		padding-left: 160px;
	}
	@media (min-width: 992px) and (max-height: 880px){
		padding-top: 150px;
		padding-bottom: 140px;
		.margin-bottom-3{
			margin-bottom: 1.5rem;
		}
		.margin-bottom-5{
			margin-bottom: 3rem;
		}
		.slick-arrow-hero .slick-arrows{
			bottom: -100px;
		}
	}
}

.js-slider-hero-image{
	position: absolute;
	top: 0;
	left: 0;
	height: 1199px;
	width: 100%;
    @include bp(rev-medium){
    	height: 779px;
    }
    @media (min-width: 2000px){
		height: 1409px;
    }
	div:not(.bg-image-hero){
		height: 100%;
	}
}
