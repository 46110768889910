/* Base settings */
*, *::before, *::after{
	box-sizing: border-box;
}

*{
	transition: color .15s, background .15s, border .15s, opacity .15s;
}

*:after, *:before{
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	outline: none;
}

::-moz-selection{
	color: #fff;
	background-color: $color-green-normal;
}

::selection{
	color: #fff;
	background-color: $color-green-normal;
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

::-webkit-scrollbar-track {
	border-radius: 0;
	background: $color-gray-light; 
}
 
::-webkit-scrollbar-thumb {
	border-radius: 0;
	background: $color-blue-dark;
}

::-webkit-scrollbar-thumb:hover {
	background: darken($color-blue-dark,5%);
}

@-ms-viewport{
	width: device-width;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary{
	display: block;
}

html{
	position: relative;
	box-sizing: border-box;
	height: 100%;
	font-size: 100%;
}

body{
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 310px;
	height: 100%;
	margin: 0;
	color: #000;
	background-color: #ffffff;
	font-family: $font-primary;
	font-weight: 500;
	line-height: 1;
}

a{
	cursor: pointer;
	text-decoration: none;
	color: inherit;
	outline: none;
	font-family: $font-primary;
}

a:focus, a:active, a:visited{
	outline: none;
}

.a-hover{
	&:hover, &:focus{
		text-decoration: underline;
		color: white;
	}

}

.a-hover-link{
	display: flex;
	align-items: center;
	cursor: pointer;
}

.a-hover-link-text{
	text-transform: uppercase;
	color: $color-green-normal;
	font-family: $font-primary;
	font-weight: 700;

	@include font-size(16px);
}

.a-hover-link-text-green{
	color: $color-green-normal;
}

.a-hover-link-icon{
	display: inline-block;
	margin-left: 5px;
	vertical-align: middle;
	color: $color-green-normal;

	@include font-size(24px);
}

p{
	display: block;
	margin-top: 0;
	margin-bottom: 2em;
	color: $color-black-normal;
	font-family: $font-primary;
	font-weight: 300;
	line-height: 1.8;
	@include font-size(15px);
}

.p-large{
	p{
		@include font-size(16px);
		@include bp(xsmall){
			@include font-size(16px);
		}
		@include bp(small){
			@include font-size(18px);
		}
		@include bp(medium){
			@include font-size(20px);
		}
	}
}

.p-big{
	p{
		@include font-size(16px);
		@include bp(small){
			@include font-size(18px);
		}
	}

}

.p-normal{
	p{
		line-height: 1.6;

		@include font-size(14px);
	}

}

.p-small{
	p{
		letter-spacing: 0.65px;
		line-height: 1.5;

		@include font-size(13px);
	}

}

.p-secondary{
	p{
		font-family: $font-primary;
		font-weight: 700;
	}

}

.p-line-1-4{
	p{
		line-height: 1.4;
	}
}

.p-line-1-6{
	p{
		line-height: 1.6;
	}
}

.p-line-1-8{
	p{
		line-height: 1.8;
	}

}

.p-line-2{
	p{
		line-height: 2;
	}
}

.p-line-2-2{
	p{
		line-height: 2.2;
	}

}

ol, ul{
	margin: 0;
	padding: 0;
	list-style: none;
}

ul.list-style, ol.list-style{
	margin-bottom: 2em;

	li{
		position: relative;
		display: block;
		margin-bottom: 8px;
		padding-left: 25px;
		color: $color-black-normal;
		font-family: $font-primary;
		font-weight: 500;
		line-height: 1.4;
		letter-spacing: 0.23px;

		@include font-size(15px);
	}

	a{
		color: $color-green-normal;

		&:hover{
			text-decoration: underline;
		}

	}

}

ul.list-style{
	li{
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 50px;
		border-bottom: 1px solid #eff2f4;
		@include bp(xsmall){
			padding-top: 20px;
			padding-bottom: 20px;
		}
		@include bp(large){
			padding-left: 80px;
		}
		&:before{
			content: "";
			position: absolute;
			top: 50%;
			left: 15px;
			transform: translateY(-50%);
			display: block;
			width: 19px;
			height: 15px;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			background-image: url('../images/icons/check.svg');
			@include bp(large){
				left: 50px;
			}
		}

	}

}

ol.list-style{
	list-style-position: inside;
	counter-reset: item;

	li{
		&:before{
			position: absolute;
			top: 0;
			left: 0;
			display: inline-block;
			content: counter(item)'.';
			counter-increment: item;
			color: $color-green-normal;
			line-height: 1.4;

			@include font-size(16px);
		}

	}

}

em, i, .italic{
	font-style: italic;
}

strong, .strong{
	font-weight: 700;
}

small, .small{
	@include font-size(12px);
}

h1, .alfa, h2, .beta, h3, .gamma, h4, .delta, h5, .epsilon, h6, .zeta{
	margin: 0;
	margin-bottom: 1.5em;
	color: $color-blue-normal;
	font-family: $font-primary;
	font-weight: 700;
	line-height: 1.4;
	a{
		color: inherit;
	}

}

h1, .alfa{
	@include font-size(36px);
	@include bp(xsmall){
		@include font-size(40px);
	}
	@include bp(small){
		@include font-size(50px);
	}

	@include bp(medium){
		@include font-size(60px);
	}
}


h2, .beta{
	@include font-size(36px);
	@include bp(xsmall){
		@include font-size(38px);
	}
	@include bp(small){
		@include font-size(40px);
	}
	@include bp(small){
		@include font-size(44px);
	}

}

h3, .gamma{
	letter-spacing: 1.99px;
	@include font-size(32px);
	@include bp(xsmall){
		@include font-size(36px);
	}
	@include bp(small){
		@include font-size(37px);
	}

}

h4, .delta{
	letter-spacing: 1.99px;
	@include font-size(28px);
	@include bp(xsmall){
		@include font-size(32px);
	}
	@include bp(small){
		@include font-size(34px);
	}


}

h5, .epsilon{
	@include font-size(24px);
	@include bp(xsmall){
		@include font-size(26px);
	}
	@include bp(small){
		@include font-size(29px);
	}
}

.epsilon-ext{
	@include bp(small){
		@include font-size(25px);
	}
}

h6, .zeta{
	@include font-size(15px);
	letter-spacing: 0.3px;
	@include bp(xsmall){
		@include font-size(15px);
	}
	@include bp(small){
		@include font-size(15px);
	}
}


.headline-divider{
	margin-top: -10px;
	svg{
		@include font-size(50px);
		fill: $color-green-normal;
	}
}