/* Table block */

.table-responsives{
	@include bp(rev-small){
	    overflow-x: auto;
	    overflow-y: hidden;
	    width: 100%;
	    min-height: .01%;
	    border: 0 !important;
	    -ms-overflow-style: -ms-autohiding-scrollbar;
	    background: -webkit-gradient(linear, left top, right top, color-stop(30%, white), to(rgba(255, 255, 255, 0))), -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), color-stop(70%, white)) 0 100%, radial-gradient(farthest-side at 0% 50%, #f8faff, rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 100% 50%, #f8faff, rgba(0, 0, 0, 0)) 0 100%;
	    background: -webkit-linear-gradient(left, white 30%, rgba(255, 255, 255, 0)), -webkit-linear-gradient(left, rgba(255, 255, 255, 0), white 70%) 0 100%, -webkit-radial-gradient(0% 50%, farthest-side, #f8faff, rgba(0, 0, 0, 0)), -webkit-radial-gradient(100% 50%, farthest-side, #f8faff, rgba(0, 0, 0, 0)) 0 100%;
	    background: -o-linear-gradient(left, white 30%, rgba(255, 255, 255, 0)), -o-linear-gradient(left, rgba(255, 255, 255, 0), white 70%) 0 100%, -o-radial-gradient(0% 50%, farthest-side, #f8faff, rgba(0, 0, 0, 0)), -o-radial-gradient(100% 50%, farthest-side, #f8faff, rgba(0, 0, 0, 0)) 0 100%;
	    background: linear-gradient(to right, white 30%, rgba(255, 255, 255, 0)), linear-gradient(to right, rgba(255, 255, 255, 0), white 70%) 0 100%, radial-gradient(farthest-side at 0% 50%, #f8faff, rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 100% 50%, #f8faff, rgba(0, 0, 0, 0)) 0 100%;
	    background-repeat: no-repeat;
	    background-color: white;
	    -webkit-background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
	    background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
	    background-position: 0 0, 100%, 0 0, 100%;
	    background-attachment: local, local, scroll, scroll;
	}
}

.table-md-responsives{
	@include bp(rev-medium){
		overflow-x: auto;
		overflow-y: hidden;
		width: 100%;
		min-height: .01%;
		border: 0!important;

		-ms-overflow-style: -ms-autohiding-scrollbar;

		// overflow: auto;
		// max-width: 100%;
		background: linear-gradient(to right, white 30%, rgba(255, 255, 255, 0)), linear-gradient(to right, rgba(255, 255, 255, 0), white 70%) 0 100%, radial-gradient(farthest-side at 0% 50%, rgb(250, 247, 243), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 100% 50%, rgb(250, 247, 243), rgba(0, 0, 0, 0)) 0 100%;
		background-repeat: no-repeat;
		background-color: white;
		background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
		background-position: 0 0, 100%, 0 0, 100%;
		background-attachment: local, local, scroll, scroll;
	}
}

table{
	width: 100%;
	border-collapse: collapse;
	thead{
		tr{
			border-bottom: 2px solid $color-green-normal;
		}
	}
	th{
		padding: 1rem 1rem 1rem 1rem;
		font-family: $font-primary;
		font-weight: 500;
		line-height: 1.4;
		text-align: center;
		text-transform: uppercase;
		color: $color-black-normal;
		letter-spacing: 0.15px;

		@include font-size(14px);
		@include bp(small){
			@include font-size(15px);
		}
	}
	tbody{
		tr{
			&:nth-child(even){
				background-color: #f8faff;
			}
		}
	}
	td{
		padding: 1rem 1rem 1rem 1rem;
		font-family: $font-primary;
		line-height: 1.4;
		text-align: center;
		font-weight: 300;
		color: $color-black-normal;
		letter-spacing: 1.27px;
		@include font-size(15px);
		&:first-child{
		}
	}
}
