/* Content */

section{
	position: relative;
	padding: 3rem 0 3rem;
	z-index: 3;

	@include bp(small){
		padding: 3rem 0 3rem;
	}
}

.no-padding{
	padding: 0;
}

@include bp(xsmall){
	.no-xs-padding{
		padding: 0;
	}
}

@include bp(small){
	.no-sm-padding{
		padding: 0;
	}
}

@include bp(medium){
	.no-md-padding{
		padding: 0;
	}
}

@include bp(large){
	.no-lg-padding{
		padding: 0;
	}
}

.padding-style-1{
	padding: 2rem 0.5rem 1rem;

	@include bp(small){
		padding: 2rem 0 0;
	}
}

.padding-style-1-ext{
	padding: 0 0 1rem;

	@include bp(small){
		padding: 1rem 0 1rem;
	}
}


.padding-style-2{
	padding: 2rem 0.5rem 2rem;

	@include bp(small){
		padding: 2rem 0 2rem;
	}
}

.padding-style-3{
	padding: 2rem 1rem 2rem;

	@include bp(small){
		padding: 2rem 0 3rem;
	}
}

.padding-style-4{
	padding: 3rem 0.5rem 3rem;

	@include bp(medium){
		padding: 4rem 0 4rem;
	}
}

.padding-style-4-ext{
	padding: 3rem 0.5rem 3rem;

	@include bp(medium){
		padding: 6rem 0 4rem;
	}
}

.padding-style-5{
	padding: 3rem 0.5rem 3rem;

	@include bp(small){
		padding: 4.5rem 0 5rem;
	}
}

.padding-style-5-ext{
	padding: 3rem 0.5rem 0;

	@include bp(small){
		padding: 5rem 0 2.5rem;
	}
}

.padding-style-6{
	padding: 3rem 0.5rem 3rem;

	@include bp(medium){
		padding: 0 0 5rem;
	}
}

.padding-style-6-ext{
	padding: 3rem 0.5rem 2rem;

	@include bp(small){
		padding: 6rem 0 4rem;
	}
}

.padding-style-6-ext-1{
	padding: 3rem 0.5rem 2rem;

	@include bp(small){
		padding: 6rem 0 6rem;
	}
}

.padding-style-7{
	padding: 3rem 0.5rem 3rem;

	@include bp(small){
		padding: 6rem 0 8rem;
	}
}

.padding-style-7-ext{
	padding: 3rem 0.5rem 2rem;

	@include bp(small){
		padding: 4rem 0 4rem;
	}
}

.padding-content-1{
	padding: 1rem 1rem;
}

.padding-content-2{
	padding: 2rem 2rem;
}

.padding-content-3{
	padding: 3rem 3rem;
}

.padding-content-4{
	padding: 4rem 4rem;
}

.padding-content-5{
	padding: 5rem 5rem;
}

.content-width-200{
	max-width: 200px;
}

.content-width-300{
	max-width: 300px;
}

.content-width-600{
	max-width: 600px;
}

.content-width-670{
	max-width: 670px;
}

.content-width-750{
	max-width: 750px;
}


@include bp(rev-medium){
	.content-width-md-300{
		max-width: 300px;
	}
}

.page-index{
	main{
		padding-top: 60px;

		@include bp(medium){
			padding-top: 0
		}
	}
}

main{
	position: relative;
	display: block;
	padding-top: 60px;

	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	@include bp(medium){
		padding-top: 120px;
	}
}

.container-full{
	padding: 0 15px;
}

.container-small,
.container-normal,
.container-large{
	margin: 0 auto;
	padding: 0 15px;
}

.container-small{
	@include bp(small){
		width: 730px;
	}
	@include bp(medium){
		width: 800px;
	}
	@include bp(large){
		width: 800px;
	}
	@include bp(xlarge){
		width: 800px;
	}
	@include bp(rev-small){
		width: 100%;
	}
}

.container-normal{
	@include bp(small){
		width: 730px;
	}
	@include bp(medium){
		width: 920px;
	}
	@include bp(large){
		width: 1140px;
	}
	@include bp(xlarge){
		width: 1200px;
	}
	@include bp(rev-small){
		width: 100%;
	}
}

.container-large{
	@include bp(small){
		width: 730px;
	}
	@include bp(medium){
		width: 950px;
	}
	@include bp(large){
		width: 1140px;
	}
	@include bp(xlarge){
		width: 1360px;
	}
	@include bp(xxlarge){
		width: 1480px;
	}
	@include bp(rev-small){
		width: 100%;
	}
	@media (min-width: 1900px){
		width: 1720px;
	}

	@media (min-width: 2000px){
		width: 1800px;
	}
}