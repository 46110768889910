.pdf-item{
	margin-bottom: 2rem;
	// height: calc( (360px / 270) * 200 );
	// @include bp(xsmall){
	// 	height: calc( (360px / 270) * 50% );
	// }
	@include bp(small){
		height: calc( (360px / 270) * 335 );
	}
	@include bp(medium){
		height: calc( (360px / 270) * 255 );
	}
	@include bp(xlarge){
		height: calc( (360px / 270) * 270 );
	}
	// @include bp(rev-xssmall){
	// 	width: 100%;
	// 	max-width: 100%;
	// 	flex: 0 0 100%;
	// }
}

.pdf-content{
	display: block;
	position: relative;
	box-shadow: 0 4px 24px rgba(0, 0, 0, 0.07);
	border-radius: 4px;
	border-bottom: 2px solid $color-green-normal;
	background-color: #ffffff;
	text-decoration: none!important;
	// height: 450px;
	// @include bp(xsmall){
	// 	height: 450px;
	// }
	@include bp(small){
		height: 100%;
	}
	@include bp(rev-small){
		height: calc( (347px / 260) * 260 );
		width: 260px;
		margin: 0 auto;
	}
	@include bp(rev-xsmall){
		height: calc( (387px / 290) * 290 );
		width: 290px;
		margin: 0 auto;
	}
	&:hover{
		.pdf-name{
			color: $color-green-normal;
		}
	}
	.btn-arrow{
		position: absolute;
		bottom: 1rem;
		right: 1rem;
	}
}

.pdf-image{
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	img{
		// object-fit: contain;
	}
}

.pdf-size-block{
	// height: 250px;
	display: flex;
	// align-items: center;
	justify-content: center;
	text-align: center;
	padding: 1rem 0.5rem 1rem;
	color: #ffffff;
	font-family: $font-primary;
	@include font-size(20px);
	font-weight: 300;
	line-height: 1.4;
	text-transform: uppercase;
	letter-spacing: 1px;
	z-index: 2;
	position: relative;
	// flex-direction: column;
	// @include bp(xsmall){
	// 	height: 320px;
	// }
	height: calc(100% - 90px);
	@include bp(small){
		height: calc(100% - 90px);
	}
	strong{
		display: block;
		color: #ffffff;
		font-weight: 500;
	}
}

.pdf-size-center{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	flex-direction: column;
	height: 80%;
}

.pdf-text-block{
	position: relative;
	padding: 1.5rem 1rem;
	border-top: 1px solid #eaedf0;
	text-align: center;
	background-color: #ffffff;
	z-index: 3;
	height: 90px;
	@include bp(small){
		height: 90px;
	}
}

.pdf-name{
	display: block;
	margin-bottom: 0.5rem;
	color: #383838;
	@include font-size(15px);
	font-weight: 400;
	line-height: 1.4;
	letter-spacing: 0.45px;
}

.pdf-info{
	display: block;
	color: #858585;
	@include font-size(12px);
	font-weight: 400;
	line-height: 1.2;
	letter-spacing: 0.36px;
}

