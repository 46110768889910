// GENERAL
$project-name: 'project-name';
$project-font-1:'univia-pro';
// $project-font-2:'Monarcha';
$debug: false;
$debug-screen: true;

// COMPASS VARIABLES
$border-radius: 5px;
$transition-property: all;
$transition-duration: .4s;
$transition-easing: ease-out;

// GRID VARIABLES
$grid-columns: 12;
$grid-gutter-width: 30px;
$screen-xs-phone: 370px;
$screen-phone: 576px;
$screen-tablet: 771px;
$screen-desktop: 992px;
$screen-lg-desktop: 1200px;
$screen-xl-desktop: 1440px;
$screen-xxl-desktop: 1600px;
$container-tablet: (720px + $grid-gutter-width);
$container-sm: $container-tablet;
$container-desktop: (940px + $grid-gutter-width);
$container-md: $container-desktop;
$container-large-desktop: (1140px + $grid-gutter-width);
$container-lg: $container-large-desktop;

// FONT SETTINGS
$font-size-small: 12px;
$font-size-smaller: 13px;
$font-size-normal: 14px;
$font-size-bigger: 16px;
$font-size-big: 18px;
$font-primary: $project-font-1, Arial, sans-serif;
// $font-primary: $project-font-2, Arial, sans-serif;
$font-light: 300;
$font-regular: 400;
$font-bold: 700;
$alfa: 48px;
$beta: 36px;
$gamma: 24px;
$delta: 18px;
$epsilon: 16px;
$zeta: 14px;

// COLORS SETTINGS
$color-green-normal: #00ab84;
$color-blue-dark: #0b204f;
$color-gray-light: #f7f9ff;
$color-red-normal: #cd1434;
$color-blue-normal: #2244b3;
$color-white-light: #e8eeff;
$color-black-normal: #4f4f4f;
$color-orange-normal: #e03d20;
$color-blue-light: #77a4ee;