.js-slider{
	display: none;
	&.slick-initialized{
		display: block;
	}
	.slick-slide{
		outline: none;
	}
	.js-slide-item{
		outline: none;
	}
}

.slick-margin-negative-10{
	margin: 0 -10px;
	.js-slide-item{
		padding-right: 10px;
		padding-left: 10px;
	}
}

.slick-margin-negative-20{
	margin: 0 -20px;
	.js-slide-item{
		padding-right: 20px;
		padding-left: 20px;
	}
}

.slick-margin-negative-30{
	margin: 0 -30px;
	.js-slide-item{
		padding-right: 30px;
		padding-left: 30px;
	}
}

.slick-margin-negative-40{
	margin: 0 -40px;
	.js-slide-item{
		padding-right: 40px;
		padding-left: 40px;
	}
}

.slick-margin-negative-50{
	margin: 0 -50px;
	.js-slide-item{
		padding-right: 50px;
		padding-left: 50px;
	}
}

@include bp(xsmall){
	.slick-margin-negative-xs-5{
		margin: 0 -5px;
		.js-slide-item{
			padding-right: 5px;
			padding-left: 5px;
		}
	}

	.slick-margin-negative-xs-10{
		margin: 0 -10px;
		.js-slide-item{
			padding-right: 10px;
			padding-left: 10px;
		}
	}

	.slick-margin-negative-xs-20{
		margin: 0 -20px;
		.js-slide-item{
			padding-right: 20px;
			padding-left: 20px;
		}
	}

	.slick-margin-negative-xs-30{
		margin: 0 -30px;
		.js-slide-item{
			padding-right: 30px;
			padding-left: 30px;
		}
	}

	.slick-margin-negative-xs-40{
		margin: 0 -40px;
		.js-slide-item{
			padding-right: 40px;
			padding-left: 40px;
		}
	}

	.slick-margin-negative-xs-50{
		margin: 0 -50px;
		.js-slide-item{
			padding-right: 50px;
			padding-left: 50px;
		}
	}
}

@include bp(small){
	.slick-margin-negative-sm-5{
		margin: 0 -5px;
		.js-slide-item{
			padding-right: 5px;
			padding-left: 5px;
		}
	}

	.slick-margin-negative-sm-10{
		margin: 0 -10px;
		.js-slide-item{
			padding-right: 10px;
			padding-left: 10px;
		}
	}

	.slick-margin-negative-sm-20{
		margin: 0 -20px;
		.js-slide-item{
			padding-right: 20px;
			padding-left: 20px;
		}
	}

	.slick-margin-negative-sm-30{
		margin: 0 -30px;
		.js-slide-item{
			padding-right: 30px;
			padding-left: 30px;
		}
	}

	.slick-margin-negative-sm-40{
		margin: 0 -40px;
		.js-slide-item{
			padding-right: 40px;
			padding-left: 40px;
		}
	}

	.slick-margin-negative-sm-50{
		margin: 0 -50px;
		.js-slide-item{
			padding-right: 50px;
			padding-left: 50px;
		}
	}
}

@include bp(medium){
	.slick-margin-negative-md-5{
		margin: 0 -5px;
		.js-slide-item{
			padding-right: 5px;
			padding-left: 5px;
		}
	}

	.slick-margin-negative-md-10{
		margin: 0 -10px;
		.js-slide-item{
			padding-right: 10px;
			padding-left: 10px;
		}
	}

	.slick-margin-negative-md-20{
		margin: 0 -20px;
		.js-slide-item{
			padding-right: 20px;
			padding-left: 20px;
		}
	}

	.slick-margin-negative-md-30{
		margin: 0 -30px;
		.js-slide-item{
			padding-right: 30px;
			padding-left: 30px;
		}
	}

	.slick-margin-negative-md-40{
		margin: 0 -40px;
		.js-slide-item{
			padding-right: 40px;
			padding-left: 40px;
		}
	}

	.slick-margin-negative-md-50{
		margin: 0 -50px;
		.js-slide-item{
			padding-right: 50px;
			padding-left: 50px;
		}
	}
}

.slick-margin-10{
	margin: 0 10px;
}

.slick-margin-20{
	margin: 0 20px;
}

.slick-margin-30{
	margin: 0 30px;
}

.slick-margin-40{
	margin: 0 40px;
}

.slick-margin-50{
	margin: 0 50px;
}

@include bp(small){
	.slick-margin-sm-50{
		margin: 0 50px;
	}
}


.slick-dots-hero{
	.slick-dots{
		@include bp(medium){
			text-align: left;
			left: calc((100% - 905px) / 2);
		}
		@include bp(large){
			left: calc((100% - 1125px) / 2);
		}
		@include bp(xlarge){
			left: calc((100% - 1185px) / 2);
		}
		@include bp(rev-medium){
			position: absolute;
			bottom: 10px;
		}
		li{
			width: 30px;
			height: 40px;
			&:after{
				position: absolute;
				top: 50%;
				left: 50%;
				display: block;
				width: 9px;
				height: 9px;
				border: 2px solid #ffffff;
				content: '';
				// transform: translate(-50%, -50%);
				// background-color: transparent;
				// background-repeat: no-repeat;
				// background-position: center;
				// background-size: cover;
				// background-image: url('../images/icons/slider-nav.svg');
				// @include border-radius(0);
			}
			&.slick-active,
			&:hover{
				&:after{
					border-color: #ffffff;
					background-color: #ffffff;
				}
			}
		}
	}
}

.slick-dots{
	position: absolute;
	bottom: -30px;
	left: 0;
	width: 100%;
	margin: 0!important;
	text-align: center;
	li{
		position: relative;
		display: inline-block;
		width: 30px;
		height: 40px;
		padding: 0!important;
		cursor: pointer;
		vertical-align: middle;

		@include transition(all, 250ms, ease);
		&:after{
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			width: 10px;
			height: 10px;
			content: '';
			transform: translate(-50%, -50%);
			border: 2px solid $color-blue-normal;
		}
		&.slick-active,
		&:hover{
			&:after{
				border-color: $color-blue-normal;
				background-color: $color-blue-normal;
			}
		}
		button{
			&:before{
				content: none;
			}
		}
		button{
			display: none;
		}
	}
}


.slick-arrow-hero{
	.slick-arrows{
		top: auto;
		margin-top: 0;
		bottom: -60px;
		@include bp(xsmall){
			bottom: -120px;
		}
		&.slick-prev{
			left: 50%;
			@include bp(xsmall){
				left: -25px;
			}
			@include bp(rev-xsmall){
				margin-left: -60px;
			}
		}
		&.slick-next{
			right: auto;
			left: 50%;
			@include bp(xsmall){
				left: 35px;
			}
			@include bp(rev-xsmall){
				margin-right: -60px;
			}
		}
	}
}

.slick-arrows{
	position: absolute;
	z-index: 4;
	top: 50%;
	display: block;
	margin-top: -20px;
	cursor: pointer;
	// @include bp(rev-small){
	// 	display: none!important;
	// }
	.slick-icon-prev,
	.slick-icon-next{
		display: flex;
		width: 60px;
		height: 50px;
		text-align: center;
		align-items: center;
		justify-content: center;
	}
	svg{
		fill: $color-green-normal;
		@include font-size(26px);
		&:hover{
			fill: darken($color-green-normal, 5%);
		}
	}
	&.slick-disabled{
		svg{
			fill: rgba(119, 164, 238, 0.6);
			@include bp(medium){
				fill: rgba(119, 164, 238, 0.18);
			}
		}
	}
	&.slick-prev{
		left: 0;
	}
	&.slick-next{
		right: 0;
	}
}