/* Map block */

.map-block{
	width: 100%;
	height: 100%;
	background-color: $color-gray-light;
	position: relative;
}

.map{
	width: 100%;
	height: 400px;
	@include bp(small){
		height: 600px;
	}
}

.map-block{
	.window-block{
		position: relative;
		pointer-events: none;
	}
	.gm-style-iw-t {
		&:after{
			display: none;
		}
	}
	.gm-style-iw {
		width: 180px !important;
		top: 0 !important;
		left: 0!important;
		margin: 0 auto;
		overflow: visible;
		z-index: 9;
		background-color: #0a2659;
		pointer-events: all;
		border-radius: 5px;
		padding: 0;
		&:after{
			content: '';
			position: absolute;
			left: -10px;
			top: 50%;
			display: inline-block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 8px 10px 8px 0;
			border-color: transparent #0a2659 transparent transparent;
			@include transform(translateY(-50%));
		}
		.gm-ui-hover-effect{
			width: 30px!important;
			height: 30px!important;
			right: 10px!important;
			top: -15px!important;
			border-radius: 100%;
			background-color: $color-blue-normal!important;
			opacity: 1;
			outline: none;
			display: none!important;
			img{
				display: none!important;
			}
			&:after{
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				width: 14px;
				height: 14px;
				margin-top: -7px;
				margin-left: -7px;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url('../images/icons/close-purple.svg');
			}
		}
	}
	.gm-style-iw{
		> div{
			overflow: auto!important;
			max-width: 100%!important;
		}
	}
	.gm-style-iw + div{
		pointer-events: all;
		background: $color-blue-normal;
		cursor: pointer;
		z-index: 9999999!important;
		&:after{
			content: "";
			font-family: $project-name;
			background-color: red;
			width: 10px;
			height: 10px;
			display: block;
		}
		img{
			display: none!important;
		}
	}
}

.map-content{
	padding: 0.8rem 1.2rem;
	p{
		display: block;
		margin-bottom: 0;
		color: $color-blue-light;
		font-family: $font-primary;
		@include font-size(12px);
		font-weight: 300;
		line-height: 1.2;
		letter-spacing: 0.24px;
	}
}

.map-title{
	display: block;
	margin-bottom: 0.2rem;
	color: #2bb22b;
	font-family: $font-primary;
	@include font-size(15px);
	font-weight: 500;
	line-height: 1.2;
	letter-spacing: 0.45px;
}