/* Social block */

.social-list-header{
	display: flex;
	justify-content: center;
	margin-top: 2rem;
}

.social-list-item{
	display: inline-block;
	margin-right: 20px;
	margin-bottom: 10px;
	vertical-align: middle;
	@include bp(medium){
		margin-right: 4px;
	}
	@include bp(large){
		margin-right: 20px;
	}
	&:last-child{
		margin-right: 0;
	}
}

.social-list-link{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	text-align: center;
	vertical-align: middle;
	color: $color-blue-normal;
	// border: 1px solid $color-blue-normal;
	border-radius: 100%;
	svg{
		fill: $color-blue-normal;
		@include font-size(40px);
	}
	&:hover{
		// background-color: $color-blue-normal;
		svg{
			fill: darken($color-blue-normal, 10%);
		}
	}
}
